import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../data.service';

@Component({
  selector: 'app-customer-support',
  templateUrl: './customer-support.component.html',
  styleUrls: ['./customer-support.component.scss']
})

export class CustomerSupportComponent implements OnInit {
  userBasicInfo: any;
  channelName: any = '';
  channelPartnerName: any = '';

  constructor(private router: Router, private data: DataService) { }

  lead_source: any;
  ngOnInit() {
    this.lead_source = sessionStorage.getItem('company_Name')
    this.userBasicInfo = JSON.parse(localStorage.getItem('user_basic_info')) || [];

    const body1 = {
      phone: localStorage.getItem('dmi_phone')
    }
    this.data.getChannelPartner(body1).subscribe(res => {
      this.channelName = res;
      this.channelPartnerName = this.channelName.data;
      if (this.channelName != '') {
        // Start event tracking
        let eventName = 'Customer Support Page'
        let phone = localStorage.getItem('dmi_phone')
        let campign_number = 'null';
        let source = 'DMI-Customer-Portal';
        const body1 = {
          phone: phone,
          eventname: eventName,
          campign_number: campign_number,
          source: source,
          partner: this.channelPartnerName

        }
        this.data.getEventName(body1).subscribe(res => {
        })
        // end
      }
    });
  }

}
