import { Component, OnInit, HostListener } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from 'src/app/data.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgbDateStruct, NgbCalendar, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { encryptString, decryptString } from 'src/Utils/encryption';

@Component({
  selector: 'app-create-tickets',
  templateUrl: './create-tickets.component.html',
  styleUrls: ['./create-tickets.component.scss']
})
export class CreateTicketsComponent implements OnInit {
  userActivity;
  userInactive: Subject<any> = new Subject();
  uploadtktimage = '';
  uploadtktimagewrite: any = '';
  tktimgset: boolean = false;
  remarkErr: any;
  remarkErrwrite: any;
  paymentDerr: any;
  transactionRefNoerr: any;
  amtPaidErr: any;
  emailIdErr: any;
  subjectErr: any;
  subjectErrwrite: any;
  bankErrwrite: any;
  imagePath: any;
  loanDetail: any;
  paymentDate: string = '';
  loanNane: string = '';
  loanNanewrite: string = '';
  transactionRefNo: string = '';
  amtPaid: string = '';
  emailId: string = '';
  remarks: string = '';
  remarkswrite: string = '';
  tktResData: any;
  statusData: string = 'Open';
  origin: string = 'Customer Portal';
  priority: string = 'Medium';
  division: string = 'Retail';
  ticketType: string = 'Complaint';
  ticketSubType: string = 'EMI Related Complaint';
  contact_info: any;
  userBasicInfo: any;
  userPhone: any;
  subject: string = '';
  subjectwrite: string = '';

  bankname: string = '';
  other: any;
  samsungUI: boolean = false;
  createTktSection: boolean = false;
  notallowedcreateTkt: boolean = false;
  paymentnotreflectinsection: boolean = false;
  otherSection: boolean = true;
  EmandateSection: boolean = true;
  display = 'none';
  display1 = 'none';
  fileSizeExceedErr: any;
  disableDate: any;
  loanNameErr: any;
  loanNameErrwrite: any;
  attachmentReq: string = "";
  attachmentReqwrite: string = '';
  campign_number: any;
  eventName: any;
  issubmitTkt: boolean = false;
  tktcaseno: any;

  filetoUpload: Array<File> = [];
  filetoUploadWrite: Array<File> = [];

  fileName: string = 'Upload File';
  fileNameWrite: string = 'Upload File';

  attachFile: any = 'assets/attachment-icon.svg';
  extension: any;
  attachFileWrite: any = 'assets/attachment-icon.svg';
  extensionWrite: any;
  issidenaav: boolean = false;
  showloader: boolean = false;
  showAlert: boolean = false;
  userName1: any;
  company_name: any = '';
  channelName: any = '';
  channelPartnerName: any = '';
  selectionType: any = '';
  samsungSubject: any = '';
  selectedLoan: any = '';
  selectionTypeArray: any = [{ name: 'Payment made but not reflecting', Id: 4 }, { name: 'Write to Us', Id: 5 }];

  // UAT Url

  baseurl = "https://dev.dmifinance.co/Customer-Portal/Backend/api/V1/"

  // production Url

  // baseurl = "https://businesspartner-be.dmifinance.in/backend/api/V1/"

  constructor(public domSanatize: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private data: DataService,
    private router: Router, private config: NgbDatepickerConfig, private http: HttpClient) {
    this.setTimeout();
    this.userInactive.subscribe(() => {
      localStorage.clear();
      this.router.navigate(['customerportal&currentuser&sessiontimeout'])
    });
  }

  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 900000);
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  lead_source: any;

  ngOnInit() {

    this.lead_source = sessionStorage.getItem('company_Name')

    const body2 = {
      phone: localStorage.getItem('dmi_phone')
    }

    this.data.getChannelPartner(body2).subscribe(res => {
      this.channelName = res;
      this.channelPartnerName = this.channelName.data;

    });

    this.selectionType = this.selectionTypeArray[1].Id;
    // this.selectionType = this.selectionTypeArray[2].Id;

    this.userName1 = localStorage.getItem('user_basic_name')

    if (this.router.url === '/createtickets') {
      this.issidenaav = true;
    }

    let phone = localStorage.getItem('dmi_phone')
    if (localStorage.getItem('single_loan_name')) {
      this.campign_number = localStorage.getItem('single_loan_name');
      this.company_name = sessionStorage.getItem('company_Name')
      this.eventName = 'Service ticket creation From ' + localStorage.getItem('single_loan_name')
    } else {
      this.campign_number = 'Not Available';
      this.company_name = this.channelPartnerName
      this.eventName = 'Service ticket creation For Search Term "from Help Center"'
    }
    let source = 'DMI-Customer-Portal';
    this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
    console.log('this.loanDetail', this.loanDetail)
    const body1 = {
      phone: localStorage.getItem('dmi_phone'),
      eventname: this.eventName,
      campign_number: this.campign_number,
      source: source,
      partner: this.company_name

    }

    this.data.getEventName(body1).subscribe(res => {
    });
    const current = new Date();
    this.disableDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate() - 3
    };
    if (this.other) {
      this.ticketType = '';
      this.ticketSubType = '';
    }
    this.userBasicInfo = JSON.parse(localStorage.getItem('user_basic_info')) || [];
    this.userPhone = this.userBasicInfo.data[0].MobilePhone;
    this.contact_info = 'Existing Contact';
    this.emailId = this.userBasicInfo.data[0].Email;
    this.loanNane = this.loanDetail[0].Id;
    this.loanNanewrite = this.loanDetail[0].Id;
    this.selectedLoanChange(this.loanNanewrite);
  }



  notAllowedToCreateTkt() {
    let phone = localStorage.getItem('dmi_phone')
    if (localStorage.getItem('single_loan_name')) {
      this.campign_number = localStorage.getItem('single_loan_name');
      this.company_name = sessionStorage.getItem('company_Name')
      this.eventName = 'Selected "Yes, I have recently made the payment"' + localStorage.getItem('single_loan_name');
    } else {
      this.campign_number = 'Not Available';
      this.company_name = this.channelPartnerName;
      this.eventName = 'Selected "Yes, I have recently made the payment "'
    }
    let source = 'DMI-Customer-Portal';
    this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
    const body1 = {
      phone: localStorage.getItem('dmi_phone'),
      eventname: this.eventName,
      campign_number: this.campign_number,
      source: source,
      partner: this.company_name

    }
    this.data.getEventName(body1).subscribe(res => {
    });

    this.createTktSection = false;
    this.display = 'block';
    // alert('Payment is made within the last 3 working days, your payment will be updated soon in our records');
    // this.router.navigate(['helpcenter'])
  }

  createTktSec() {

    let phone = localStorage.getItem('dmi_phone')
    if (localStorage.getItem('single_loan_name')) {
      this.campign_number = localStorage.getItem('single_loan_name');
      this.company_name = sessionStorage.getItem('company_Name')
      this.eventName = 'Selected "No, its has been more than 3 working days now"' + localStorage.getItem('single_loan_name');
    } else {
      this.campign_number = 'Not Available';
      this.company_name = this.channelPartnerName;
      this.eventName = 'Selected "No, its has been more than 3 working days now"'
    }
    let source = 'DMI-Customer-Portal';
    this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
    const body1 = {
      phone: localStorage.getItem('dmi_phone'),
      eventname: this.eventName,
      campign_number: this.campign_number,
      source: source,
      partner: this.company_name

    }
    this.data.getEventName(body1).subscribe(res => {
    })

    this.createTktSection = true;
    this.samsungUI = false;
    this.notallowedcreateTkt = false;
  }

  getProfilePic(element) {
    this.filetoUpload = element.target.files;
    if (this.filetoUpload != []) {
      this.attachmentReqwrite = '';
      this.fileName = this.filetoUpload[0].name;
      this.extension = this.fileName.split('.');
      if (this.filetoUpload[0].size > 5300000) {
        this.fileSizeExceedErr = "Maximum size upto 5MB Allowed";
        return;
      }
      else if (this.extension[1] == 'pdf') {
        this.attachFile = 'assets/pdf-icon.jpeg';
      }
      else if (this.extension[1] !== 'jpeg' && this.extension[1] !== 'jpg' && this.extension[1] !== 'png' && this.extension[1] !== 'pdf') {
        this.fileName = "Please attach the allowed file format.";
        this.attachFile = 'assets/alert.svg';
      }
      else {
        // this.attachFile = 'assets/letter.svg';
        const fileReader: FileReader = new FileReader();
        fileReader.readAsDataURL(this.filetoUpload[0]);
        fileReader.onload = (event: Event) => { this.attachFile = fileReader.result; }
      }
    }
  }

  getProfilePicWrite(element) {
    this.fileSizeExceedErr = '';
    this.filetoUploadWrite = element.target.files;
    if (this.filetoUploadWrite != []) {
      this.fileNameWrite = this.filetoUploadWrite[0].name;
      this.extensionWrite = this.fileNameWrite.split('.');
      if (this.filetoUploadWrite[0].size > 5300000) {
        this.fileSizeExceedErr = "Maximum size upto 5MB Allowed";
        return;
      }
      else if (this.extensionWrite[1] == 'pdf') {
        this.attachFileWrite = 'assets/pdf-icon.jpeg';
      }
      else if (this.extensionWrite[1] !== 'jpeg' && this.extensionWrite[1] !== 'jpg' && this.extensionWrite[1] !== 'png' && this.extensionWrite[1] !== 'pdf') {
        this.fileNameWrite = "Please attach the allowed file format.";
        this.attachFileWrite = 'assets/alert.svg';
      }
      else {
        // this.attachFile = 'assets/letter.svg';
        const fileReader: FileReader = new FileReader();
        fileReader.readAsDataURL(this.filetoUploadWrite[0]);
        fileReader.onload = (event: Event) => { this.attachFileWrite = fileReader.result; }
      }
    }
  }

  ifpaymentnotreflecting() {
    let phone = localStorage.getItem('dmi_phone')
    if (localStorage.getItem('single_loan_name')) {
      this.campign_number = localStorage.getItem('single_loan_name');
      this.company_name = sessionStorage.getItem('company_Name')
      this.eventName = 'Selected "Payment made but not reflecting"'
    } else {
      this.campign_number = 'Not Available';
      this.company_name = this.channelPartnerName;
      this.eventName = 'Selected "Payment made but not reflecting"'
    }
    let source = 'DMI-Customer-Portal';
    this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
    const body1 = {
      phone: localStorage.getItem('dmi_phone'),
      eventname: this.eventName,
      campign_number: this.campign_number,
      source: source,
      partner: this.company_name

    }
    this.data.getEventName(body1).subscribe(res => {
    })

    this.paymentnotreflectinsection = true;
    this.createTktSection = false;
    this.otherSection = false;
    this.EmandateSection = false;
  }

  createothertkt() {
    this.paymentnotreflectinsection = false;
    this.otherSection = true;
    this.EmandateSection = false;
  }
  EmandateCancel() {
    this.paymentnotreflectinsection = false;
    this.otherSection = false;
    this.EmandateSection = true;
  }

  Description1;


  createTicketWrite() {
    const phone = localStorage.getItem('dmi_phone');
    const singleLoanName = localStorage.getItem('single_loan_name');
    const companyName = sessionStorage.getItem('company_Name');

    if (singleLoanName) {
      this.campign_number = singleLoanName;
      this.company_name = companyName;
      this.eventName = `Made a "Submit" action to create a service ticket request for Loan Account ${singleLoanName}`;
    } else {
      this.campign_number = 'Not Available';
      this.company_name = this.channelPartnerName;
      this.eventName = 'Made a "Submit" action to create a service ticket request';
    }

    const source = 'DMI-Customer-Portal';
    this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));

    if (!this.loanNanewrite) {
      this.loanNameErrwrite = 'Loan Name is Required';
      return;
    }
    if (!this.subjectwrite) {
      this.subjectErrwrite = 'Subject is required';
      return;
    }
    if (!this.remarkswrite) {
      this.remarkErrwrite = 'Remark is required';
      return;
    }

    this.issubmitTkt = true;

    const ticketData: any = {
      Status: this.statusData,
      Origin: this.origin,
      Type: '',
      Sub_Type__c: '',
      category: '',
      Priority: this.priority,
      Associated_Loan__c: this.loanNanewrite,
      Contact_Info__c: this.contact_info,
      subject: this.subjectwrite,
      bank: this.bankname,
      Description: this.remarkswrite,
      Division__c: this.division,
      caller_phone__c: phone,
      attachment: this.filetoUploadWrite.length === 0 ? '' : []
    };

    if (this.filetoUploadWrite.length > 0) {
      for (let i = 0; i < this.filetoUploadWrite.length; i++) {
        const file = this.filetoUploadWrite[i];
        const reader = new FileReader();
        reader.onload = (event: any) => {
          ticketData.attachment.push({
            name: file.name,
            content: event.target.result.split(',')[1] // base64 encoded file content
          });

          if (i === this.filetoUploadWrite.length - 1) {
            this.submitTicket1(ticketData);
          }
        };
        reader.readAsDataURL(file);
      }
    } else {
      this.submitTicket1(ticketData);
    }
  }

  submitTicket1(ticketData: any) {
    const body1 = {
      phone: localStorage.getItem('dmi_phone'),
      eventname: this.eventName,
      campign_number: this.campign_number,
      source: 'DMI-Customer-Portal',
      partner: this.company_name
    };

    this.data.getEventName(body1).subscribe(res => { });

    const data = encryptString(ticketData, environment.secretKey)

    this.http.post(`${this.baseurl}createticket`, { data }).subscribe((response) => {
      this.handleTicketResponse1(response);
    });
  }

  handleTicketResponse1(response: any) {
    const ticketResponse = JSON.parse(decryptString(response.data, environment.secretKey))


    if (ticketResponse.status == 200) {
      this.showAlert = true;
      this.tktcaseno = true;
      this.issubmitTkt = false;
      this.tktcaseno = ticketResponse.data;
      const phone = localStorage.getItem('dmi_phone');
      const singleLoanName = localStorage.getItem('single_loan_name');
      const companyName = sessionStorage.getItem('company_Name');

      if (singleLoanName) {
        this.campign_number = singleLoanName;
        this.company_name = companyName;
        this.eventName = `Created service ticket request id: ${this.tktcaseno}`;
      } else {
        this.campign_number = 'Not Available';
        this.company_name = this.channelPartnerName;
        this.eventName = `Created service ticket request id: ${this.tktcaseno}`;
      }

      const source = 'DMI-Customer-Portal';
      this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));

      const body1 = {
        phone,
        eventname: this.eventName,
        campign_number: this.campign_number,
        source,
        partner: this.company_name
      };

      this.data.getEventName(body1).subscribe(res => { });

      this.display1 = 'block';
    }
    else {

    }
  }

  handleTicketResponse(response: any) {
    const ticketResponse = JSON.parse(decryptString(response.data, environment.secretKey))

    if (ticketResponse.status == 200) {

      this.showAlert = true;
      this.tktcaseno = true;
      this.issubmitTkt = false;
      this.tktcaseno = ticketResponse.data;
      const phone = localStorage.getItem('dmi_phone');
      const singleLoanName = localStorage.getItem('single_loan_name');
      const companyName = sessionStorage.getItem('company_Name');

      if (singleLoanName) {
        this.campign_number = singleLoanName;
        this.company_name = companyName;
        this.eventName = `Created service ticket request id: ${this.tktcaseno}`;
      } else {
        this.campign_number = 'Not Available';
        this.company_name = this.channelPartnerName;
        this.eventName = `Created service ticket request id: ${this.tktcaseno}`;
      }

      const source = 'DMI-Customer-Portal';
      this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));

      const body1 = {
        phone,
        eventname: this.eventName,
        campign_number: this.campign_number,
        source,
        partner: this.company_name
      };

      this.data.getEventName(body1).subscribe(res => { });

      this.display1 = 'block';
    }
    else {

    }


  }





  createTicketWrite1() {
    const phone = localStorage.getItem('dmi_phone');
    const singleLoanName = localStorage.getItem('single_loan_name');
    const companyName = sessionStorage.getItem('company_Name');

    if (singleLoanName) {
      this.campign_number = singleLoanName;
      this.company_name = companyName;
      this.eventName = `Made a "Submit" action to create a service ticket request for Loan Account ${singleLoanName}`;
    } else {
      this.campign_number = 'Not Available';
      this.company_name = this.channelPartnerName;
      this.eventName = 'Made a "Submit" action to create a service ticket request';
    }

    const source = 'DMI-Customer-Portal';
    this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));

    if (!this.loanNanewrite) {
      this.loanNameErrwrite = 'Loan Name is Required';
      return;
    }
    if (!this.bankname) {
      this.bankErrwrite = 'Bank Name is required';
      return;
    }
    if (!this.remarkswrite) {
      this.remarkErrwrite = 'Remark is required';
      return;
    }

    this.issubmitTkt = true;

    const ticketData: any = {
      Status: this.statusData,
      Origin: this.origin,
      Type: 'Request',
      Sub_Type__c: '',
      category: '',
      Priority: this.priority,
      Associated_Loan__c: this.loanNanewrite,
      Contact_Info__c: this.contact_info,
      subject: 'Online Mandate Cancellation',
      bank: this.bankname,
      Description: `Bank Name: ${this.bankname} Remarks: ${this.remarkswrite}`,
      Division__c: this.division,
      caller_phone__c: phone,
      attachment: this.filetoUploadWrite.length === 0 ? '' : []
    };

    if (this.filetoUploadWrite.length > 0) {
      for (let i = 0; i < this.filetoUploadWrite.length; i++) {
        const file = this.filetoUploadWrite[i];
        const reader = new FileReader();
        reader.onload = (event: any) => {
          ticketData.attachment.push({
            name: file.name,
            content: event.target.result.split(',')[1] // base64 encoded file content
          });

          if (i === this.filetoUploadWrite.length - 1) {
            this.submitTicket(ticketData);
          }
        };
        reader.readAsDataURL(file);
      }
    } else {
      this.submitTicket(ticketData);
    }
  }


  submitTicket(ticketData: any) {
    const body1 = {
      phone: localStorage.getItem('dmi_phone'),
      eventname: this.eventName,
      campign_number: this.campign_number,
      source: 'DMI-Customer-Portal',
      partner: this.company_name
    };

    this.data.getEventName(body1).subscribe(res => { });

    const data = encryptString(ticketData, environment.secretKey)

    this.http.post(`${this.baseurl}createticket`, { data }).subscribe((response) => {
      this.handleTicketResponse(response);
    });
  }


  createTicketPayment() {

    console.log('this.selectionType1', this.selectionType)
    let validateEmail = this.validateEmail(this.emailId);
    let dateFormat = `${this.paymentDate['day']}-${this.paymentDate['month']}-${this.paymentDate['year']}`;
    let phone = localStorage.getItem('dmi_phone');

    if (localStorage.getItem('single_loan_name')) {
      this.campign_number = localStorage.getItem('single_loan_name');
      this.company_name = sessionStorage.getItem('company_Name');
      this.eventName = `Made a "Submit" action to create a service ticket request for Loan Account ${localStorage.getItem('single_loan_name')}`;
    } else {
      this.campign_number = 'Not Available';
      this.company_name = this.channelPartnerName;
      this.eventName = 'Made a "Submit" action to create a service ticket request';
    }

    let source = 'DMI-Customer-Portal';
    this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));

    const body1 = {
      phone: localStorage.getItem('dmi_phone'),
      eventname: this.eventName,
      campign_number: this.campign_number,
      source: source,
      partner: this.company_name
    };

    this.data.getEventName(body1).subscribe(res => { });

    if (this.loanNanewrite === "") {
      this.loanNameErr = 'Loan Name is Required';
    } else if (this.emailId === "") {
      this.emailIdErr = 'Email ID is required';
    } else if (validateEmail === false) {
      this.emailIdErr = 'Please enter valid email';
    } else if (this.subject === "") {
      this.subjectErr = 'Subject is required';
    } else if (this.remarks === "") {
      this.remarkErr = 'Remark is required';
    } else if (this.filetoUpload.length === 0) {
      this.attachmentReqwrite = 'Please attach the payment proof.';
    } else {

      if (this.selectionType == 4 || this.selectionType == 5 || this.selectionType == 6) {

        this.issubmitTkt = true;
        let inputdate = this.paymentDate;

        let currentDate = new Date();
        if (new Date(inputdate) <= currentDate || new Date(inputdate) >= currentDate) {
          this.display = 'block';
        } else if (this.paymentDate === "") {
          this.paymentDerr = 'Date of Transaction is required';
        } else if (this.transactionRefNo === "") {
          this.transactionRefNoerr = 'Transaction Reference Number is required';
        } else if (this.amtPaid === "") {
          this.amtPaidErr = 'Amount Paid is required';
        } else {

          this.showloader = true;

          const jsonData = {
            Status: this.statusData,
            Origin: this.origin,
            Type: this.ticketType,
            Sub_Type__c: this.ticketSubType,
            category: "Payment Not Updated",
            Priority: this.priority,
            Associated_Loan__c: this.loanNanewrite,
            Contact_Info__c: this.contact_info,
            subject: this.subject,
            Description: this.remarks,
            Division__c: this.division,
            caller_phone__c: localStorage.getItem('dmi_phone'),
            paid_amount: this.amtPaid,
            transaction_ref_no: this.transactionRefNo,
            payment_date: dateFormat,
          };

          const data = encryptString(jsonData, environment.secretKey)

          this.http.post(this.baseurl + 'createticketpayment', { data }).subscribe((response) => {
            this.tktResData = response;
            if (response) { this.showloader = false; }
            this.tktcaseno = this.tktResData.data[0].CaseNumber;
            this.issubmitTkt = false;
            if (localStorage.getItem('single_loan_name')) {
              this.campign_number = localStorage.getItem('single_loan_name');
              this.company_name = sessionStorage.getItem('company_Name');
              this.eventName = `Created service ticket request id : ${this.tktResData.data[0].CaseNumber}`;
            } else {
              this.campign_number = 'Not Available';
              this.company_name = this.channelPartnerName;
              this.eventName = `Created service ticket request id : ${this.tktResData.data[0].CaseNumber}`;
            }
            const body1 = {
              phone: localStorage.getItem('dmi_phone'),
              eventname: this.eventName,
              campign_number: this.campign_number,
              source: source,
              partner: this.company_name
            };
            this.data.getEventName(body1).subscribe(res => { });
            this.display1 = 'block';
          });
        }
      }

      if (this.selectionType == 1 || this.selectionType == 2 || this.selectionType == 3) {
        if (this.selectionType == 1) {
          if (this.paymentDate == "") {
            this.paymentDerr = 'Date of Transaction is required';
          } else if (this.transactionRefNo === "") {
            this.transactionRefNoerr = 'Transaction Reference Number is required';
          } else if (this.amtPaid === "") {
            this.amtPaidErr = 'Amount Paid is required';
          } else {
            this.showloader = true;
            const jsonDataSamsung = {
              type: this.selectionType,
              subject: this.samsungSubject,
              email: this.emailId,
              loan_name: this.selectedLoan,
              stage_name: this.loanDetail[0].StageName,
              description: this.remarks,
              payment_date: dateFormat,
              payment_refernce_number: this.transactionRefNo,
              amount: this.amtPaid
            };

            const data = encryptString(jsonDataSamsung, environment.secretKey)

            this.http.post(environment.baseurl + 'freshdeskdb', { data }).subscribe((response) => {
              this.tktResData = response;
              if (response) { this.showloader = false; }
              this.showAlert = true;
              this.tktcaseno = this.tktResData.data[0].CaseNumber;
              this.issubmitTkt = false;
              if (localStorage.getItem('single_loan_name')) {
                this.campign_number = localStorage.getItem('single_loan_name');
                this.company_name = sessionStorage.getItem('company_Name');
                this.eventName = `Created service ticket request id : ${this.tktResData.data[0].CaseNumber}`;
              } else {
                this.campign_number = 'Not Available';
                this.company_name = this.channelPartnerName;
                this.eventName = `Created service ticket request id : ${this.tktResData.data[0].CaseNumber}`;
              }
              const body1 = {
                phone: localStorage.getItem('dmi_phone'),
                eventname: this.eventName,
                campign_number: this.campign_number,
                source: source,
                partner: this.company_name
              };
              this.data.getEventName(body1).subscribe(res => { });
              this.display1 = 'block';
            });
          }
        }

        if (this.selectionType == 2 || this.selectionType == 3) {
          if (this.selectionType == 3) { this.samsungSubject = this.subject; }
          const jsonDataSamsungOther = {
            type: this.selectionType,
            subject: this.samsungSubject,
            email: this.emailId,
            loan_name: this.selectedLoan,
            stage_name: this.loanDetail[0].StageName,
            description: this.remarks
          };


          const data = encryptString(jsonDataSamsungOther, environment.secretKey)

          this.http.post(environment.baseurl + 'freshdeskdb', { data }).subscribe((response) => {
            this.tktResData = response;
            if (response) { this.showloader = false; }
            this.showAlert = true;
            this.tktcaseno = this.tktResData.data[0].CaseNumber;
            this.issubmitTkt = false;
            if (localStorage.getItem('single_loan_name')) {
              this.campign_number = localStorage.getItem('single_loan_name');
              this.company_name = sessionStorage.getItem('company_Name');
              this.eventName = `Created service ticket request id : ${this.tktResData.data[0].CaseNumber}`;
            } else {
              this.campign_number = 'Not Available';
              this.company_name = this.channelPartnerName;
              this.eventName = `Created service ticket request id : ${this.tktResData.data[0].CaseNumber}`;
            }
            const body1 = {
              phone: localStorage.getItem('dmi_phone'),
              eventname: this.eventName,
              campign_number: this.campign_number,
              source: source,
              partner: this.company_name
            };
            this.data.getEventName(body1).subscribe(res => { });
            this.display1 = 'block';
          });
        }
      }
    }
  }


  removeErr() {
    this.loanNameErr = '';
    this.paymentDerr = '';
    this.transactionRefNoerr = '';
    this.amtPaidErr = '';
    this.emailIdErr = '';
    this.subjectErr = '';
    this.remarkErr = '';
    this.attachmentReqwrite = '';
  }

  removeErrNumber(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  removeErrWrite() {
    this.subjectErrwrite = '';
    this.remarkErrwrite = '';
  }

  dateChange() {
    this.paymentDerr = '';
  }

  selectedLoanChange(event) {
    this.selectionTypeArray = [];
    for (let i = 0; i < this.loanDetail.length; i++) {
      if (event == this.loanDetail[i].Id) {
        if (this.loanDetail[i].Pulled_Lead_Source_Name__c == 'Samsung') {
          this.selectedTypeChange(1);
          this.selectedLoan = this.loanDetail[i].name;
          this.createTktSection = true;
          this.samsungUI = false;
          this.otherSection = false;
          this.paymentnotreflectinsection = true;
          this.EmandateSection = false;
          this.selectionTypeArray = [{ name: 'Payment done but Phone Locked', Id: 1 }, { name: 'Phone locked due to Technical Issue', Id: 2 }, { name: 'Others', Id: 3 }];
          this.selectionType = this.selectionTypeArray[2].Id;
        }
        else {
          this.selectedTypeChange(5);
          this.selectionTypeArray = [{ name: 'Payment made but not reflecting', Id: 4 }, { name: 'Write to Us', Id: 5 }];
          this.selectionType = this.selectionTypeArray[1].Id
        }
      }
    }
    ;
    // this.selectionType = this.selectionTypeArray[2].Id;
  }

  selectedTypeChange(event) {
    if (event == 1) { this.samsungSubject = "Payment not updated"; this.subject = "Payment not updated" }
    else if (event == 2) { this.samsungSubject = "Phone locked due to technical issue"; this.subject = "Phone locked due to technical issue" }
    else if (event == 3) { this.subject = '' }
    else if (event == 4) { this.ifpaymentnotreflecting() }
    else if (event == 5) { this.createothertkt() }
    else if (event == 6) { this.EmandateCancel() }
  }

  // createTicket() {
  //   if(this.loanNane == ""){
  //     this.loanNameErr = 'Loan Name is Required'
  //   }
  //   if (this.paymentDate == "") {
  //     this.paymentDerr = 'Date of Transaction is required'
  //   }if (this.transactionRefNo == "") {
  //     this.transactionRefNoerr = 'Transaction Reference Number is required'
  //   }if (this.amtPaid == "") {
  //     this.amtPaidErr = 'Amount Paid is required'
  //   }if (this.emailId == "") {
  //     this.emailIdErr = 'Email ID is required'
  //   }if (this.subject == "") {
  //     this.subjectErr = 'Subject is required'
  //   }
  //   if(this.uploadtktimage == ''){
  //     this.attachmentReq = 'Please attach the payment proof.'
  //   }
  //    if(this.remarks == "") {
  //     this.remarkErr = 'Remark is required'
  //   }else{
  //   let inputdate = this.paymentDate;
  //   let currentDate = new Date();
  //   if (new Date(inputdate) <= currentDate || new Date(inputdate) >= currentDate) {
  //     this.display = 'block';
  //     // alert('Please allows us 3 working days from the date of payments to update your records with us.')
  //   }
  //   let remark = {
  //     remark: this.remarks,
  //     payment_date: this.paymentDate,
  //     transaction_ref_no: this.transactionRefNo,
  //     paid_amount: this.amtPaid,
  //     email_id: this.emailId
  //   }
  //   this.data.createTicketReq(this.statusData, this.origin, this.ticketType, this.ticketSubType, this.priority, this.loanNane, this.contact_info, this.subject, this.remarks, this.division, localStorage.getItem('dmi_token'), localStorage.getItem('dmi_instance_url'), this.userPhone, this.uploadtktimage).subscribe(res => {
  //     this.tktResData = res;
  //     alert('Your request has been received successfully.' + '\n' + 'Your Ticket Id is:' + '  ' + this.tktResData.data[0].CaseNumber)
  //     this.router.navigate(['helpcenter'])
  //   })
  // }
  // }

  getBase64(file, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(file);
  }

  dismisModel() {
    this.display = 'none'
    this.router.navigate(['helpcenter'])
  }

  ngOnDestroy() {
    clearTimeout(this.userActivity);
  }
}
