import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { decryptString, encryptString } from 'src/Utils/encryption';
import { BaseHttpResponse } from 'src/modals/http-request-modals';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  response: any;

  constructor(private http: HttpClient) {}

  // UAT Url
  baseurl = environment.baseurl;

  // production Url
  //baseurl3 = "https://los.dmifinance.in/los/api/"
  //  baseurl2 = "https://dev.dmifinance.co/cp/backend/public/api/";
  //  baseurl2 = "https://dev.vistaconnect.com/dmi-clubbed-backend/api/"
  // baseurl2 = "https://34.100.142.32/backend/api/V1/"
  baseurl2 = environment.baseurl;
  secretKey = environment.secretKey;
  // for get authentication

  // razorpay Configuration file:

  // UAT
  // public razorpayKey: any = 'rzp_test_EsnLkwYiJxNMp6';

  // Prod
  public razorpayKey: any = 'rzp_live_yYLo9BAVCiDsOX';

  deviceTracking(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'CP_Device_Traking', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  troubleLogin(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'cp_trouble_login', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  getMobileNo(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'otpcp', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  reSetPassword(body): Observable<any> {
    const headers = new HttpHeaders({ 'Secret-Key': body.key });
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(
        this.baseurl + 'CP_Create_Password',
        { data },
        { headers }
      )
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  getNachPaymentStatus(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'cp_loan_nach_payment_status', {
        data,
      })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  // for loan and user details
  getUserInfo(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'basicinfov2', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  // for loan and user details
  getUserName(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'basicinfobynamev2', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  //////////////////////////////////////////////// Feedback Litmus////////////////////////////////////////////
  FeedbackLTMS(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'litmusgetdata', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }
  getRatingDetails(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'getfeedbackbyltms', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }
  //////////////////////////////////////////////// Feedback Litmus////////////////////////////////////////////

  ////////////////////////////////////////////////////Experian////////////////////////////////////////////////
  // getAuthForExperian() {
  //   return this.http.get(this.baseurl + 'getauthexp');
  // }
  submitExperianForm(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'reqdataexp', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }
  databaseEntryExperian(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'dbdataexp', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }
  sendOTP(body) {
    //return this.http.get(this.baseurl + 'otpex/' + mobileNumber);

    const data = encryptString(body, this.secretKey);
    return this.http
      .get<BaseHttpResponse>(this.baseurl + 'otpex/' + { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }
  verifyOTP(body: any) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .get<BaseHttpResponse>(this.baseurl + 'verifyotpex/' + { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }
  getUserDetails(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'reqformdataexpv2', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }
  experianPDF(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'ExperianPDFGenerate', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }
  downloadPDF(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'Experianpdfbase64', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }
  experianCheckUser(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'Experianwhitelistcheckuser', {
        data,
      })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }
  // ....................Credit Score....................
  getCreditScoreRecord(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'reqfromfrontend', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }
  getExperianDataByMBID(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'reqdatawithmbid', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  ////////////////////////////////////////////////////Experian////////////////////////////////////////////////

  getBankDetail(phone) {
    return this.http.post(this.baseurl2 + 'bankdetailv2', { phone });
  }

  getLoanDetail(phone) {
    return this.http.post(this.baseurl2 + 'totalloancpv2', { phone });
  }

  getLoanCount(body): Observable<any> {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'totalloancountcpv2', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  getLoanCountDetails(body): Observable<any> {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'totalloancountdatacpv2', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  getLoanOffer(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'offerv2', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  getSpecificLoan(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'loandetailv20', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  getPaymentSchedule(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'paymentschedulev20', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  getStatement(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'statementv2', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  getChannelPartner(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'getchannelname', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  getbankdetailByLoan(loanname) {
    return this.http.post(this.baseurl2 + 'bankdetailbyloanv2', { loanname });
  }

  getmaturesumvalue(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'maturesumv2', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  // for nach and welcome letter
  getWelcomeLetter(loanid) {
    return this.http.post(this.baseurl2 + 'welcomeletterv2', { loanid });
  }
  createWelComeLetter(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'createwelcomeletterv2', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  createNocLetter(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'NOC-2022', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  // for NACH Registration and payment
  getChechNach(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'checknachv2', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  getCreateCo(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'createcoportal', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  getNachRegistration(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'nachcp', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }
  getTokenData(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'gettokenfromrzp', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  getPayNow(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'payorderid', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }
  //get Paynow Amount
  getPaybleAmount(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'Get_Payable_Amount', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  // save nach records
  saveNACHrecords(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'createnachlog', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  //downloadStatement
  downloadStatement(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'soadownload', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  // for blog and faq
  getPagination() {
    return this.http
      .get<BaseHttpResponse>(this.baseurl + 'blogpaginglist')
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }
  getBlogList(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'bloglist', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }
  getBlogDetails(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'blogsingleview', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }
  getFAQ() {
    return this.http.get<BaseHttpResponse>(this.baseurl + 'faqlist').pipe(
      map((response) => {
        return (this.response = JSON.parse(
          decryptString(response.data, this.secretKey)
        ));
      })
    );
  }

  // for knowladge base
  getKBdata() {
    return this.http
      .get<BaseHttpResponse>(this.baseurl + 'knowledgebase/knowledgebaselist')
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  getKBsearch(title) {
    return this.http.post(this.baseurl + 'knowledgebase/articlesearch/', {
      title,
    });
  }

  // for ticket service request
  createTicketReq(
    Status,
    Origin,
    Type,
    Sub_Type__c,
    Priority,
    Associated_Loan__c,
    Contact_Info__c,
    subject,
    Description,
    Division__c,
    caller_phone__c,
    attachment
  ) {
    return this.http.post(this.baseurl + 'createticket', {
      Status,
      Origin,
      Type,
      Sub_Type__c,
      Priority,
      Associated_Loan__c,
      Contact_Info__c,
      subject,
      Description,
      Division__c,
      caller_phone__c,
      attachment,
    });
  }

  // for event tracking
  getEventName(body) {
    const data = encryptString(body, this.secretKey);
    return this.http.post<BaseHttpResponse>(this.baseurl + 'eventtrackcp', {
      data,
    });
    // .pipe(map(response => {
    //   return this.response = JSON.parse(decryptString(response, this.secretKey))
    // }))
  }

  getselectlanguage(body) {
    const data = encryptString(body, this.secretKey);
    return this.http.post<BaseHttpResponse>(this.baseurl + 'selectlanguage', {
      data,
    });
  }
  getpasskey(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'getpasskey', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  PTPStatus(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'checkservice', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }
  ptpDate(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'promisetopaydate', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }
  //promise to pay date
  promisetodate(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'temporaryunlock', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }
  DeviceLockStatus(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'devicelockstatus', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }
  DeviceUnlock(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'deviceunlock', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  payment_sync(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'paymentsync', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }
  getArticleSearch(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(
        this.baseurl + 'knowledgebase/articlesearchheader',
        { data }
      )
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  getArticleDetails(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'knowledgebase/articledetails', {
        data,
      })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  //notification

  getNotificationList(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'notification/notificationlist', {
        data,
      })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  getTrendingDataList(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'bloglist', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  //category

  getCategoryList() {
    return this.http
      .get<BaseHttpResponse>(this.baseurl + 'knowledgebase/categoryactivelist')
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  getCategoryDetails(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(
        this.baseurl + 'knowledgebase/articlecategorysearch',
        { data }
      )
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }
  //FAQ

  getFAQList() {
    return this.http
      .get<BaseHttpResponse>(this.baseurl + 'knowledgebase/articletopview')
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  //recentArticle

  getRecentActivity(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'knowledgebase/recentarticle', {
        data,
      })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  likeArticle(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(
        this.baseurl + 'knowledgebase/articlelikeunlike',
        { data }
      )
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  //feedback
  getFeedback(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'sessionrating', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }
  getDetailsLoan(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'totalemicountv2', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }
  getFeedbackUrl(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'qdsfeedback', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  // tickets
  getlastthreeTkt(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'viewlastthreeticket', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }
  getalltickets(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'viewmobileticket', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }
  getticketdetails(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'viewticket', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  // Date: 2024-11-28
  // Time: 13:30 IST
  // Developer: Saurabh Kumar

  storeQdsFeedback(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'store_qds_feedback', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  // Date: 2024-12-09
  // Time:15:30 IST
  // Developer: Saurabh Kumar

  checkQdsFeedback(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'check_qds_feedback', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  // create payment record
  getpaymentRecords(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .get<BaseHttpResponse>(this.baseurl + 'createpaymentlog' + data)
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  // forclouser
  getUserEvent(partner_user_id, campign_number, eventname, recent_activity) {
    return this.http.post(this.baseurl + 'eventreportpartner', {
      partner_user_id,
      campign_number,
      eventname,
      recent_activity,
    });
  }
  getForclosure(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'forcluser', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }
  Check_date_preclosure(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'Check_Prepayment', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }
  foreclosurePayInfo(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'createpreclosurepaymentlog', {
        data,
      })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }
  getSecretKey(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'Secret_Key_Generate', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }
  preClouse(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'cp_preclosure', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  msgforeClosure(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'Preclosure_Createcase', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  prepaymentPopupMsg(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'Prepayment_Popup_Msg_1', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  PrepaymentPopupMsg2(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'Prepayment_Popup_Msg_2', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  customersoffers(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'customersoffers', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }
  customer_OffersDecision(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'customeroffersdecision', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  // AA api

  check_AA(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'checkaa', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  AASatellite(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'satelliteservice', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  AA_Callback(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'AA_Callback', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }

  emandateapply(body) {
    const data = encryptString(body, this.secretKey);
    return this.http
      .post<BaseHttpResponse>(this.baseurl + 'Emandate_Service', { data })
      .pipe(
        map((response) => {
          return (this.response = JSON.parse(
            decryptString(response.data, this.secretKey)
          ));
        })
      );
  }
}
