import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../_models';
import { environment } from 'src/environments/environment';
import { decryptString, encryptString } from 'src/Utils/encryption';
import { BaseHttpResponse } from 'src/modals/http-request-modals';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
   

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }
    baseurl = environment.baseurl
    secretKey=environment.secretKey
    response:any;

    loginWithPassword(phone: string, password: string) {
        const body={
            phone:phone,
            password:password
        }
        const data=(encryptString(body, this.secretKey)); 
       
        return this.http.post<BaseHttpResponse>(this.baseurl+'logincp', {data})
            .pipe(map(user => {                
                this.response=JSON.parse(decryptString(user.data, this.secretKey))  
               
                if (this.response) {
                    sessionStorage.setItem('firstTime', 'true');
                    sessionStorage.setItem('userID',this.response.data.id)
                    // console.log(this.response.data.id)
                    localStorage.setItem('currentUser', JSON.stringify(this.response))
                    this.currentUserSubject.next(this.response);
                }
                return this.response;
            }));
    }

    verifyOtp(Mobile:any,otp : any) {
        const body={
            mobile:Mobile,
            otp:otp
        }
        
        const data=(encryptString(body, this.secretKey)); 
        
        return this.http.post<any>(environment.baseurl + 'verifyotpcp',{data})
            .pipe(map(user => {
                this.response = JSON.parse(decryptString(user.data, this.secretKey))
                sessionStorage.setItem('signature',this.response.data.sign);
                sessionStorage.setItem('userID',this.response.data.id);

                // console.log(this.response.data.id)
                if (this.response) {
                    localStorage.setItem('currentUser', JSON.stringify(this.response + localStorage.getItem('dmi_token')))
                    this.currentUserSubject.next(this.response);
                }
                return this.response;
            }));
    }

    logout() {
        localStorage.clear();
        sessionStorage.clear();
        this.currentUserSubject.next(null);
    }
}