declare var Razorpay: any;
import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as jsPDF from 'jspdf'
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { Subject } from 'rxjs';
import { saveAs as fileSaverSave } from 'file-saver'
import { Url } from 'url';
import { decryptString } from 'src/Utils/encryption';
import { environment } from 'src/environments/environment';
// forclouser changes

@Component({
  selector: 'app-forclouser',
  templateUrl: './forclouser.component.html',
  styleUrls: ['./forclouser.component.scss']
})
export class ForclouserComponent implements OnInit {
  loan_borrower_name = localStorage.getItem('loan_borrower_name');
  loan_stage = localStorage.getItem('loan_stage');
  loanName = localStorage.getItem('single_loan_name');
  loan_lead_source_name = localStorage.getItem('loan_lead_source_name');
  StageName: any
  loanId = localStorage.getItem('specefic_loan_id');
  phone = localStorage.getItem('dmi_phone');
  lead_source = sessionStorage.getItem('company_Name');
  // lead_source = 'l'
  userId = sessionStorage.getItem('userID');
  responsedata: any;
  forcluserdata: any;
  fortrue: boolean = false;
  showRemarks: boolean = false;
  dashboardloader: boolean = false;
  disableBtn: boolean = true;
  paynowDisable: boolean = false;
  foreclosureValues: any = [];
  opName: any = '';
  Remarks: any = '';
  creationDate: any = '';
  formula: string = '';
  csvResp = [];
  downloadData: boolean = false;
  userActivity: any = '';
  userInactive: Subject<any> = new Subject();
  @ViewChild('pdfTable') pdfTable: ElementRef;

  // payment
  loanDetail: any = ''
  channelName: any = '';
  channelPartnerName: any = '';
  city: any; country: any; postalCode: any; state: any; street: any; billingAddress: any; userBasicInfo: any; email: any; acHolderName: any; loanDetails: any;
  userName: any; phoneNo: any;
  payNowData: any;
  payNowcustomerId: any;
  payNoworderId: any;
  display2 = 'none';
  payNowModel = 'none';
  userName1: any;
  paidPayableAmount: any;
  totalAmt: any;
  docNameAddress: any;
  isDisabled: boolean = false;
  preclosure_msg: any;
  preclosure_image: any;
  information_msg: any;
  confirmationMsg: boolean = false
  source: any
  campign_number: any
  finalCasepopup: boolean = false;
  secretKey = environment.secretKey;
  // razorpayKey: any
  preClosureStatus: any

  constructor(private data: DataService, private router: Router, private http: HttpClient) {
    this.setTimeout();
    this.userInactive.subscribe(() => {
      localStorage.clear();
      this.router.navigate(['customerportal&currentuser&sessiontimeout']);
    })
  }

  model_overlay: boolean = false;
  // new changes
  ngOnInit() {
    this.showRemarks = true;
    this.fortrue = false;
    this.dashboardloader = true;
    this.source = 'DMI-Customer-Portal'
    this.campign_number = localStorage.getItem('single_loan_name');
    this.userName1 = localStorage.getItem('user_basic_name');
    this.StageName = localStorage.getItem('loanDetail');
    // this.razorpayKey = this.data.razorpayKey;
    this.preClosureStatus = localStorage.getItem('preClosurePopup');


    if (this.preClosureStatus == 'true') {
      this.getDynamicMsg();
    }
    else {
      this.withOutSpecificLeadSource()
    }

    this.getUserInfo()
    this.getPartnerName()
  }

  dynamicMsgResponse: any
  dynamicMsg2: any
  dynamicMsg3: any

  getDynamicMsg() {

    const body = {
      user_id: this.userId
    }

    this.data.prepaymentPopupMsg(body).subscribe(res => {

      if (res.status == 200) {
        this.dynamicMsgResponse = res.data
        this.confirmationMsg = true;
        this.dashboardloader = false;
        let eventName = 'Double_Confirmation_Success_Popup'

        const body1 = {
          phone: this.phone,
          eventname: eventName,
          campign_number: this.campign_number,
          source: this.source,
          partner: sessionStorage.getItem('company_Name')

        }
        this.data.getEventName(body1).subscribe(res => { });

      }
      else {
        this.confirmationMsg = false;
        this.dashboardloader = true;
      }
    })

  }


  openFinalModel() {

    const body = {
      user_id: this.userId
    }

    let eventName = 'Ok_Button_Click_Preclosure_Amount_Detail'

    const body1 = {
      phone: this.phone,
      eventname: eventName,
      campign_number: this.campign_number,
      source: this.source,
      partner: sessionStorage.getItem('company_Name')

    }
    this.data.getEventName(body1).subscribe(res => { });


    this.data.PrepaymentPopupMsg2(body).subscribe(res => {

      if (res) {
        this.dynamicMsg2 = res.data
        this.finalCasepopup = true;
        this.dashboardloader = false;
      }
      else {
        this.finalCasepopup = false;
        this.dashboardloader = true;
      }
    })

  }



  // code for specific lead source

  withSpecificLeadSource() {

    this.finalCasepopup = false
    this.dashboardloader = true;
    this.showRemarks = true;

    const body2 = {

      mobile: this.phone,
      loan_id: this.loanName,
      user_id: this.userId,
      loan_stage: JSON.parse(this.StageName)[0].StageName
    }

    this.data.msgforeClosure(body2).subscribe(res => {
      this.model_overlay = true
      this.dashboardloader = false;
      this.preclosure_image = 'assets/Device_info.svg';
      this.information_msg = 'Information'
      this.preclosure_msg = res.message;
      // this.preclosure_msg = 'We have registered your request. We will connect with you within next 24 hrs.';

      let eventName = 'Prepayment_Popup_Shown'

      const body1 = {
        phone: this.phone,
        eventname: eventName,
        campign_number: this.campign_number,
        source: this.source,
        partner: sessionStorage.getItem('company_Name')

      }
      this.data.getEventName(body1).subscribe(res => { });
    },
      (error) => {

        const errormsg = JSON.parse(decryptString(error.error.data, this.secretKey))
        this.dashboardloader = false;
        this.model_overlay = true;
        this.showRemarks = true;
        this.preclosure_image = 'assets/Alert_1.svg'
        this.information_msg = 'Alert'
        this.preclosure_msg = errormsg.message.error

        let eventName = 'Prepayment_error_Popup_Shown'
        const body1 = {
          phone: this.phone,
          eventname: eventName,
          campign_number: this.campign_number,
          source: this.source,
          partner: sessionStorage.getItem('company_Name')

        }
        this.data.getEventName(body1).subscribe(res => { });
      })

    return;
  }

  closepopFinalpopUp() {
    this.finalCasepopup = false
  }



  // code for specific without lead source
  withOutSpecificLeadSource() {

    const body = {
      loanname: this.loanId
    }

    this.dashboardloader = true;
    this.confirmationMsg = false;

    let eventName = 'Double_Confirmation_Proceed'
    const body1 = {
      phone: this.phone,
      eventname: eventName,
      campign_number: this.campign_number,
      source: this.source,
      partner: sessionStorage.getItem('company_Name')

    }
    this.data.getEventName(body1).subscribe(res => { });

    this.data.getForclosure(body).subscribe(data => {
      this.fortrue = true;
      this.responsedata = data;
      this.dashboardloader = false;
      this.forcluserdata = this.responsedata.data.opp_data;
      this.paidPayableAmount = this.responsedata.data.total_amount;
      this.totalAmt = this.paidPayableAmount.toString().replace(/^-+/, '');
      if (this.forcluserdata.length == 1) {
        this.confirmationMsg = false;
        this.showRemarks = false;
        this.opName = this.forcluserdata[0].opName;
        this.creationDate = this.forcluserdata[0].calculation_as_on_dated;
        let eventName = 'Foreclosure_Success'
        const body1 = {
          phone: this.phone,
          eventname: eventName,
          campign_number: this.campign_number,
          source: this.source,
          partner: sessionStorage.getItem('company_Name')

        }
        this.data.getEventName(body1).subscribe(res => { });
        this.foreclosureValues.push(
          // {name:'Loan ID',value:this.forcluserdata[0].opName},
          { name: 'Calculation as on Dated', value: this.forcluserdata[0].calculation_as_on_dated == null ? 0 : this.forcluserdata[0].calculation_as_on_dated },
          { name: 'Principal Outstanding', value: this.forcluserdata[0].principal_outstanding == null ? 0 : this.forcluserdata[0].principal_outstanding },
          { name: 'Nach Rejection Charges', value: this.forcluserdata[0].nach_rejection_charges == null ? 0 : this.forcluserdata[0].nach_rejection_charges },
          { name: 'Advance EMI Amount', value: this.forcluserdata[0].advance_emi == null ? 0 : this.forcluserdata[0].advance_emi },
          { name: 'Net Principal as per Today', value: this.forcluserdata[0].net_principal_as_per_today == null ? 0 : this.forcluserdata[0].net_principal_as_per_today },
          { name: 'Arrears', value: this.forcluserdata[0].arrears == null ? 0 : this.forcluserdata[0].arrears },
          { name: 'Interest Amount', value: this.forcluserdata[0].total_due_interest == null ? 0 : this.forcluserdata[0].total_due_interest },
          { name: 'Overdue Interest', value: this.forcluserdata[0].total_overdue_interest == null ? 0 : this.forcluserdata[0].total_overdue_interest },
          { name: 'Bounce Charges', value: this.forcluserdata[0].bounce_charges == null ? 0 : this.forcluserdata[0].bounce_charges },
          { name: 'Foreclosure Charges', value: this.forcluserdata[0].foreclosureCharges == null ? 0 : this.forcluserdata[0].foreclosureCharges },
          { name: 'Moratorium Interest', value: this.forcluserdata[0].moratorium_interest == null ? 0 : this.forcluserdata[0].moratorium_interest },
          { name: 'GST 18%', value: this.forcluserdata[0].gstCharges == null ? 0 : this.forcluserdata[0].gstCharges },
          { name: 'Net Amount To Be Paid Today', value: this.responsedata.data.total_amount == null ? 0 : this.responsedata.data.total_amount },
          { name: 'Per Day Interest', value: this.forcluserdata[0].per_day_interest == null ? 0 : this.forcluserdata[0].per_day_interest }
        )
        for (let i = 0; i < this.foreclosureValues.length; i++) {
          this.csvResp[i] = this.foreclosureValues[i];
        }
        this.csvResp.push({ name: 'Loan ID', value: this.forcluserdata[0].opName });
        this.disableBtn = false;
      }
      else {
        this.showRemarks = true; this.Remarks = this.forcluserdata.Remark1;
        let eventName = 'Foreclosure-Not Allowed'
        const body1 = {
          phone: this.phone,
          eventname: eventName,
          campign_number: this.campign_number,
          source: this.source,
          partner: sessionStorage.getItem('company_Name')

        }
        this.data.getEventName(body1).subscribe(res => { });
      }
    },
      err => {
        this.forcluserdata = [];

        let eventName = 'Foreclosure-Failed'
        const body1 = {
          phone: this.phone,
          eventname: eventName,
          campign_number: this.campign_number,
          source: this.source,
          partner: sessionStorage.getItem('company_Name')

        }
        this.data.getEventName(body1).subscribe(res => { });
      }
    );


  }





  // downloadFile(){
  //   this.formula = 'foreclosureDetails';
  //   var options = {
  //     title : '',
  //     fieldSeparator : ',',
  //     quoteStrings : '"',
  //     decimalSeparator : '.',
  //     showLabels : true,
  //     showTitle : false,
  //     useBom : true
  //   }
  //   new Angular5Csv(this.csvResp,this.formula,options);
  // }

  // public downloadFile() {
  //   let source = 'DMI-Customer-Portal'
  //   let eventName = 'Download Foreclosure';
  //   this.disableBtn = true;
  //   this.data.getEventName(this.phone,eventName,localStorage.getItem('single_loan_name'), source, sessionStorage.getItem('company_Name')).subscribe(res=>{ 
  //     this.disableBtn = false;
  //   });

  //   const doc = new jsPDF('p', 'pt', 'letter');

  //   const specialElementHandlers = {
  //     '#editor': function (element, renderer) {
  //       return true;
  //     }
  //   };

  //   const pdfTable = this.pdfTable.nativeElement;

  //   doc.fromHTML(pdfTable.innerHTML, 15, 15, {
  //     width: 190,
  //     'align': 'center',
  //     'elementHandlers': specialElementHandlers
  //   });

  //   doc.save(this.opName+'-'+this.creationDate+'.pdf');
  // }
  getPdfDownLoad() {
    this.isDisabled = true;
    const body = {
      Access_Token: localStorage.getItem('dmi_token'),
      URL: localStorage.getItem('dmi_instance_url'),
      Loan_Name: this.loanId,
      Mobile: this.phone
    }

    this.data.preClouse(body).subscribe((res: any) => {
      if (res) {
        let pdfData = res.data
        this.docNameAddress = pdfData.replace('image/jpg', 'application/pdf')
        this.dataUrlFile()
        this.isDisabled = false;
      }
    }, (error) => { this.isDisabled = false; })
  }
  dataUrlFile() {
    const arr = this.docNameAddress.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    fileSaverSave(new File([u8arr], `${this.loanId}.pdf`, { type: 'pdf' }));
  }
  getUserInfo() {


    this.userBasicInfo = JSON.parse(localStorage.getItem('user_basic_info')) || [];

    this.city = this.userBasicInfo.data[0].MailingCity;
    this.country = this.userBasicInfo.data[0].MailingCountry;
    this.postalCode = this.userBasicInfo.data[0].MailingPostalCode;
    this.state = this.userBasicInfo.data[0].MailingState;
    this.street = this.userBasicInfo.data[0].MailingStreet;
    this.billingAddress = this.city + ',' + this.country + ',' + this.postalCode + ',' + this.state + ',' + this.street;
    this.userName = this.userBasicInfo.data[0].Name;
    this.email = this.userBasicInfo.data[0].Email;
    this.acHolderName = this.userBasicInfo.data[0].Name;
    this.loanDetails = JSON.parse(localStorage.getItem('loan_details')) || [];

    if (this.userBasicInfo.data[0].MobilePhone == null || this.userBasicInfo.data[0].MobilePhone == '' || this.userBasicInfo.data[0].MobilePhone == 'null') { this.phoneNo = this.userBasicInfo.data[0].Phone; }
    else { this.phoneNo = this.userBasicInfo.data[0].MobilePhone; }

  }

  getPartnerName() {

    const body5 = {
      phone: localStorage.getItem('dmi_phone')
    }

    this.data.getChannelPartner(body5).subscribe(res => {
      this.channelName = res;
      this.channelPartnerName = this.channelName.data;
    });
  }



  payAmountNow() {

    // Start event tracking
    this.paynowDisable = true;
    let eventName = 'Pay Loan EMI Amount'
    this.phone = localStorage.getItem('dmi_phone');
    let campign_number = this.loanName;
    let source = 'DMI-Customer-Portal';
    let method = 'Payment';
    this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
    if (this.channelName != '') {
      const body1 = {
        phone: this.phone,
        eventname: eventName,
        campign_number: campign_number,
        source: source,
        partner: sessionStorage.getItem('company_Name')

      }
      this.data.getEventName(body1).subscribe(res => {
      })
    }
    // end

    // this.paidPayableAmount = this.forcluserdata[0].total_amount_to_be_paid;
    // this.totalAmt  = this.paidPayableAmount.toString().replace(/[^A-Za-z0-9]+/g,"");
    let amount = Math.trunc(this.totalAmt);
    // console.log(amount)
    const body =
    {
      phone: this.phone,
      name: this.userName,
      loanname: this.loanName,
      amount: amount
    }
    this.data.getPayNow(body).subscribe(res => {
      this.paynowDisable = false;
      this.payNowData = res
      // console.log(this.payNowData)
      this.payNowcustomerId = this.payNowData.data.customerid.id;
      this.payNoworderId = this.payNowData.data.orderid.id;
      const razorpayKey = environment.rzp_key;
      var payNowOptions = {

        "key": razorpayKey,
        "currency": "INR",
        "name": this.loanName,
        "description": "Pay your emi",
        "image": "https://los.dmifinance.in/assets/images/logo.svg",
        "order_id": this.payNoworderId,//Order ID is generated as Orders API has been implemented. Refer the Checkout form table given below
        "handler": (response) => {
          this.display2 = 'block';
          const body = {
            phone: this.phone,
            loan_number: this.loanName,
            amount: amount,
            source: source,
            method: method,
            customer_id: this.payNowcustomerId,
            order_id: this.payNoworderId,
            name: this.userName,
            email: this.email
          }
          this.data.getpaymentRecords(body).subscribe(res => {
            if (res) {
              const body = {
                phone: this.phone,
                loan_number: this.loanName,
                amount: amount,
                method: "null"

              }

              this.data.foreclosurePayInfo(body).subscribe(res => { })
            }
            // alert('Saved payment record successfully.')
          })
        },
        "prefill": {
          "email": this.email,
          "contact": this.phone
        },
        "notes": {
          "opportunityName": this.loanName,
          "senderId": "DMI",
          "opportunityID ": this.loanId,
          "billingAddress": this.billingAddress,
          "customerName": this.userName1
        },
        "theme": {
          "color": "#F37254"
        }
      };

      let eventName = 'Razorpay_Popup_Open_Forclouser'

      const razorpayBody = {
        phone: this.phone,
        eventname: eventName,
        campign_number: localStorage.getItem('single_loan_name'),
        source: 'DMI-Customer-Portal',
        partner: sessionStorage.getItem('company_Name'),
        remark_1: payNowOptions

      }

      if (this.loanName) {
        this.data.getEventName(razorpayBody).subscribe();
        var rzp1 = new Razorpay(payNowOptions);
        rzp1.open();
      }
      else {

      }
    })
  }




  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 900000);
  }
  closeModelpopup() {
    this.model_overlay = false;
    this.showRemarks = false;
    this.router.navigate(['dashboard']);
  }

  closepopUp() {

    let eventName = 'Double_Confirmation_Close_Button'
    const body1 = {
      phone: this.phone,
      eventname: eventName,
      campign_number: this.campign_number,
      source: this.source,
      partner: sessionStorage.getItem('company_Name')

    }
    this.data.getEventName(body1).subscribe(res => { });

    this.confirmationMsg = false;
    this.router.navigate(['dashboard']);
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  ngOnDestroy() {
    clearTimeout(this.userActivity);
  }

}
