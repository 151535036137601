import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.scss']
})
export class PaymentModalComponent implements OnInit {

  userName:any='';
  @Output() proceedEvent:EventEmitter<any> = new EventEmitter();

  constructor(private activeModal: NgbActiveModal,
    private data:DataService) { }

  ngOnInit() {
    this.userName = localStorage.getItem('user_basic_name');
    this.eventTracking('Payment-Summary-Modal-Open');
  }

  close(){
    this.eventTracking('Payment-Summary-Modal-Close');
    this.currentModal.dismiss();
  }

  get currentModal(){
    return this.activeModal;
  }

  eventTracking(eventName){
    let phone = localStorage.getItem('dmi_phone');
    let campign_number = localStorage.getItem('single_loan_name');
    const body1={
      phone:phone,
       eventname:eventName, 
       campign_number:campign_number, 
       source:'DMI-Customer-Portal', 
       partner:sessionStorage.getItem('company_Name')

    }
    this.data.getEventName(body1).subscribe();
  }

}
