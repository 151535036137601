import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/data.service';
import { ShareDataService } from 'src/app/share-data.service';
import { HttpClient } from '@angular/common/http';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ExperianModalComponent } from 'src/app/modal/experian-modal/experian-modal.component';
import { ExperianPopupComponent } from 'src/app/modal/experian-popup/experian-popup.component';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  @Input() getEvent: Observable<void>;
  @ViewChild('closeFeedback') closeFeedback: ElementRef;
  eventsSubscription: Subscription;
  mobile: any;
  pageno: any = 1;
  records: any = 2;
  notification_type: any = 1;
  notificationArr: any = [];
  rating: any = '';
  ratingLog: any = '';
  reviews: any = '';
  showDial: boolean = false;
  campign_number: any;
  eventName: any;
  showGood: boolean = false;
  showAvg: boolean = false;
  showPoor: boolean = false;
  clickedStar: boolean = false;
  checkedFeedOnly: boolean = true;
  checkedFeedLog: boolean = true;
  getSession: any = '';
  giveFeedback: boolean = true;
  sessionAlert: boolean = false;
  sessionExist: boolean = false;
  showFeedback: boolean = false;
  feedBackLiabrary: boolean = false;
  user_details: any;
  params: any;
  isFound: boolean = false;
  feedbackResp: any = '';
  feedbackResponse: any = [];
  customer_id: any = "";
  name: any = "";
  user_email: any = "";
  user_phone: any = "";
  otpSentResponse: any = '';
  app_id: any = "";
  notify_channel = "none";
  experian_error: any = '';
  is_generate_short_url = false;
  showCreditScore: boolean = false;
  userBasicInfo: any = '';
  url: SafeResourceUrl;
  channelName: any = '';
  channelPartnerName: any = [];
  selectedGender: number;
  responseResult: any = [];
  titleAlert: string = 'This field is required';
  loanDetail: any = '';
  creditScoreRes: any = [];
  AA_Requried: any;
  feedBackDoneTost: boolean = false;
  feedBackDone: any;
  feedbackText: any;
  proceedText: string = 'Submit'
  chooseSatisfaction: boolean = true;
  feedBackDoneText: string = '';
  alreadyFeedback: boolean = false;
  tabName: any;
  userNameForm: any;
  alterName: any;
  alteremail: any;
  alterphone: any;
  alterPan: any;
  alterEmail: any;
  alterLoan: any;
  user_Id: any;
  alterAadhar: any;
  showLoader: boolean = false;
  loading: boolean = false;
  alterLeadSource: any;
  alterLead_source_Name: any;


  additionalContactForm: any = [];

  constructor(private router: Router, private data: DataService,
    public sanitizer: DomSanitizer, private http: HttpClient,
    private modalService: NgbModal, private toastr: ToastrService,
    private cd: ChangeDetectorRef,
    public service: ShareDataService, private fb: FormBuilder, private renderer: Renderer2) {
    this.additionalContactForm = this.fb.group({
      mobile2: ['', [Validators.pattern(/^\d{10}$/)]],
      mobile3: ['', [Validators.pattern(/^\d{10}$/)]],
      email2: ['', [Validators.email, this.invalidEmailValidator]],
    });
  }


  ngOnInit() {
    this.alterLead_source_Name = localStorage.getItem('pulled_lead_source_name');
    this.user_Id = sessionStorage.getItem('userID');
    this.getChannelPartnerName();
    this.lead_sourc();
    this.getalterUpdateMobile();
    if (localStorage.getItem('loanDetail')) { this.giveFeedback = false; }
    this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
    this.userBasicInfo = JSON.parse(localStorage.getItem('user_basic_info'));
    this.mobile = localStorage.getItem('dmi_phone');
    this.userNameForm = localStorage.getItem('user_basic_name');
    if (this.userBasicInfo != null) {
      this.customer_id = this.userBasicInfo.data[0].Id;
    }
    setTimeout(() => {
      if (localStorage.getItem('loanDetail')) { this.giveFeedback = false; }
      // console.log('Header')
    }, 20000);
  }
  Emandate() {
    this.router.navigate(['/helpcenter/createtickets']);
    // console.log("hekklk")
  }
  experian() {
    let phone = localStorage.getItem('dmi_phone')
    this.campign_number = 'null';
    let source = 'DMI-Customer-Portal';
    const body1 = {
      phone: phone,
      eventname: 'Get-Credit-Score',
      campign_number: this.campign_number,
      source: source,
      partner: this.channelPartnerName

    }


    this.data.getEventName(body1).subscribe();
    const body = {
      phone: phone
    }
    this.data.getCreditScoreRecord(body).subscribe(res => {
      if (res) {
        this.creditScoreRes = res;
        localStorage.setItem("Credit_Score_Data", JSON.stringify(this.creditScoreRes.data));
        const body1 = {
          phone: phone,
          eventname: 'Get-Credit-Score-Success',
          campign_number: this.campign_number,
          source: source,
          partner: this.channelPartnerName

        }
        this.data.getEventName(body1).subscribe();
        this.router.navigate(['/creditScoreDetail']);
      } else {
        let modalObj: NgbModalOptions = {
          backdrop: 'static',
          centered: true,
          windowClass: 'myCustomModalClass',
          keyboard: false,
        }
        const modal = this.modalService.open(ExperianModalComponent, modalObj);
      }
    },
      err => {
        const body1 = {
          phone: phone,
          eventname: 'Get-Credit-Score-Failed',
          campign_number: this.campign_number,
          source: source,
          partner: this.channelPartnerName

        }
        this.data.getEventName(body1).subscribe();
        let modalObj: NgbModalOptions = {
          backdrop: 'static',
          centered: true,
          windowClass: 'myCustomModalClass',
          keyboard: false,
        }
        const modal = this.modalService.open(ExperianModalComponent, modalObj);
      });
  }

  getExperianUser() {
    let phone = localStorage.getItem('dmi_phone')
    this.campign_number = 'null';
    let source = 'DMI-Customer-Portal';
    const body = {
      Mobile: localStorage.getItem('dmi_phone')
    }

    this.data.experianCheckUser(body).subscribe(res => {
      if (res.message == "Users Success.") {
        this.showCreditScore = true;
        const body1 = {
          phone: phone,
          eventname: 'Experian-User-WhiteListed',
          campign_number: this.campign_number,
          source: source,
          partner: this.channelPartnerName

        }
        this.data.getEventName(body1).subscribe();
      }
      else {
        const body1 = {
          phone: phone,
          eventname: 'Experian-User-Not-WhiteListed',
          campign_number: this.campign_number,
          source: source,
          partner: this.channelPartnerName

        }
        this.data.getEventName(body1).subscribe();
      }
    });
  }
  // Custom email validator (to catch unwanted email formats like 'ssssssssssssss')
  invalidEmailValidator(control: any) {
    const email = control.value;
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // Check if the email matches a valid pattern and is not a sequence of characters like "ssssssssssssss"
    if (email && !pattern.test(email)) {
      return { invalidEmail: true };
    }

    // You can also add additional checks like no repeating characters if needed
    return null;
  }
  // Helper method to check if form control is invalid and touched
  getControl(controlName: string) {
    return this.additionalContactForm.get(controlName);
  }
  openExperianPopup() {
    this.AA_Requried = this.service.getAA();
    if (this.AA_Requried == 'No' || this.AA_Requried == 'no') {

      let modalObj: NgbModalOptions = {
        backdrop: 'static',
        centered: true,
        windowClass: 'otpCustomModal experianPopup',
        keyboard: false,
      }
      const modal = this.modalService.open(ExperianPopupComponent, modalObj);
      modal.componentInstance.proceedEvent.subscribe(res => {
        if (res.proceed) {
          let modalObj: NgbModalOptions = {
            backdrop: 'static',
            centered: true,
            windowClass: 'myCustomModalClass',
            keyboard: false,
          }
          const modal = this.modalService.open(ExperianModalComponent, modalObj);
        }
      });
    }
  }


  // Functionality added for update alternate mobile number 
  // Developer : Rajiv Prajapati
  // Date : 03 Dec 2024 

  /////////////////////////////Alternate mobile functionality start////////////////////////

  onlyAllowNumbers(event: any) {
    const inputValue = event.target.value;
    // Allow only digits (0-9)
    event.target.value = inputValue.replace(/[^0-9]/g, '');
  }

  new_lead_source_name: any;
  userAlterInfo: any;
  altName: any;
  checkPopup: any;
  lead_sourc() {
    const body = {
      phone: localStorage.getItem('dmi_phone'),
      limit: 5,
      offset: 0
    }

    // Add comprehensive error handling and logging
    this.data.getLoanCountDetails(body).subscribe({
      next: (res) => {
        // Defensive programming: Add null/undefined checks
        if (!res || !res.data || res.data.length === 0) {
          console.error('No data received from getLoanCountDetails');
          return;
        }

        this.userAlterInfo = res;

        // Safely access properties with optional chaining
        this.altName = this.userAlterInfo.data[0].Name;
        this.new_lead_source_name = this.userAlterInfo.data[0].Pulled_Lead_Source_Name__c;

        // More robust status checking
        if (res.status === 200 && ['Gpay Merchant', 'Samsung TA', 'Lendingkart', 'Samsung DF', 'Saarathi',
          'Faircent', 'TradeUdhaar', 'Getvantage', 'Mahila Money', 'Cateye',
          'Andromeda', 'Klub', 'Kaliedofin'].includes(this.new_lead_source_name)) {
          const popupBody = {
            mobile: localStorage.getItem('dmi_phone'),
          }

          console.log('Attempting to check alternate popup', popupBody);

          this.data.checkAlterPopup(popupBody).subscribe({
            next: (popupRes) => {
              console.log('Popup API response:', popupRes);

              // More robust checking of popup response
              if (popupRes.data.show_popup == true) {
                this.userBasicInfo = JSON.parse(localStorage.getItem('user_basic_info'));
                this.alterLeadSource = JSON.parse(localStorage.getItem('channel_partner_name'));

                console.log("alterdatalload", this.userBasicInfo)
                this.alterName = this.userBasicInfo.data[0].Name;
                this.alteremail = this.userBasicInfo.data[0].Email;
                this.alterphone = this.userBasicInfo.data[0].Phone;
                this.alterPan = this.userBasicInfo.data[0].Pan;
                this.alterAadhar = this.userBasicInfo.data[0].Aadhar;
                this.alterLoan = localStorage.getItem('single_loan_name');
                this.alterLead_source_Name = localStorage.getItem('pulled_lead_source_name');
                document.getElementById('MyProfile').click();

                // Use multiple methods to trigger popup
                const popupElement = document.getElementById('alternatemobile');
                if (popupElement) {
                  try {
                    // Multiple click methods
                    popupElement.click();
                    popupElement.dispatchEvent(new MouseEvent('click'));
                  } catch (error) {
                    console.error('Error triggering popup:', error);
                  }
                } else {
                  console.warn('Popup element not found in DOM');
                }
              } else {
                console.log('Popup should not be shown', popupRes);
              }
            },
            error: (error) => {
              console.error('Error in checkAlterPopup:', error);
            }
          });
        } else {
          console.log('Status not 200 or no data', res);
        }
      },
      error: (error) => {
        console.error('Error in getLoanCountDetails:', error);
      }
    });
  }

  editedFields: { [key: string]: boolean } = { mobile2: false, mobile3: false, email2: false };
  isEditing: { [key: string]: boolean } = { mobile2: true, mobile3: true, email2: true };

  toggleEdit(field: string): void {
    const controlValue = this.getControl(field).value.trim();

    if (controlValue && this.isEditing[field]) {
      this.handleSave(field); // Save if data exists
    } else {
      this.isEditing[field] = true; // Enter editing mode
    }
  }


  Emaildata: any;
  emailotp: any;
  mobileotp: any;
  MobileNumber: any;
  MobileVerify: boolean = false;
  EmailVerify: boolean = false;
  handleSave(field: string): void {
    this.showLoader = true;
    const formValues = this.additionalContactForm.value; // Get form values
    const phone_alternate = localStorage.getItem('dmi_phone'); // Get alternate phone from local storage
    const userId = this.user_Id; // Get user ID

    if (field === 'mobile2' && formValues.mobile2.trim()) {
      // Prepare payload for Mobile No. 2
      const mobilePayload = {
        user_id: userId,
        mobile_type:'mobile_two',
        leadsource: this.new_lead_source_name,
        opp_name: this.alterLoan,
        customer_mobile: phone_alternate,
        alternate_mobile: formValues.mobile2.trim(),
      };
      this.MobileNumber = formValues.mobile2.trim();
      // Call API to send OTP for Mobile No. 2
      this.data.sendOTPAlternateMobile(mobilePayload).subscribe(
        (response) => {
          console.log('OTP sent for Mobile No. 2:', response);
          // alert('OTP sent to Mobile No. 2');
          this.showLoader = false;
          document.getElementById('otpPopupalternate').click();
          this.eventTracking('Alternate_Mobile2_OTP_Send');
          this.isEditing['mobile2'] = false; // Lock the field after saving
          this.MobileVerify = true;
          this.EmailVerify = false;
          // Access index dynamically
          this.startMobileCountdown();
        },
        (error) => {
          console.error('Error sending OTP for Mobile No. 2:', error);
          // alert('Failed to send OTP for Mobile No. 2');
        }
      );
    } else if (field === 'mobile3' && formValues.mobile3.trim()) {
      // Prepare payload for Mobile No. 3
      const mobilePayload = {
        user_id: userId,
        mobile_type:'mobile_three',
        leadsource: this.new_lead_source_name,
        opp_name: this.alterLoan,
        customer_mobile: phone_alternate,
        alternate_mobile: formValues.mobile3.trim(),
      };
      this.MobileNumber = formValues.mobile3.trim();
      // Call API to send OTP for Mobile No. 3
      this.data.sendOTPAlternateMobile(mobilePayload).subscribe(
        (response) => {
          console.log('OTP sent for Mobile No. 3:', response);
          this.eventTracking('Alternate_Mobile3_OTP_Send');
          // alert('OTP sent to Mobile No. 3');
          this.showLoader = false;
          document.getElementById('otpPopupalternate').click();
          this.isEditing['mobile3'] = false; // Lock the field after saving
          this.MobileVerify = true;
          this.EmailVerify = false;
          this.startMobileCountdown();
        },
        (error) => {
          console.error('Error sending OTP for Mobile No. 3:', error);
          // alert('Failed to send OTP for Mobile No. 3');
        }
      );
    } else if (field === 'email2' && formValues.email2.trim()) {
      // Prepare payload for Email ID 2
      const emailPayload = {
        user_id: userId,

        leadsource: this.new_lead_source_name,
        opp_name: this.alterLoan,
        customer_mobile: phone_alternate,
        alternate_email: formValues.email2.trim(),
      };
      this.Emaildata = formValues.email2.trim();
      // Call API to send OTP for Email ID 2
      this.data.sendOTPAlternateEmail(emailPayload).subscribe(
        (response) => {
          console.log('OTP sent for Email ID 2:', response);
          this.eventTracking('Alternate_Email2_OTP_Send');
          // alert('OTP sent to Email ID 2');
          this.showLoader = false;
          document.getElementById('otpPopupalternate').click();
          this.isEditing['email2'] = false; // Lock the field after saving'
          this.MobileVerify = false;
          this.EmailVerify = true;
          this.startEmailCountdown();
        },
        (error) => {
          console.error('Error sending OTP for Email ID 2:', error);
          // alert('Failed to send OTP for Email ID 2');
        }
      );
    } else {
      // Handle invalid inputs
      // alert('Please enter a valid value before saving.');
    }
  }


  updateAlterNumber() {
    document.getElementById('otpPopupalternateSave').click();
  }



  alternateMobiles = [
    { mobile: '', otp: '' },
  ];

  alternateEmails = [
    { email: '', otp: '' },
  ];
  mobileArray: any[] = [];
  emailArray: any[] = [];

  storeMobileData(formValues: any): void {
    // Clear the previous mobile array
    this.mobileArray = [];
    console.log(formValues)
    // Dynamically check for mobile inputs and add to the array
    if (formValues.mobile2 && formValues.mobile2.trim()) {
      this.mobileArray.push({ mobile: formValues.mobile2.trim(), otp: '' });
    }

    if (formValues.mobile3 && formValues.mobile3.trim()) {
      this.mobileArray.push({ mobile: formValues.mobile3.trim(), otp: '' });

    }
    if (formValues.email2 && formValues.email2.trim()) {
      this.emailArray.push({ email: formValues.email2.trim(), otp: '' });

    }
    // Optionally, sync the data to alternateMobiles
    // this.alternateMobiles = [...this.mobileArray];
    this.alternateMobiles = this.mobileArray.slice(); // Use slice to create a shallow copy
    this.alternateEmails = this.emailArray.slice();
    console.log('Stored Mobile Data:', this.mobileArray, this.emailArray)
    // Access index dynamically
    this.mobileArray.forEach((mobileObj, index) => {
      console.log(`Mobile ${index}:`, mobileObj.mobile);
      // this.resetTimer(index, 'mobile');
    });

    this.emailArray.forEach((emailObj, index) => {
      console.log(`Email ${index}:`, emailObj.email);
      // this.resetTimer(index, 'email');
    });
  }


  Formreset(): void {
    // Clear the arrays for alternate mobiles and emails
    this.alternateMobiles = [];
    this.alternateEmails = [];

    // Optionally clear any other state or form controls
    this.mobileArray = [];
    this.emailArray = [];

    console.log('Form data has been reset');
  }

  isAnyOtpEntered(): boolean {
    // Check if any mobile OTP has a value
    const isMobileOtpEntered = this.alternateMobiles.some(mobile => mobile.otp.trim() !== '');

    // Check if any email OTP has a value
    const isEmailOtpEntered = this.alternateEmails.some(email => email.otp.trim() !== '');

    // Return true if at least one OTP (mobile or email) is entered
    return isMobileOtpEntered || isEmailOtpEntered;
  }

  isAnyFieldEntered(): boolean {
    const mobile2 = this.additionalContactForm.get('mobile2').value.trim() || '';
    const mobile3 = this.additionalContactForm.get('mobile3').value.trim() || '';
    const email2 = this.additionalContactForm.get('email2').value.trim() || '';

    // Return true if at least one field is filled
    return mobile2 !== '' || mobile3 !== '' || email2 !== '';
  }

  // ------------------Alternate mobile functionality End ------------------ 

  mobileOTPError: string = '';
  emailOTPError: string = '';

  verifyAllOTPs(): void {
    this.showLoader = true;
    const phone_alternate = localStorage.getItem('dmi_phone');

    if (this.MobileVerify) {
      let mobile_type = '';
      if (this.additionalContactForm.get('mobile2').value === this.MobileNumber) {
        mobile_type = 'mobile_two';
      } else if (this.additionalContactForm.get('mobile3').value === this.MobileNumber) {
        mobile_type = 'mobile_three';
      }
      // Call Mobile OTP verification API
      const payload = {
        user_id: this.user_Id,
        mobile_type: mobile_type,
        leadsource: this.new_lead_source_name,
        opp_name: this.alterLoan,
        customer_mobile: phone_alternate,
        alternate_mobile: this.MobileNumber,
        otp: this.mobileotp,
      };

      this.data.verifyOTPAlternateMobile(payload).subscribe(
        (response) => {
          console.log('Mobile OTP verification response:', response);
          if (response.status == 200) {
            this.showLoader = false;
            this.eventTracking('OTP_Verify_For_Alternate_Mobile');
            this.closeModalManually('otpVerificationalter');
            document.getElementById('otpPopupalternateSave').click();
            // this.triggerOTPPopup();
          } else {

          }
        },
        (error) => {
          console.error('Error verifying Mobile OTP:', error);
          this.mobileOTPError = 'Invalid or incorrect OTP. Please try again.';
        }
      );
    } else if (this.EmailVerify) {
      // Call Email OTP verification API
      const payload = {
        user_id: this.user_Id,
        leadsource: this.new_lead_source_name,
        opp_name: this.alterLoan,
        customer_mobile: phone_alternate,
        alternate_email: this.Emaildata,
        otp: this.emailotp,
      };

      this.data.verifyOTPAlternateEmail(payload).subscribe(
        (response) => {
          console.log('Email OTP verification response:', response);
          if (response.status == 200) {
            this.showLoader = false;
            this.eventTracking('OTP_Verify_For_Alternate_Email');
            this.closeModalManually('otpVerificationalter');
            document.getElementById('otpPopupalternateSave').click();
          } else {

          }
        },
        (error) => {
          console.error('Error verifying Email OTP:', error);
          this.emailOTPError = 'Invalid or incorrect OTP. Please try again.';
        }
      );
    } else {
      // alert('No OTP type selected for verification.');
    }
  }

  closeModalManually(modalId: string): void {
    const modalElement = document.getElementById(modalId);
    if (modalElement) {
      modalElement.classList.remove('show'); // Hide the modal
      modalElement.setAttribute('aria-hidden', 'true');
      modalElement.style.display = 'none';
      document.body.classList.remove('modal-open'); // Remove the body class
      const backdrop = document.querySelector('.modal-backdrop');
      if (backdrop) backdrop.remove(); // Remove the backdrop
    }
  }

  getalterUpdateMobile() {
    const phone_alternate = localStorage.getItem('dmi_phone');
    const alterBody = {
      user_id: this.user_Id,
      customer_mobile: phone_alternate,
    };

    this.data.updatedMobileGet(alterBody).subscribe(res => {
      console.log("updatemobile data get", res);

      if (res.status === 200 && res.data) {
        const data = res.data;
        // Prefill the form with API data
        this.additionalContactForm.patchValue({
          mobile2: data.latest_phones[0] || '',
          mobile3: data.latest_phones[1] || '',
          email2: data.latest_emails[0] || ''
        });
        this.isEditing = {
          mobile2: !data.latest_phones[0], // Show Save if empty, Edit otherwise
          mobile3: !data.latest_phones[1],
          email2: !data.latest_emails[0]
        };
      } else {
        console.error("Failed to fetch data or invalid response format", res);
      }
    },
      (error) => {
        console.error("Error fetching updated mobile data", error);
      }
    );
  }

  mobileTimer: number = 59; // Single timer for mobile OTP
  emailTimer: number = 59; // Single timer for email OTP

  // Start countdown for mobile
  startMobileCountdown() {
    const interval = setInterval(() => {
      if (this.mobileTimer > 0) {
        this.mobileTimer--;
      } else {
        clearInterval(interval);
      }
    }, 1000);
  }

  // Start countdown for email
  startEmailCountdown() {
    const interval = setInterval(() => {
      if (this.emailTimer > 0) {
        this.emailTimer--;
      } else {
        clearInterval(interval);
      }
    }, 1000);
  }

  // Resend OTP Logic
  resendOTP(type: 'mobile' | 'email'): void {
    if (type === 'mobile') {
      // Determine mobile_type based on which mobile number was previously used
      let mobile_type = '';
      
      // Check which mobile number was used to send OTP
      if (this.additionalContactForm.get('mobile2').value === this.MobileNumber) {
        mobile_type = 'mobile_two';
      } else if (this.additionalContactForm.get('mobile3').value === this.MobileNumber) {
        mobile_type = 'mobile_three';
      }
  
      this.data.sendOTPAlternateMobile({
        user_id: this.user_Id,
        mobile_type: mobile_type, // Add mobile_type to the payload
        leadsource: this.new_lead_source_name,
        opp_name: this.alterLoan,
        customer_mobile: localStorage.getItem('dmi_phone'),
        alternate_mobile: this.MobileNumber ? this.MobileNumber : this.EmailVerify,
      }).subscribe(response => {
        console.log('Mobile OTP resent:', response);
        this.eventTracking('resend_alternate_mobile_button_click');
        this.mobileTimer = 59;
        this.startMobileCountdown();
      });
    }
  
    if (type === 'email') {
      this.data.sendOTPAlternateEmail({
        user_id: this.user_Id,
        leadsource: this.new_lead_source_name,
        opp_name: this.alterLoan,
        customer_mobile: localStorage.getItem('dmi_phone'),
        alternate_email: localStorage.getItem('alternate_email'),
      }).subscribe(response => {
        console.log('Email OTP resent:', response);
        this.eventTracking('resend_alternate_email_button_click');
        this.emailTimer = 59;
        this.startEmailCountdown();
      });
    }
  }

  // Helper function for formatting the timer
  formatTimer(time: number): string {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  resetTimer(type: 'mobile' | 'email'): void {
    if (type === 'mobile') {
      this.startCountdown(); // Reset mobile timer

    } else if (type === 'email') {
      this.emailTimer = 59; // Reset email timer
    }

    // Restart the countdown
    this.startCountdown();
  }

  mobileTimers: number[] = []; // Timers for mobile OTPs
  emailTimers: number[] = [];  // Timers for email OTPs
  timerIntervals: any[] = [];  // Store intervals for cleanup

  initializeTimers(): void {
    this.mobileTimers = this.alternateMobiles.map(() => 59); // 59 seconds for each mobile
    this.emailTimers = this.alternateEmails.map(() => 59);  // 59 seconds for each email

    this.startCountdown();
  }
  startCountdown(): void {
    // Clear any existing intervals
    this.clearIntervals();

    // Start countdown for mobiles
    this.alternateMobiles.forEach((_, index) => {
      this.timerIntervals[index] = setInterval(() => {
        if (this.mobileTimers[index] > 0) {
          this.mobileTimers[index]--;
        } else {
          clearInterval(this.timerIntervals[index]); // Stop when the timer hits 0
        }
      }, 1000); // Decrease every second
    });

    // Start countdown for emails
    this.alternateEmails.forEach((_, index) => {
      this.timerIntervals[index + this.alternateMobiles.length] = setInterval(() => {
        if (this.emailTimers[index] > 0) {
          this.emailTimers[index]--;
        } else {
          clearInterval(this.timerIntervals[index + this.alternateMobiles.length]);
        }
      }, 1000);
    });
  }


  clearIntervals(): void {
    this.timerIntervals.forEach((interval) => clearInterval(interval));
    this.timerIntervals = [];
  }



  // ------------------------------------nps modal start--------------------------------

  npsModel(event: any) {
    this.tabName = event
    this.url = '';
    if (this.channelName != '') {
      let phone = localStorage.getItem('dmi_phone');
      this.campign_number = 'null';
      this.eventName = `Click on the ${this.tabName} tab`;  // Ensure there's a space after 'the'
      let source = 'DMI-Customer-Portal';

      const body1 = {
        phone: phone,
        eventname: this.eventName,
        campign_number: this.campign_number,
        source: source,
        partner: this.channelPartnerName
      };

      this.data.getEventName(body1).subscribe(res => {
        // handle response if necessary
      });
    }
    if (this.tabName == 'alternatemobile') {
      this.userBasicInfo = JSON.parse(localStorage.getItem('user_basic_info'));
      this.alterLeadSource = JSON.parse(localStorage.getItem('channel_partner_name'));

      console.log("alterdatalload", this.userBasicInfo)
      this.alterName = this.userBasicInfo.data[0].Name;
      this.alteremail = this.userBasicInfo.data[0].Email;
      this.alterphone = this.userBasicInfo.data[0].Phone;
      this.alterPan = this.userBasicInfo.data[0].Pan;
      this.alterAadhar = this.userBasicInfo.data[0].Aadhar;
      this.alterLoan = localStorage.getItem('single_loan_name');
      this.alterLead_source_Name = localStorage.getItem('pulled_lead_source_name');
      document.getElementById('MyProfile').click();
      this.eventTracking('My_Profile_Open');
    }
    else {
      const checkQDSBody = {
        phone_no: localStorage.getItem('dmi_phone')
      }

      this.data.checkQdsFeedback(checkQDSBody).subscribe((res) => {
        if (res.status == 200) {
          if (res.data.is_eligible === true) {
            document.getElementById('paymentlink').click();
            this.chooseSatisfaction = true;
            this.feedbackText = '';
            this.feedBackDoneText = '';
            this.feedBackDoneTost = false;
          }
          else {
            if (this.tabName === 'logout') {
              this.logout();
              this.sessionExist = false;
            }
            else {
              this.alreadyFeedback = true;
              this.feedBackDone = res.message;
              setTimeout(() => {
                this.alreadyFeedback = false;
              }, 2000)
            }
          }

        }
        else {
          console.log('')
        }
      })

    }
  }


  userNotSatisfied() {
    this.chooseSatisfaction = !this.chooseSatisfaction;
    if (this.channelName != '') {
      let phone = localStorage.getItem('dmi_phone');
      this.campign_number = 'null';
      this.eventName = `Tab name ${this.tabName} : Not satisfied with your service`;  // Ensure there's a space after 'the'
      let source = 'DMI-Customer-Portal';
      const body1 = {
        phone: phone,
        eventname: this.eventName,
        campign_number: this.campign_number,
        source: source,
        partner: this.channelPartnerName
      };

      this.data.getEventName(body1).subscribe(res => {
        // handle response if necessary
      });
    }
  }




  getQdsFeedback() {
    const body = {
      contact_no: localStorage.getItem('dmi_phone'),
      voc: this.feedbackText
    }

    this.proceedText = 'Loading...'
    this.data.storeQdsFeedback(body).subscribe(res => {
      if (res.status == 200) {
        this.proceedText = 'Proceed'
        this.feedBackDoneTost = true;
        this.feedBackDoneText = res.message
        if (this.channelName != '') {
          let phone = localStorage.getItem('dmi_phone');
          this.campign_number = 'null';
          this.eventName = `Tab name ${this.tabName} : Custom feedback stored`;  // Ensure there's a space after 'the'
          let source = 'DMI-Customer-Portal';
          const body1 = {
            phone: phone,
            eventname: this.eventName,
            campign_number: this.campign_number,
            source: source,
            partner: this.channelPartnerName
          };

          this.data.getEventName(body1).subscribe(res => {
            // handle response if necessary
          });
        }
      }
      else {
        this.proceedText = 'Loading...'

      }

    }, (error) => {

      this.proceedText = 'Proceed'
      if (this.channelName != '') {
        let phone = localStorage.getItem('dmi_phone');
        this.campign_number = 'null';
        this.eventName = `Tab name ${this.tabName} : Custom feedback stored failed`;  // Ensure there's a space after 'the'
        let source = 'DMI-Customer-Portal';
        const body1 = {
          phone: phone,
          eventname: this.eventName,
          campign_number: this.campign_number,
          source: source,
          partner: this.channelPartnerName
        };

        this.data.getEventName(body1).subscribe(res => {
          // handle response if necessary
        });
      }
    })
  }


  checkRatingStats() {
    this.showFeedback = true;
    this.userBasicInfo = '';
    this.customer_id = '';
    this.userBasicInfo = JSON.parse(localStorage.getItem('user_basic_info'));
    this.customer_id = this.userBasicInfo.data[0].Id;
    this.name = this.userBasicInfo.data[0].Name;
    this.user_email = this.userBasicInfo.data[0].Email;
    if (this.userBasicInfo.data[0].Phone == null) {
      this.user_phone = this.userBasicInfo.data[0].MobilePhone;
    } else { this.user_phone = this.userBasicInfo.data[0].Phone; }
    const body1 = {
      phone: localStorage.getItem('dmi_phone')
    }
    this.data.getChannelPartner(body1).subscribe(res => {
      this.channelName = res;
      this.channelPartnerName = this.channelName.data;
      if (this.channelName != '') {
        this.app_id = this.service.getAppIdforFeedback(this.channelPartnerName);
        if (this.app_id == '') { this.app_id = 'juz6_test'; }

        this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
        const body = {
          phone: this.user_phone,
          loan_id: this.loanDetail[0].name,
          loan_amount: this.loanDetail[0].Original_Face__c,
          loan_disbursement_date: this.loanDetail[0].Disbursement_Date__c,
          channel_partner_name: this.channelPartnerName,
          email_id: this.user_email,
          name: this.name
        }

        this.data.getFeedbackUrl(body).subscribe(res => {

          if (res) {
            this.feedbackResp = res;
            if (this.feedbackResp.data === '') {
              this.feedBackDone = this.feedbackResp.message

              if (this.channelName != '') {
                let phone = localStorage.getItem('dmi_phone');
                this.campign_number = 'null';
                this.eventName = `Tab name ${this.tabName} : You have already given your feedback.`;  // Ensure there's a space after 'the'
                let source = 'DMI-Customer-Portal';

                const body1 = {
                  phone: phone,
                  eventname: this.eventName,
                  campign_number: this.campign_number,
                  source: source,
                  partner: this.channelPartnerName
                };

                this.data.getEventName(body1).subscribe(res => {
                  // handle response if necessary
                });
              }
              if (this.tabName === 'logout') {
                this.logout();
                this.sessionExist = false;
              }
            }
            else {
              this.feedbackResponse = this.feedbackResp.data.url;
              this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.feedbackResponse);
            }
          }
        });
      }
    });

    ////////////////////////////document///////////////////////////

    if (window.addEventListener) {
      window.addEventListener("message", onMessage, false);
    }
    else if ((<any>window).attachEvent) {
      (<any>window).attachEvent("onmessage", onMessage, false);
    }
    function onMessage(event) {
      if (event.origin !== "https://app.litmusworld.com" || event.origin !== "https://app-india.litmusworld.com" || event.origin !== "https://dashboard.litmusworld.com" ||
        event.origin !== "https://dashboard-india.litmusworld.com") return;

      var data = event.data;
      if (typeof (window[data.func]) == "function") {
        // window[data.func].call(null, data.message);
        alert(event);
      }
    }

    ////////////////////////////document///////////////////////////
  }



  checkRatingLogout() {
    this.getSession = localStorage.getItem('sesssionRating');
    if (this.getSession != null) {
      this.sessionExist = true;
    } else if (this.getSession == null) {
      this.logout();
      this.sessionExist = false;
    }
  }

  logout() {

    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/']);
  }

  getfeedbackLogout() {
    let phone = localStorage.getItem('dmi_phone')
    this.campign_number = 'null';
    this.eventName = 'Made an action to "Logout" from Top Menu'
    let source = 'DMI-Customer-Portal'
    this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
    if (this.channelName != '') {

      const body1 = {
        phone: phone,
        eventname: this.eventName,
        campign_number: this.campign_number,
        source: source,
        partner: this.channelPartnerName

      }
      this.data.getEventName(body1).subscribe(res => {
      })
    }

    if (this.reviews == '') {
      this.reviews = '-';
    }
    if (this.clickedStar == true) {
      localStorage.removeItem('sesssionRating');
      const body = {
        phone: this.mobile,
        rating: this.ratingLog,
        description: this.reviews

      }
      this.data.getFeedback(body).subscribe(res => {
        if (res) {
        }
        this.ratingLog = '';
        this.showDial = false;
        this.showPoor = false; this.showAvg = false; this.showGood = false;
        this.logout();
      });
    }
    else {
      this.logout();
    }
  }

  getfeedbackOnly() {
    let phone = localStorage.getItem('dmi_phone')
    this.campign_number = 'null';
    this.eventName = 'Made an action to "Give Feedback" From Top Menu'
    let source = 'DMI-Customer-Portal'
    this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
    if (this.channelName != '') {
      const body1 = {
        phone: phone,
        eventname: this.eventName,
        campign_number: this.campign_number,
        source: source,
        partner: this.channelPartnerName

      }
      this.data.getEventName(body1).subscribe(res => {
      })
    }
    if (this.reviews == '') {
      this.reviews = '-';
    }
    localStorage.removeItem('sesssionRating');
    const body = {
      phone: this.mobile,
      rating: this.rating,
      description: this.reviews

    }
    this.data.getFeedback(body).subscribe(res => {
      if (res) {
      }
      this.rating = '';
      this.showDial = false;
      this.showPoor = false; this.showAvg = false; this.showGood = false;
    });
  }

  onRate(newValue: number) {
    this.clickedStar = false;
    if (newValue >= 1) {
      this.checkedFeedOnly = false;
    }
    this.showPoor = false; this.showAvg = false; this.showGood = false;
    this.rating = newValue;
    if (newValue) { this.showDial = true; }
    if (newValue <= 7) { this.showPoor = true; }
    else if (newValue < 10 && newValue > 7) { this.showAvg = true; }
    else if (newValue > 9) { this.showGood = true; }
  }

  onRateLogout(newValue: number) {
    this.clickedStar = true;
    if (newValue >= 1) {
      this.checkedFeedLog = false;
    }
    this.showPoor = false; this.showAvg = false; this.showGood = false;
    this.ratingLog = newValue;
    if (newValue) { this.showDial = true; }
    if (newValue <= 7) { this.showPoor = true; }
    else if (newValue < 10 && newValue > 7) { this.showAvg = true; }
    else if (newValue > 9) { this.showGood = true; }
  }

  closeRate() {


    if (this.channelName != '') {
      let phone = localStorage.getItem('dmi_phone')
      this.campign_number = 'null';
      this.eventName = `${this.tabName} tab: Click on NPS model close button from top menu`
      let source = 'DMI-Customer-Portal';
      const body1 = {
        phone: phone,
        eventname: this.eventName,
        campign_number: this.campign_number,
        source: source,
        partner: this.channelPartnerName

      }
      this.data.getEventName(body1).subscribe(res => {
      })
    }
    if (this.tabName === 'logout') {
      this.logout();
      this.sessionExist = false;
    }
  }

  closeRateLogout() {
    if (this.channelName != '') {
      let phone = localStorage.getItem('dmi_phone')
      this.campign_number = 'null';
      this.eventName = 'Logout from Feedback window'
      let source = 'DMI-Customer-Portal';
      const body1 = {
        phone: phone,
        eventname: this.eventName,
        campign_number: this.campign_number,
        source: source,
        partner: this.channelPartnerName

      }
      this.data.getEventName(body1).subscribe(res => {
      });
      this.logout();
    }
  }



  getNotification() {
    if (sessionStorage.getItem('firstTime')) {
      const body = {
        phone: localStorage.getItem('dmi_phone')
      }
      this.data.getCreditScoreRecord(body).subscribe(res => {
        if (res) {
          this.creditScoreRes = res;
          let source = 'DMI-Customer-Portal';
          this.campign_number = 'null';
          localStorage.setItem("Credit_Score_Data", JSON.stringify(this.creditScoreRes.data));
          const body1 = {
            phone: localStorage.getItem('dmi_phone'),
            eventname: 'Get-Credit-Score-Success',
            campign_number: this.campign_number,
            source: source,
            partner: this.channelPartnerName

          }

          this.data.getEventName(body1).subscribe();
        } else {
          setTimeout(() => {
            this.openExperianPopup();
          }, 2000);
        }
      }, error => {
        setTimeout(() => {
          this.openExperianPopup();
        }, 2000);
      });
    }
    const body = {
      mobile: this.mobile,
      pageno: this.pageno,
      no_of_records_per_page: this.records,
      notification_type: this.notification_type
    }

    this.data.getNotificationList(body).subscribe(res => { this.notificationArr = res; });
  }

  notificationDetails() {
    this.router.navigate(['helpcenter/notification&alertdetails'])
  }

  getSafeUrl() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.feedbackResponse);
  }

  getChannelPartnerName() {
    const body = {
      phone: localStorage.getItem('dmi_phone')
    }
    this.data.getChannelPartner(body).subscribe(res => {
      this.channelName = res;
      this.channelPartnerName = this.channelName.data;
      this.getNotification();
      //this.getExperianUser();
    });
  }
//  Add Event Tracking 
  eventTracking(Event_Name) {
    const body = {
      phone: localStorage.getItem('dmi_phone'),
      eventname: Event_Name,
      campign_number: localStorage.getItem('single_loan_name'),
      source: 'DMI-Customer-Portal',
      partner: sessionStorage.getItem('company_Name'),

    }
    this.data.getEventName(body).subscribe();

  }

  ngOnDestroy() {
    this.modalService.dismissAll();
  }

}

