import { Component, OnInit, ViewContainerRef, HostListener } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-notification-details',
  templateUrl: './notification-details.component.html',
  styleUrls: ['./notification-details.component.scss']
})
export class NotificationDetailsComponent implements OnInit {
  userActivity;
  userInactive: Subject<any> = new Subject();
  mobile: any;
  pageno: any = 1;
  records: any = 2;
  loanDetail: any = '';
  notification_type_notif: any = 1;
  notification_type_alert: any = 2;
  tabId: any;

  notificationArr: any = [];
  alertArr: any = [];
  trendingArr: any = [];
  arrofTrending: any = [];
  blogDetailsData: any;
  channelName: any = '';
  channelPartnerName: any = '';


  constructor(private service: DataService,
    private router: Router) {
    this.setTimeout();
    this.userInactive.subscribe(() => {
      localStorage.clear();
      this.router.navigate(['customerportal&currentuser&sessiontimeout'])
    });
  }
  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 900000);
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
  lead_source: any;
  ngOnInit() {
    this.lead_source = sessionStorage.getItem('company_Name')
    const body1 = {
      phone: localStorage.getItem('dmi_phone')
    }
    this.service.getChannelPartner(body1).subscribe(res => {
      this.channelName = res;
      this.channelPartnerName = this.channelName.data;
    });
    this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
    this.mobile = localStorage.getItem('dmi_phone');
    this.getNotification();
    this.getTrendingList();
  }

  getNotification() {
    if (this.channelName != '') {
      let eventName = 'Notification Event'
      let phone = localStorage.getItem('dmi_phone');
      let campign_number = 'Not Available'
      let source = 'DMI-Customer-Portal';
      this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
      const body1 = {
        phone: phone,
        eventname: eventName,
        campign_number: campign_number,
        source: source,
        partner: this.channelPartnerName

      }
      this.service.getEventName(body1).subscribe(res => {
      })
    }
    const body = {
      mobile: this.mobile,
      pageno: this.pageno,
      no_of_records_per_page: this.records,
      notification_type: this.notification_type_notif
    }
    this.service.getNotificationList(body).subscribe(res => {
      this.notificationArr = res;
    });
  }

  getAlert() {
    if (this.channelName != '') {
      let eventName = 'Alert Event'
      let phone = localStorage.getItem('dmi_phone');
      let campign_number = 'Not Available'
      let source = 'DMI-Customer-Portal';
      this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
      const body1 = {
        phone: phone,
        eventname: eventName,
        campign_number: campign_number,
        source: source,
        partner: this.channelPartnerName

      }
      this.service.getEventName(body1).subscribe(res => {
      })
    }
    const body = {
      mobile: this.mobile,
      pageno: this.pageno,
      no_of_records_per_page: this.records,
      notification_type: this.notification_type_alert
    }
    this.service.getNotificationList(body).subscribe(res => {
      this.alertArr = res;
    });
  }

  getTabchange(event) {
    this.tabId = event.nextId;
    if (this.tabId == "Notification") {
      this.getNotification();
    }
    else if (this.tabId == "Alert") {
      this.getAlert();
    }
  }

  getTrendingList() {
    const body = {
      pageno: this.pageno
    }
    this.service.getTrendingDataList(body).subscribe(res => {
      this.trendingArr = res;
    });
  }
  // gotoblogpage(){
  //   this.router.navigate(['blog'])
  // }

  loanDetails(id) {
    const body = {
      blog_id: id
    }
    this.service.getBlogDetails(body).subscribe(res => {
      this.blogDetailsData = res;
      localStorage.setItem('sliderBlogDetail', JSON.stringify(this.blogDetailsData.data))
      this.router.navigate(['blogdetails'])
    });
  }

  ngOnDestroy() {
    clearTimeout(this.userActivity);
  }

}
