import { Component, OnInit, Inject } from '@angular/core';
import { DataService } from '../data.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ShareDataService } from '../share-data.service';
import { AuthService } from '../auth/auth.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthenticationService } from '../_services';
import { DOCUMENT } from '@angular/common';
import { first } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable'
import 'rxjs/add/observable/timer'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/take'
import { Subject } from 'rxjs';
import { Pipe, PipeTransform } from '@angular/core';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
// import { timingSafeEqual } from 'crypto';

import { decryptString, encryptString } from 'src/Utils/encryption';
import { BaseHttpResponse } from 'src/modals/http-request-modals';

@Component({
  selector: 'app-english-page',
  templateUrl: './english-page.component.html',
  styleUrls: ['./english-page.component.scss']
})
export class EnglishPageComponent implements OnInit {
  secretKey = environment.secretKey
  eventTrigger: Subject<void> = new Subject<void>();
  loginForm: FormGroup;
  isLoading: boolean;
  isOTP: boolean;
  isPassword: boolean;
  isHavingPassword: boolean;
  notOTP: boolean;
  isMobileField: boolean;
  loginSection: boolean;
  resetPasswordSec: boolean;
  isVerifyOTP: boolean;
  getauthdata: any;
  mobnumPattern = "[789][0-9]{9}";
  formName: any = '';
  formEmail: any = '';
  formMobile: any = '';
  formMessage: any = '';
  mobileRequired: any = '';
  messageRequired: any = '';
  user: any;
  userName
  mobileNumber: any;
  returnUrl: string;
  phoneNotCurrect: any = '';
  userBasicInfo: any;
  bankAccountDetails: any;
  loanDetail: any;
  loanOffer: any;
  lastLogin: any;
  loginBtn: any = 'LOGIN';
  showLogin: boolean = true;
  isLogging: boolean = false;
  isSendOTP: boolean = false;
  isVerifyOTPBtn: boolean = false;
  passwordNotMatch: boolean = false;
  passNotCurrect: any;
  otpNotCurrect: any = "";
  registeredNo: any;
  response: any;
  tokenvalue: any;
  regNoErr: any;
  errorMsg: any;
  passwordError: any;
  countDown;
  counter = 900;
  tick = 1000;
  resendCouter = 0;
  otpExpiredErr: any;
  newPage: any = 1;
  config = { itemsPerPage: 5, currentPage: this.newPage, totalItems: 0 };
  resendCountDown = 900;
  countDownSend;
  sendOTPCount = 120;

  showAlert: boolean = false;
  showPassword: boolean = true;
  sendOTPCounter: boolean = false;
  encryptKey: any;
  param: any;
  constructor(
    private fb: FormBuilder,
    private formBuilder: FormBuilder,
    private data: DataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private service: ShareDataService,
    private authService: AuthService,
    private deviceService: DeviceDetectorService,
    private authenticationService: AuthenticationService, @Inject(DOCUMENT) private document: Document) {
  }
  billingAddress: any;
  email: any;
  loanId: any;
  acHolderName;
  userName1
  ngOnInit() {
    // this.returnUrl = this.router.snapshot.queryParams['returnUrl'] ;
    // console.log(this.returnUrl,"ftryfglhkjl")

    // sessionStorage.setItem('geturl',(this.returnUrl));

    localStorage.clear();
    localStorage.removeItem('passwordErr');


    this.loginSection = true;
    this.notOTP = true;
    this.isMobileField = true;
    this.isVerifyOTP = false;

    this.loginForm = this.formBuilder.group({
      phone: ['', Validators.pattern(this.mobnumPattern)],
      password: [''],
      new_password: [''],
      repeat_password: [''],
      otp: ['']
    });

    // this.authenticationService.logout();
    // this.returnUrl = this.router.snapshot.queryParams['returnUrl'] || '/';


    // this
    //   .data
    // .getAuth().subscribe(
    //   data => {
    //     this.getauthdata = data
    //     localStorage.setItem('dmi_token', this.getauthdata.data.access_token)
    //     localStorage.setItem('dmi_instance_url', this.getauthdata.data.instance_url)
    //     localStorage.setItem('sesssionRating', 'authenticated')
    //   },
    //   err => { this.getauthdata = [] }
    // );
  }

  get formData() { return this.loginForm.controls; }

  sendOTP() {
    this.isLogging = true;
    this.isVerifyOTPBtn = false;
    this.sendOTPCount = 120;

    this.countDown = Observable.timer(0, this.tick)
      .take(this.counter)
      .map(() => --this.counter);

    if (this.formData.phone.value.length < 10) { this.phoneNotCurrect = "Please enter a valid 10 digit mobile number."; }
    else {
      this.isSendOTP = true;
      const body = {
        mobile: this.formData.phone.value
      }

      this.data.getMobileNo(body).subscribe(res => {
        this.response = res;
        this.isLogging = true;

        if (this.response.message.error == 'Please try with registered mobile number.') {
          this.isSendOTP = false;
          this.regNoErr = "Please try with registered mobile number."
          let eventName = 'Authentication failed from unregistered "mobile number" ' + this.formData.phone.value;
          let phone = this.formData.phone.value;
          let campign_number = 'Not Available'
          let source = 'DMI-Customer-Portal';
          const body = {
            phone: phone,
            eventname: eventName,
            campign_number: campign_number,
            source: source,
            partner: "null"

          }
          this.data.getEventName(body).subscribe(res => {
          })
        }
        else {
          let eventName = 'Send OTP'
          let phone = this.formData.phone.value;
          let campign_number = 'Not Available'
          let source = 'DMI-Customer-Portal'
          const body1 = {
            phone: phone,
            eventname: eventName,
            campign_number: campign_number,
            source: source,
            partner: "null"

          }
          this.data.getEventName(body1).subscribe(res => {
          })
          if (this.formData.phone.value.length < 10 || this.formData.phone.value == '' || this.formData.phone.value == 'undefined' || this.formData.phone.value == 'null') {
            this.isSendOTP = false;
            this.phoneNotCurrect = "Please enter a valid 10 digit mobile number.";
            return;
          }
          this.isSendOTP = true;
          this.isLogging = true;
          this.sendOTPCounter = false;
          this.showPassword = false;
          this.isVerifyOTPBtn = false;
          this.counter = 120;
          this.phoneNotCurrect = '';
          this.user = res
          localStorage.setItem('dmi_phone', this.formData.phone.value)
          this.isMobileField = false;
          this.isOTP = true;
          this.notOTP = false;
          this.isVerifyOTP = true;
          this.isVerifyOTPBtn = true;
          this.isHavingPassword = false;
          const body = {
            phone: this.formData.phone.value
          }

          this.isSendOTP = false;
        }
      },
        err => {
          this.isLogging = true;
          this.isSendOTP = false;

          this.message = JSON.parse(decryptString(err.error.data, this.secretKey));

          if (err.status === 404) {
            if (this.message.message.error == 'You cant send more than 3 OTP within an hour.') {
              this.regNoErr = '';
              this.isLogging = true;
              this.phoneNotCurrect = this.message.message.error;
            }
          }
          this.regNoErr = '';
          this.phoneNotCurrect = this.message.message.error;
          this.user = [];
        });
    }
  }

  verifyOTP() {
    // event Tracking
    let eventName = 'verify OTP'
    let phone = localStorage.getItem('dmi_phone');
    let campign_number = 'Not Available'
    let source = 'DMI-Customer-Portal'
    const body1 = {
      phone: phone,
      eventname: eventName,
      campign_number: campign_number,
      source: source,
      partner: "null"

    }
    this.data.getEventName(body1).subscribe(res => {
    })

    if (this.formData.otp.value == '' || this.formData.otp.value == 'undefined' || this.formData.otp.value == 'null') {
      this.otpNotCurrect = "Please enter valid OTP";
      return;
    }
    this.isVerifyOTPBtn = true;

    localStorage.setItem('OTP_Pass', this.formData.otp.value);
    const body = {
      mobile: this.formData.phone.value,
      otp: this.formData.otp.value
    }
    this.authenticationService.verifyOtp(this.formData.phone.value, this.formData.otp.value).pipe(first()).subscribe(
      data => {
        this.user = data;
        if(this.formData.phone.value==this.user.data.mobile && this.user.message=='OTP has been verified'){
          this.mobileNumber = localStorage.getItem('dmi_phone');
         
          const body = {
            Mobile: this.formData.phone.value
          }
          this.data.getSecretKey(body).subscribe((res: any) => {
            let key = res.data
            this.encryptKey = window.atob(key)
  
          })
          this.loginSection = false;
          this.resetPasswordSec = true;
          this.isVerifyOTPBtn = false;
        }
        else{
        
          this.otpNotCurrect='Technical Error'
        }
        
      },
      err => {
        this.user = [];
        this.otpExpiredErr = err;
        this.message = JSON.parse(decryptString(err.error.data, this.secretKey));
        let otpexpire = this.message.message.error;

        this.otpNotCurrect = otpexpire;
      }
    );
  }

  goToDashboard() {
    let eventName = 'Customer Login'
    let phone = this.formData.phone.value;
    let campign_number = 'Not Available';
    let source = 'DMI-Customer-Portal';
    const body1 = {
      phone: phone,
      eventname: eventName,
      campign_number: campign_number,
      source: source,
      partner: "null"

    }
    this.router.navigate(['dashboard']);
    this.data.getEventName(body1).subscribe(res => {

      this.router.navigate(['dashboard']);
      // this.route.navigate([this.returnUrl]);
    })
  }

  proceedLogin() {
    if (this.loginForm.value.new_password == this.loginForm.value.repeat_password) {
      let eventName = 'Customer Login'
      let phone = this.formData.phone.value;
      let campign_number = 'Not Available'
      let source = 'DMI-Customer-Portal'
      const body1 = {
        phone: phone,
        eventname: eventName,
        campign_number: campign_number,
        source: source,
        partner: "null"

      }
      this.data.getEventName(body1).subscribe(res => { })
      const body = {
        Mobile: this.formData.phone.value, password: this.formData.new_password.value, key: this.encryptKey,
        sign: sessionStorage.getItem('signature')

      }

      this.data.reSetPassword(body).subscribe((data: any) => {
        // this.data.setUserPassword(localStorage.getItem('dmi_phone'), this.formData.new_password.value)
        //   .subscribe(
        // data => {
        this.user = data;
        let eventName = 'Customer Set Password';
        let phone = localStorage.getItem('dmi_phone');
        let campign_number = 'Not Available';
        let source = 'DMI-Customer-Portal';
        const body1 = {
          phone: phone,
          eventname: eventName,
          campign_number: campign_number,
          source: source,
          partner: "null"

        }
        this.data.getEventName(body1).subscribe(res => {
        });

        this.router.navigate(['dashboard']);

      },

        err => {
          if (err.status === 404) {
            this.passwordNotMatch = true;
            this.message = JSON.parse(decryptString(err.error.data, this.secretKey));
            this.passwordError = this.message.message.error;
          }
          this.user = [];
        }
      );
    }
    else { this.passwordNotMatch = true; this.passwordError = 'Old and New Password should Match.' }

  }

  otpLogin() {
    this.isOTP = false;
    this.notOTP = true;
    this.isMobileField = true;
    this.isPassword = false;
    this.isHavingPassword = false;
  }

  passwordLogin() {
    if (this.showPassword == true) {
      this.notOTP = false;
      this.isOTP = false;
      this.isMobileField = true;
      this.isHavingPassword = true;
      this.isPassword = true;
      this.phoneNotCurrect = "";
      this.sendOTPCounter = false;
      this.isLogging = true;
    }
  }

  resendOTP() {
    this.resendCouter = this.resendCouter + 1;
    if (this.resendCouter > 2) {
      this.showPassword = true;
      this.sendOTPCounter = true;
      this.isOTP = false;
      this.isSendOTP = false;
      this.isMobileField = true;
      this.isVerifyOTP = false;
      this.notOTP = true;
      this.counter = 120;
      this.resendCountDown = 900;
      this.countDownSend = Observable.timer(0, this.tick)
        .take(this.resendCountDown)
        .map(() => --this.resendCountDown)
    }
    else {
      this.isLogging = true;
      this.isSendOTP = false;
      this.counter = 120;
      this.countDown = Observable.timer(0, this.tick)
        .take(this.counter)
        .map(() => --this.counter)

      if (this.formData.phone.value.length < 10) { this.phoneNotCurrect = "Please enter a valid 10 digit mobile number."; }
      else {

        const body = {
          mobile: this.formData.phone.value
        }
        this.data.getMobileNo(body).subscribe(res => {
          this.response = res;
          this.isLogging = true;

          if (this.response.message.error == 'Please try with registered mobile number.') {
            this.regNoErr = "Please try with registered mobile number."
            let eventName = 'Authentication failed from unregistered "mobile number - Resend OTP" ' + this.formData.phone.value;
            let phone = this.formData.phone.value;
            let campign_number = 'Not Available'
            let source = 'DMI-Customer-Portal';
            const body1 = {
              phone: phone,
              eventname: eventName,
              campign_number: campign_number,
              source: source,
              partner: "null"

            }
            this.data.getEventName(body1).subscribe(res => {
            })
          }
          else {
            let eventName = 'Send OTP'
            let phone = this.formData.phone.value;
            let campign_number = 'Not Available'
            let source = 'DMI-Customer-Portal'
            const body1 = {
              phone: phone,
              eventname: eventName,
              campign_number: campign_number,
              source: source,
              partner: "null"

            }
            this.data.getEventName(body1).subscribe(res => {
            })
            if (this.formData.phone.value.length < 10 || this.formData.phone.value == '' || this.formData.phone.value == 'undefined' || this.formData.phone.value == 'null') {
              this.isSendOTP = false;
              this.phoneNotCurrect = "Please enter a valid 10 digit mobile number.";
              return;
            }
            this.isSendOTP = true;
            this.user = res;
            localStorage.setItem('dmi_phone', this.formData.phone.value);
            this.isMobileField = false;
            this.isOTP = true;
            this.notOTP = false;
            this.isVerifyOTP = true;
            this.isHavingPassword = false;
            this.isSendOTP = false;
            this.isVerifyOTPBtn = true;
            const body = {
              phone: this.formData.phone.value
            }

            this.isSendOTP = false;
          }
        },
          err => {
            if (err.status === 404) {
              if (err.error.message.error == 'You cant send more than 3 OTP within an hour.') {
                this.regNoErr = '';
                this.phoneNotCurrect = err.error.message.error;
              }
            }
            this.user = [];
          });
      }
    }
  }
  mailingcity
  MailingCountry
  MailingStreet
  MailingState
  MailingPostalCode
  Email
  url
  name: any;
  loanNameValue: any;
  amount: any;
  totalpayableamount: any;
  loanData: any;
  userBasicInfo1;
  message: any;
  getLogin() {
    this.isLogging = true;
    this.showLogin = false;
    this.loginBtn = 'Please Wait...'
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    let eventName = 'Login Attempt'
    let phone = this.formData.phone.value
    let campign_number = 'Not Available'
    let source = 'DMI-Customer-Portal'
    const body1 = {
      phone: phone,
      eventname: eventName,
      campign_number: campign_number,
      source: source,
      partner: "null"

    }
    this.data.getEventName(body1).subscribe(res => { });

    if (this.formData.phone.value == '' || this.formData.phone.value == 'undefined' || this.formData.phone.value == 'null') {
      this.phoneNotCurrect = "Please enter a valid 10 digit mobile number.";
      return;
    } else if (this.formData.password.value == '' || this.formData.password.value == 'undefined' || this.formData.password.value == 'null') {
      this.passNotCurrect = "Please enter valid password";
      return;
    }
    localStorage.setItem('dmi_phone', this.formData.phone.value)
    this.authenticationService.loginWithPassword(this.formData.phone.value, this.formData.password.value).pipe(first()).subscribe(res => {
      this.lastLogin = res
      this.isLogging = false;
      localStorage.setItem('userId', this.lastLogin.data.id);
      // this.service.setUserId(this.lastLogin.data.id);
      localStorage.setItem('token', this.lastLogin.data.token);

      if (res) {
        let eventName = 'Customer Login Successfully'
        let phone = this.formData.phone.value
        let campign_number = 'Not Available'
        let source = 'DMI-Customer-Portal'
        const body1 = {
          phone: phone,
          eventname: eventName,
          campign_number: campign_number,
          source: source,
          partner: "null"

        }
        this.data.getEventName(body1).subscribe(res => { });
      }

      this.router.navigate(['dashboard']);



    },
      error => {
        let eventName = '"Wrong login credential" with ' + this.formData.phone.value;
        let phone = this.formData.phone.value
        let campign_number = 'Not Available'
        let source = 'DMI-Customer-Portal'
        const body1 = {
          phone: phone,
          eventname: eventName,
          campign_number: campign_number,
          source: source,
          partner: "null"

        }
        this.data.getEventName(body1);
        this.isLogging = true;
        this.showLogin = true;
        this.loginBtn = 'LOGIN';
        this.message = JSON.parse(decryptString(error.error.data, this.secretKey))
        alert(this.message.message.error)
        
      });
  }

  removeError(event) {
    this.phoneNotCurrect = "";
    this.otpNotCurrect = "";
    this.regNoErr = '';
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  removeErrorPassword(event) {
    this.passwordError = '';
    this.passNotCurrect = "";
  }
  enlishValue:boolean=false;
  notenlishValue:boolean=true;
  langaugechange(langvalue:any){
    
    console.log(langvalue.target.value,'23456789')
   
    const body = {
        user_id: null,
        remark_1: langvalue.target.value 
    }
   
    this.data.getselectlanguage(body).subscribe(res=>{
console.log(res)
const langCodeList = "Hindi,Marathi,Gujarati,Bengali,Tamil,Telugu,Kannada,Malayalam,Oriya";
const strLang = langvalue.target.value;
    var newUrl = '';
    var pathName = window.location.pathname;
    var strSegment = window.location.pathname.split("/");
    var segmentLang = strSegment[1];
    var origHost = window.location.host;
    if (langCodeList.toString().toLowerCase().indexOf(segmentLang.toString().toLowerCase()) > -1) pathName = pathName.replace("/" + segmentLang,
        "");
    if (strLang.toString().toLowerCase() == "english") {
        newUrl = window.location.protocol + "//" + origHost + pathName;
    } else {
        if (pathName == "/") pathName = "";
        newUrl = window.location.protocol + "//" + origHost + "/" + strLang.toString().toLowerCase() +
            "/" + pathName;
    }

    console.log(newUrl,'URL')
    if(langvalue.target.value==''||langvalue.target.value=='select'){
      this.router.navigate(['']);
    }
    else if(langvalue.target.value=='English'||langvalue.target.value==='English'){
      // this.router.navigate(['english']);
      this.router.navigate(['english', { relativeTo: this.activatedRoute }])
    }

    this.activatedRoute.params.subscribe(res => console.log(res))
   
      this.document.location.href = newUrl;
      // window.location.replace(newUrl)
    
    // this.document.location.href = newUrl;
    // this.document.location.href = newUrl;
    // window.location.replace(newUrl)
    // window.location.assign(newUrl); 
   

});
  }

  onSave() {
    if (this.formMobile == '' && this.formMessage == '') { this.showAlert = true; this.mobileRequired = 'Please enter mobile number.'; this.messageRequired = 'Please enter your message.' }
    else if (this.formMobile != '' && this.formMessage != '') {

      const body = {
        name: this.formName,
        mobile: this.formMobile,
        email: this.formEmail,
        comment: this.formMessage
      }


      this.data.troubleLogin(body).subscribe(res => {
        this.modalService.dismissAll();
        if (res) {
          Swal.fire({
            title: 'Submitted',
            text: 'Your request has been received successfully.',
            type: "success",
            confirmButtonText: 'OK'
          });
        }
      }, error => {
        if (error) {
          Swal.fire({
            title: 'Alert',
            text: 'Problem in trouble login',
            type: "warning",
            confirmButtonText: 'OK'
          })
        }
      });
    }
  }
}

@Pipe({
  name: 'formatTime'
})
export class FormatTimePipe1 implements PipeTransform {

  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return ('00' + minutes).slice(-2) + ':' + ('00' + Math.floor(value - minutes * 60)).slice(-2);
  }
}
