import { Component, OnInit, HostListener } from '@angular/core';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
import { ShareDataService } from '../share-data.service'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentModalComponent } from '../modal/payment-modal/payment-modal.component';

@Component({
  selector: 'app-payment-summary',
  templateUrl: './payment-summary.component.html',
  styleUrls: ['./payment-summary.component.scss']
})
export class PaymentSummaryComponent implements OnInit {
  userActivity;
  userInactive: Subject<any> = new Subject();
  speceficLoanDetail: any;
  loanName: any;
  paymentSchedule: any;
  userBasicInfo: any;
  scheduledata: any;
  loanNumber: any;
  paymentStatus: any;
  statusData: string = 'Open';
  origin: string = 'facebook';
  priority: string = 'Medium';
  division: string = 'Retail';
  ticketType: string = 'Complaint';
  ticketSubType: string = 'EMI Related Complaint';
  tktResData: any;
  loanId: any;
  contact_info: any;
  subject: string = 'Statement of account generation';
  remarks: string = '';
  userPhone: any;
  imagePath: string = '';
  opp_id: any;
  opp_name: any;
  response: any = [];
  responseofList: any;
  code: any;
  pdf: boolean = false;
  btnstatus: any = 'Download  Statement';
  userName1: any;
  loanDetail: any = '';
  downloadStatementDisable: boolean = false
  userId: any;
  constructor(private data: DataService,
    private router: Router,
    private service: ShareDataService,
    private http: HttpClient,
    private modalService: NgbModal) {
    this.setTimeout();
    this.userInactive.subscribe(() => {
      localStorage.clear();
      this.router.navigate(['customerportal&currentuser&sessiontimeout'])
    });
  }

  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 900000);
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
  lead_source: any;
  ngOnInit() {
    this.lead_source = sessionStorage.getItem('company_Name')

    this.userId = sessionStorage.getItem('userID');
    this.userName1 = localStorage.getItem('user_basic_name')
    this.opp_id = this.service.getLoanId();
    this.opp_name = this.service.getLoanName();
    // console.log(this.opp_id,this.opp_name,'fghjkl;')

    // this.opp_id = '0062v00001ABRFjAAP';
    // this.opp_name = 'DMI0001681646';
    this.userBasicInfo = JSON.parse(localStorage.getItem('user_basic_info')) || [];
    this.userPhone = this.userBasicInfo.data[0].MobilePhone;
    this.speceficLoanDetail = JSON.parse(localStorage.getItem('specific_loan_detail')) || [];
    this.loanName = this.speceficLoanDetail[0].name;
    this.loanId = this.speceficLoanDetail[0].Id;
    // Start event tracking
    let eventName = 'Payment Summary Page'
    let phone = localStorage.getItem('dmi_phone')
    let campign_number = this.loanName;
    let source = 'DMI-Customer-Portal'
    this.contact_info = 'Existing Contact'
    this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
    const body1 = {
      phone: phone,
      eventname: eventName,
      campign_number: campign_number,
      source: source,
      partner: this.speceficLoanDetail[0].Pulled_Lead_Source_Name__c

    }
    this.data.getEventName(body1).subscribe(res => {
    })
    // end

    const body = {
      loanname: this.loanName,
      account_id: sessionStorage.getItem('accountId'),
      user_id: this.userId
    }

    this
      .data
      .getPaymentSchedule(body).subscribe(
        data => {
          this.paymentSchedule = data
          this.scheduledata = this.paymentSchedule.data;
          this.openPaymentModal();
          // let givenDate = this.scheduledata[0].Date__c
          // let current_date = new Date();
          // givenDate = new Date(givenDate)
          // if(givenDate > current_date){
          //   alert('given date is grater than current date');
          //   this.paymentStatus = 'undefined';
          //   this.isUndefined = false;
          // }else{
          //   alert('given date is less than current date');
          // }

        },
        err => { this.paymentSchedule = [] }
      );
  }

  downloadStatement() {

    this.downloadStatementDisable = true
    // Start event tracking
    let eventName = 'Download Statement'
    let phone = localStorage.getItem('dmi_phone')
    let campign_number = this.loanName;
    let source = 'DMI-Customer-Portal'
    this.btnstatus = 'Please Wait...';
    this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));

    const body1 = {
      phone: phone,
      eventname: eventName,
      campign_number: campign_number,
      source: source,
      partner: this.speceficLoanDetail[0].Pulled_Lead_Source_Name__c

    }

    this.data.getEventName(body1).subscribe(res => {
    });
    const body = {
      user_id: this.userId,
      OppID: this.loanId,
      opName: this.loanName
    }
    // console.log(body)

    this.data.downloadStatement(body).subscribe(res => {

      this.downloadStatementDisable = false
      this.responseofList = res;
      // console.log(this.responseofList);

      this.code = this.responseofList.data.error;
      this.response = this.responseofList.data.presign_url;
      // console.log(this.response);

      if (res) { this.btnstatus = 'Download  Statement' };

      // if(this.responseofList.data.result.pdf_url)
      // {

      // }
      if (this.response != '') {
        window.open(this.response);
      }


      //  if(this.code!='200'){
      //   this.data.createTicketReq(this.statusData,this.origin,this.ticketType,this.ticketSubType,this.priority,this.loanId,this.contact_info,this.subject,this.remarks,this.division,localStorage.getItem('dmi_token'),localStorage.getItem('dmi_instance_url'),this.userPhone,this.imagePath).subscribe(res => {
      //     this.tktResData = res;
      //     alert('Your request has been received successfully.' +'\n'+ 'Your Ticket Id is:' +'  '+ this.tktResData.data.id)
      //   })
      //  }
    }, (error) => {
      this.downloadStatementDisable = true;
      this.downloadStatementDisable = false
      // console.log('fghjkl')
    });



    // end



    // window.open(
    //   'https://dmi-qw-cp-soa.s3.ap-south-1.amazonaws.com/SoA/DMI0002711010/SoA_DMI0002711010_101615072019.pdf',
    //   '_blank'
    // );
  }

  openPaymentModal() {
    let modalObj: NgbModalOptions = {
      backdrop: 'static',
      centered: true,
      windowClass: 'otpCustomModal paymentPopup',
      keyboard: false,
    }
    const modal = this.modalService.open(PaymentModalComponent, modalObj);
  }

  ngOnDestroy() {
    this.modalService.dismissAll();
    clearTimeout(this.userActivity);
  }

}
