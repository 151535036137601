import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { ToastrService } from 'ngx-toastr';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ShareDataService } from 'src/app/share-data.service';
import { formatDate } from '@angular/common';
import { FormBuilder, FormGroup, Validators, FormControl, } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { decryptString } from '../../../Utils/encryption';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  @ViewChild('closeFeedback') closeFeedback: ElementRef;
  SpeceficLoanDetail: any;
  loanDetails: any;
  secretKey = environment.secretKey;
  PasskeyForm: FormGroup;
  submitted = false;
  mobile: any;
  pageno: any = 1;
  records: any = 2;
  rating: any = '';
  reviews: any = '';
  session: any;
  showDial: boolean = false;
  rated: boolean = false;
  response: any;
  campign_number: any;
  eventName: any;
  userBasicInfo: any;
  clickedStar: boolean = false;
  Preclosureshow: any;
  showGood: boolean = false;
  showAvg: boolean = false;
  showPoor: boolean = false;
  getSession: any = '';
  sessionAlert: boolean = false;
  sessionExist: boolean = false;
  checkedFeedLog: boolean = true;
  feedBackDoneTost:boolean = false;
  feedBackDone:any;
  feedbackText:any;
  proceedText:string = 'Submit'
  chooseSatisfaction:boolean = true;
  tabName:any;
  customer_id: any = "";
  name: any = "";
  user_email: any = "";
  user_phone: any = "";
  app_id: any = "";
  notify_channel = "none";
  is_generate_short_url = false;
  userBasicInfoRating: any = '';
  url: SafeResourceUrl;
  feedbackResp: any = '';
  feedbackResponse: any = [];
  userName1: any
  loanDetail: any = '';
  channelName: any = '';
  channelPartnerName: any = '';
  DateValue: any;
  DateValue2: any;
  userId: any;
  mobileQuery: MediaQueryList;
  showFeedback: boolean = false;
  fillerNav = Array.from({ length: 50 }, (_, i) => `Nav Item ${i + 1}`);

  fillerContent = Array.from({ length: 50 }, () =>
    `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
       labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
       laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
       voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
       cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`);

  private _mobileQueryListener: () => void;



  constructor(private data: DataService, private toastr: ToastrService,
    private router: Router, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, public sanitizer: DomSanitizer,
    private service: ShareDataService, private formBuilder: FormBuilder) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  shouldRun = [/(^|\.)plnkr\.co$/, /(^|\.)stackblitz\.io$/].some(h => h.test(window.location.host));

  ngOnInit() {
    let currentDate = new Date();
    // this.DateValue = formatDate(currentDate, 'dd', 'en-US');
    // if (this.DateValue >= '03' && this.DateValue <= '08') {
    //   console.log('true')
    //   this.Preclosureshow = false;
    // }
    // else {
    //   console.log('false')
    //   this.Preclosureshow = true;
    // }


    this.url = this.sanitizer.bypassSecurityTrustResourceUrl('https://dev.vistaconnect.com/dmi-loader/loader1.html')
    this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
    this.userName1 = localStorage.getItem('user_basic_name')
    this.userBasicInfo = JSON.parse(localStorage.getItem('user_basic_info')) || [];
    this.mobile = localStorage.getItem('dmi_phone');
    this.userId = sessionStorage.getItem('userID');
    this.session = localStorage.getItem('token');
    this.SpeceficLoanDetail = JSON.parse(localStorage.getItem('specific_loan_detail'));
    const body1 = {
      phone: localStorage.getItem('dmi_phone')
    }
    this.data.getChannelPartner(body1).subscribe(res => {
      this.channelName = res;
      this.channelPartnerName = this.channelName.data;
    });
    if (localStorage.getItem('specefic_loan_id')) {
      const body = {
        user_id: this.userId,
        loanid: localStorage.getItem('specefic_loan_id')
      }
      this
        .data
        .getSpecificLoan(body).subscribe(
          data => {
            this.loanDetails = data
            this.SpeceficLoanDetail = this.loanDetails.data
            this.campign_number = this.SpeceficLoanDetail[0].name;
          },
          err => { this.SpeceficLoanDetail = [] }
        );

    } else {
      this.SpeceficLoanDetail = JSON.parse(localStorage.getItem('specific_loan_detail'));
    }
    this.getinitkey();
    console.log('true_sidenav')

    this.Preclosureshow = localStorage.getItem('Preclosureshow');
    // console.log(this.Preclosureshow,'this.Preclosureshow ')

  }
  getinitkey() {
    this.PasskeyForm = this.formBuilder.group(
      {
        Passpin: new FormControl('', [Validators.required]),
      });

  }

  get f() {
    return this.PasskeyForm.controls;
  }

  logout() {
    this.sessionExist = false;
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/']);
  }

  checkRatingLogout() {
    this.getSession = localStorage.getItem('sesssionRating');
    if (this.getSession != null) {
      this.sessionExist = true;
    } else if (this.getSession == null) {
      this.logout();
      this.sessionExist = false;
    }
  }

  closeRate() {


    if (this.channelName != '') {
      let phone = localStorage.getItem('dmi_phone')
      this.campign_number = 'null';
      this.eventName = `Made an action to ${this.tabName} sidebar tab`
      let source = 'DMI-Customer-Portal';
      const body1 = {
        phone: phone,
        eventname: this.eventName,
        campign_number: this.campign_number,
        source: source,
        partner: this.channelPartnerName

      }
      this.data.getEventName(body1).subscribe(res => {
      })
    }
    if (this.tabName === 'logout') {
      this.logout();
      this.sessionExist = false;
    }
  }

  getfeedbackLogout() {
    let phone = localStorage.getItem('dmi_phone')
    let campign_number = this.campign_number;
    this.eventName = 'Made an action to "Logout" from Side Menu for Loan Name' + ': ' + campign_number;
    let source = 'DMI-Customer-Portal'
    this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
    if (this.channelName != '') {
      const body1 = {
        phone: phone,
        eventname: this.eventName,
        campign_number: this.campign_number,
        source: source,
        partner: this.SpeceficLoanDetail[0].Pulled_Lead_Source_Name__c

      }

      this.data.getEventName(body1).subscribe(res => {
      })
    }

    if (this.reviews == '') {
      this.reviews = '-';
    }
    if (this.clickedStar == true) {
      localStorage.removeItem('sesssionRating');
      const body = {
        phone: this.mobile,
        rating: this.rating,
        description: this.reviews

      }
      this.data.getFeedback(body).subscribe(res => {
        if (res) {
        }
        this.rating = '';
        this.showDial = false;
        this.showPoor = false; this.showAvg = false; this.showGood = false;
        this.logout();
      });
    }
    else {
      this.logout();
    }
  }

  onRate(newValue: number) {
    if (newValue >= 1) { this.checkedFeedLog = false; }
    this.clickedStar = true;
    this.showPoor = false; this.showAvg = false; this.showGood = false;
    this.rating = newValue;
    if (newValue) { this.showDial = true; }
    if (newValue <= 7) { this.showPoor = true; }
    else if (newValue < 10 && newValue > 7) { this.showAvg = true; }
    else if (newValue > 9) { this.showGood = true; }
  }

 
  

  npsModel(event:any){

    this.tabName = event
    this.url = '';
    if (this.channelName != '') {
      let phone = localStorage.getItem('dmi_phone');
      this.campign_number = 'null';
      this.eventName = `Click on the ${this.tabName} tab`;  // Ensure there's a space after 'the'
      let source = 'DMI-Customer-Portal';
    
      const body1 = {
        phone: phone,
        eventname: this.eventName,
        campign_number: this.campign_number,
        source: source,
        partner: this.channelPartnerName
      };
    
      this.data.getEventName(body1).subscribe(res => {
       
      });

      const checkQDSBody = {
        phone_no: localStorage.getItem('dmi_phone')
      }
  
      this.data.checkQdsFeedback(checkQDSBody).subscribe((res)=>{
        if(res.status==200){
          if(res.data.is_eligible === true){
            document.getElementById('npsModel').click(); 
            this.chooseSatisfaction =true;
            this.feedbackText = '';
            
            this.feedBackDone = '';
          }
          else{
            if (this.tabName === 'logout') {
              this.logout();
              this.sessionExist = false;
            }
            else{
              this.feedBackDoneTost = true;
              this.feedBackDone =  res.message;
              setTimeout(()=>{
                this.feedBackDoneTost = false;
              },2000)
            }
          }
         
        }
        else{
         console.log('')
        }
      })
    }
  
    document.getElementById('paymentlink').click(); 
    this.chooseSatisfaction =true;
    this.feedbackText = '';
    this.feedBackDoneTost = false;
    this.feedBackDone = '';
  }

  userNotSatisfied(){
    this.chooseSatisfaction = !this.chooseSatisfaction;
    if (this.channelName != '') {
      let phone = localStorage.getItem('dmi_phone');
      this.campign_number = 'null';
      this.eventName = `Tab name ${this.tabName} sidebar : Not satisfied with your service`;  // Ensure there's a space after 'the'
      let source = 'DMI-Customer-Portal';
      const body1 = {
        phone: phone,
        eventname: this.eventName,
        campign_number: this.campign_number,
        source: source,
        partner: this.channelPartnerName
      };
    
      this.data.getEventName(body1).subscribe(res => {
        // handle response if necessary
      });
    }
  }

 

  getQdsFeedback(){

    const body = {
      contact_no: localStorage.getItem('dmi_phone'),
      voc: this.feedbackText
  }

    this.proceedText = 'Loading...'
    this.data.storeQdsFeedback(body).subscribe(res=>{
      if(res.status==200){
        this.proceedText = 'Proceed'
        this.feedBackDoneTost = true;
        this.feedBackDone = res.message
        if (this.channelName != '') {
          let phone = localStorage.getItem('dmi_phone');
          this.campign_number = 'null';
          this.eventName = `Tab name sidebar ${this.tabName} : Custom feedback stored`;  // Ensure there's a space after 'the'
          let source = 'DMI-Customer-Portal';
          const body1 = {
            phone: phone,
            eventname: this.eventName,
            campign_number: this.campign_number,
            source: source,
            partner: this.channelPartnerName
          };
        
          this.data.getEventName(body1).subscribe(res => {
            // handle response if necessary
          });
        }
      }
      else{
        this.proceedText = 'Loading...'
        this.feedBackDoneTost = false;
      }

    },(error)=>{
      this.feedBackDoneTost = false;
      this.proceedText = 'Proceed'
      if (this.channelName != '') {
        let phone = localStorage.getItem('dmi_phone');
        this.campign_number = 'null';
        this.eventName = `Tab name sidebar ${this.tabName} : Custom feedback stored failed`;  // Ensure there's a space after 'the'
        let source = 'DMI-Customer-Portal';
        const body1 = {
          phone: phone,
          eventname: this.eventName,
          campign_number: this.campign_number,
          source: source,
          partner: this.channelPartnerName
        };
      
        this.data.getEventName(body1).subscribe(res => {
          // handle response if necessary
        });
      }
    })
  }


  checkRatingStats() {
    this.showFeedback = true;
    this.userBasicInfo = '';
    this.customer_id = '';
    this.userBasicInfo = JSON.parse(localStorage.getItem('user_basic_info'));
    this.customer_id = this.userBasicInfo.data[0].Id;
    this.name = this.userBasicInfo.data[0].Name;
    this.user_email = this.userBasicInfo.data[0].Email;
    if (this.userBasicInfo.data[0].Phone == null) {
      this.user_phone = this.userBasicInfo.data[0].MobilePhone;
    } else { this.user_phone = this.userBasicInfo.data[0].Phone; }
    const body1 = {
      phone: localStorage.getItem('dmi_phone')
    }
    this.data.getChannelPartner(body1).subscribe(res => {
      this.channelName = res;
      this.channelPartnerName = this.channelName.data;
      if (this.channelName != '') {
        this.app_id = this.service.getAppIdforFeedback(this.channelPartnerName);
        if (this.app_id == '') { this.app_id = 'juz6_test'; }

        this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
        const body = {
          phone: this.user_phone,
          loan_id: this.loanDetail[0].name,
          loan_amount: this.loanDetail[0].Original_Face__c,
          loan_disbursement_date: this.loanDetail[0].Disbursement_Date__c,
          channel_partner_name: this.channelPartnerName,
          email_id: this.user_email,
          name: this.name
        }

        this.data.getFeedbackUrl(body).subscribe(res => {
          
          if (res) {
            this.feedbackResp = res;
            if (this.feedbackResp.data === '') { 
              this.feedBackDone = this.feedbackResp.message
              this.feedBackDoneTost = true;
              if (this.channelName != '') {
                let phone = localStorage.getItem('dmi_phone');
                this.campign_number = 'null';
                this.eventName = `Tab name ${this.tabName} : You have already given your feedback.`;  // Ensure there's a space after 'the'
                let source = 'DMI-Customer-Portal';
              
                const body1 = {
                  phone: phone,
                  eventname: this.eventName,
                  campign_number: this.campign_number,
                  source: source,
                  partner: this.channelPartnerName
                };
              
                this.data.getEventName(body1).subscribe(res => {
                  // handle response if necessary
                });
              }
              if (this.tabName === 'logout') {
                this.logout();
                this.sessionExist = false;
              }
            }
            else {
              this.feedbackResponse = this.feedbackResp.data.url;
              this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.feedbackResponse);
            }
          }
        });
      }
    });
    
    ////////////////////////////document///////////////////////////

    if (window.addEventListener) {
      window.addEventListener("message", onMessage, false);
    }
    else if ((<any>window).attachEvent) {
      (<any>window).attachEvent("onmessage", onMessage, false);
    }
    function onMessage(event) {
      if (event.origin !== "https://app.litmusworld.com" || event.origin !== "https://app-india.litmusworld.com" || event.origin !== "https://dashboard.litmusworld.com" ||
        event.origin !== "https://dashboard-india.litmusworld.com") return;

      var data = event.data;
      if (typeof (window[data.func]) == "function") {
        // window[data.func].call(null, data.message);
        alert(event);
      }
    }

    ////////////////////////////document///////////////////////////
  }

  closeRateLogout() {
    let phone = localStorage.getItem('dmi_phone')
    let campign_number = this.campign_number;
    this.eventName = 'Made an action to "Logout" from Side Menu for Loan Name' + ': ' + campign_number;
    let source = 'DMI-Customer-Portal'
    this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
    if (this.channelName != '') {
      const body1 = {
        phone: phone,
        eventname: this.eventName,
        campign_number: this.campign_number,
        source: source,
        partner: this.SpeceficLoanDetail[0].Pulled_Lead_Source_Name__c

      }
      this.data.getEventName(body1).subscribe(res => {
      })
    }
    this.logout();
  }

  // Get Pass Key
  getpassKey() {
    this.router.navigate(['/getpasskey'])

  }

  passkey: boolean = false;
  isDisabled: boolean = false;
  loandetailsloader: boolean = false;
  errorMobile: boolean = false;
  Error: any
  async proceed(PasskeyForm: any): Promise<void> {
    this.submitted = true;
    this.isDisabled = true;
    if (PasskeyForm.invalid) {

      PasskeyForm.controls.Passpin.touched = true;
      this.isDisabled = false;
      return;
    }
    this.passpin = this.PasskeyForm.get('Passpin').value;

    if (this.passpin == '' || this.passpin == undefined) {

      this.isDisabled = true;
    }
    if (this.passpin != '') {

      this.isDisabled = false;
      this.passpin = this.PasskeyForm.get('Passpin').value;
      const body = {
        user_id: sessionStorage.getItem('userID'),
        loan_id: this.SpeceficLoanDetail[0].name,
        pass_key: this.passpin,
      }
      try {
        this.loandetailsloader = true;
        this.isDisabled = true;
        this.hidemodeldata = true;
        const keyresponse = await this.data.getpasskey(body).toPromise();

        if (keyresponse.status == 200) {
          this.hidemodeldata = false;
          this.Passkeydone = true;
          this.loandetailsloader = false;
          this.isDisabled = false;
          this.passkey = false;
          this.passkeydata = keyresponse.data.pinNumber;
          this.eventTracking('Get_Pass_Key');

        }
        else {

          this.isDisabled = false;
          this.hidemodeldata = false;
          ///alert message
          this.isDisabled = false;
          this.loandetailsloader = false;
          this.passkey = false;
          this.Passkeydone = false;
          this.Alert = true;
          this.errorMessage = true;
          this.message = JSON.parse(decryptString(keyresponse.data, this.secretKey))
          this.error = this.message.message.ErrMessage;
          this.eventTracking('Get_Pass_Key_Failed');
        }
      }
      catch (error) {

        ///alert message
        this.isDisabled = false;
        this.passkey = false;
        this.hidemodeldata = false;
        this.Passkeydone = false;
        this.Alert = true;
        this.loandetailsloader = false;
        this.errorMessage = true;
        this.message = JSON.parse(decryptString(error.error.data, this.secretKey))
        this.error = this.message.message.ErrMessage;
        this.eventTracking('Get_Pass_Key_Failure');

      }

    }
  }
  Passkeydone: boolean = false;
  errorMessage: boolean = false;
  error: any;
  message: any;
  passpin: any;
  passkeydata: any;
  active: boolean = false;
  hidemodeldata: boolean = false;
  MobileNumber: any;
  Alert: boolean = false;
  getpass() {
    document.getElementById('GetpassKeymodel').click();
    this.MobileNumber = '';
    this.PasskeyForm.reset();
    this.submitted = false;
    this.active = true;
    this.passkey = true;
    this.Passkeydone = false;
    this.loandetailsloader = false;
    this.Alert = false;
    this.hidemodeldata = false;
    (document.getElementsByClassName('modal-backdrop')[0] as HTMLDivElement).style.setProperty('position', 'fixed', 'important');
    this.eventTracking('Open_Get_Pass_Key');
  }
  // Automatic model Open
  passkey2() {
    document.getElementById("openpasskey").click();
    this.eventTracking('Open_Get_Pass_Key');
    (document.getElementsByClassName('modal-backdrop')[0] as HTMLDivElement).style.setProperty('position', 'fixed', 'important');

  }
  closemodel1() {
    this.PasskeyForm.reset();
    (document.getElementsByClassName('modal-backdrop')[0] as HTMLDivElement).style.removeProperty('position');
    this.eventTracking('Closed_Get_Pass_Key');

  }

  ///Number Validation
  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  eventTracking(Event_Name) {
    const body = {
      phone: localStorage.getItem('dmi_phone'),
      eventname: Event_Name,
      campign_number: this.campign_number,
      source: 'DMI-Customer-Portal',
      partner: this.SpeceficLoanDetail[0].Pulled_Lead_Source_Name__c,

    }
    this.data.getEventName(body).subscribe();

  }
}
