import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../data.service';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
enum CheckBoxType { APPLY_FOR_JOB, MODIFY_A_JOB, MODIFY_A_JOB1, MODIFY_A_JOB2, NONE };
@Component({
  selector: 'app-loan-offer',
  templateUrl: './loan-offer.component.html',
  styleUrls: ['./loan-offer.component.scss']
})
export class LoanOfferComponent implements OnInit {
  userActivity;
  expiryDate: any;
  Campign_category1: any;
  dynamictabledata: any;
  Offer_status: any;
  offerdecision: any;
  offervalue: any;
  OfferdataShow: boolean = false;
  nochecboxdisabled: boolean = false;
  yeschecboxdisabled: boolean = false;
  offerData: any;
  Campign_category: any;
  sourcing_channel1: any;
  registerForm: FormGroup;
  userInactive: Subject<any> = new Subject();
  loanOffer: any;
  offerAmount: any;
  convertedOfferAmt: any;
  userBasicInfo: any;
  model_overlay: boolean = false;
  trendingArr: any = [];
  pageno: any;
  blogDetailsData: any;
  channelName: any = '';
  channelPartnerName: any = '';
  loanRestuctUrl: any = '';
  showLoader: boolean = true;
  Nooffererror: boolean = false;
  userId: any;
  SelectNooffer: boolean = false;
  OfferLoader: boolean = false;
  constructor(private router: Router, private data: DataService,
    private modalService: NgbModal, private sanitizer: DomSanitizer, private formBuilder: FormBuilder,) {
    this.setTimeout();
    this.userInactive.subscribe(() => {
      localStorage.clear();
      this.router.navigate(['customerportal&currentuser&sessiontimeout'])
    });
  }
  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 900000);
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
  lead_source: any;
  ngOnInit() {
    this.lead_source = sessionStorage.getItem('company_Name')
    this.userId = sessionStorage.getItem('userID');
    this.registerForm = this.formBuilder.group({
      lessAmountType: ['', Validators.required],
      fillAmount: ['', Validators.required]
    });
    const body1 = {
      phone: localStorage.getItem('dmi_phone')
    }
    this.data.getChannelPartner(body1).subscribe(res => {
      this.channelName = res;
      this.channelPartnerName = this.channelName.data;
      if (this.channelName != '') {
        // Start event tracking
        let eventName = 'Visited : Offers'
        let phone = localStorage.getItem('dmi_phone')
        let campign_number = 'null';
        let source = 'DMI-Customer-Portal';
        const body1 = {
          phone: phone,
          eventname: eventName,
          campign_number: campign_number,
          source: source,
          partner: this.channelPartnerName

        }
        this.data.getEventName(body1).subscribe(res => {
        })
        // end
      }
    });
    this.pageno = '1';
    const body = {
      pageno: this.pageno
    }
    this.data.getTrendingDataList(body).subscribe(res => {
      this.trendingArr = res;
    });
    this.GetOffers();
    // get loan offer from localStorage
  }
  check_box_type = CheckBoxType;

  currentlyChecked: CheckBoxType;
  selectCheckBox(targetType: CheckBoxType) {
    console.log(targetType, 'target')
    // If the checkbox was already checked, clear the currentlyChecked variable
    if (this.currentlyChecked === targetType) {
      this.currentlyChecked = CheckBoxType.NONE;
      return;
    }

    this.currentlyChecked = targetType;
  }

  onChange(event) {
    this.offervalue = event.target.checked;
    this.offerdecision = event.target.value;
  }
  StatusNo: any;
  StatusYes: any;
  async GetOffers() {
    // this.registerForm.reset();

    this.currentlyChecked = null;
    this.offervalue = '';
    this.OfferdataShow = false;
    this.Nooffererror = false;
    this.OfferLoader = true;
    this.SelectNooffer = false;
    const body = { user_id: this.userId, mobile: localStorage.getItem('dmi_phone'), pulled_lead_source_name: sessionStorage.getItem('company_Name') }

    try {
      this.OfferLoader = true;
      const Offerresponse = await this.data.customersoffers(body).toPromise();
      this.offerData = Offerresponse.data;
      this.eventTracking('Get Offer');
      this.OfferdataShow = true;
      this.Nooffererror = false;
      console.log(this.offerData, 'offer')
      this.model_overlay = false;
      this.Campign_category1 = this.offerData.filter((item) => {
        this.Campign_category = item.Campaign_Sub_Category;
        this.sourcing_channel1 = item.Sourcing_Channel;
        this.Offer_status = item.Status;
        this.expiryDate = item.Offer_Expiry_Date;
        this.StatusNo = item.Status_No;
        this.StatusYes = item.Status_Yes;
        console.log(this.StatusNo, this.StatusYes)
        // this.Offer_status = 0;
        if (this.StatusNo == 1) {
          this.nochecboxdisabled = false;
          this.yeschecboxdisabled = false;
        }
        else if (this.StatusYes == 1 || this.StatusYes == 2) {
          this.nochecboxdisabled = true;
          this.yeschecboxdisabled = false;
        }
        console.log(this.Campign_category, this.Offer_status)
        return item;
      })

      this.OfferLoader = false;
      console.log(this.offerData, this.Campign_category)
      if (this.offerData == '[]') {
        this.Nooffererror = true;
        this.OfferLoader = false;
        this.OfferdataShow = false;
      }

    } catch (error) {
      this.Nooffererror = true;
      this.OfferLoader = false;
      this.OfferdataShow = false;
    }
  }
  IsOffer: boolean = false;
  WhasappMessage: any;
  offerSubmit() {
    this.IsOffer = false;
    if (this.offerdecision == 'No') {
      this.SelectNooffer = true;
      this.OfferdataShow = false;
      this.model_overlay = false;
      this.registerForm.reset({ lessAmountType: '', fillAmount: '' })
      this.eventTracking('Offer Clicked No');
    }
    else {
      this.IsOffer = true;
      this.eventTracking('Offer Clicked Yes');
      const body = {
        user_id: this.userId,
        mobile: localStorage.getItem('dmi_phone'),
        expiry_date: this.expiryDate,
        decision: this.offerdecision,
        campaign_sub_category: this.Campign_category,
        sourcing_channel: this.sourcing_channel1

      }
      this.data.customer_OffersDecision(body).subscribe(res => {
        this.SelectNooffer = false;
        this.IsOffer = false;
        if (this.StatusYes == 0) {
          this.eventTracking('Whatsapp Message Send');
          this.WhasappMessage = 'A whatsapp has been sent on your registered mobile number please follow instructions to avail offer.'
        }
        else if (this.StatusYes == 2) {
          this.eventTracking('Whatsapp Message Not Send');
          this.WhasappMessage = 'We have already sent a whatsapp to you earlier on how to avail this offer. Please refer same to avail offer.'
        }
        this.model_overlay = true;

      }, (error) => {
        this.model_overlay = false;
        this.IsOffer = false;
      })
    }

  }

  loaderUnlock: boolean = false;
  NoOfferAmount() {
    this.loaderUnlock = true;
    console.log(this.registerForm.controls['lessAmountType'].value)
    console.log(this.registerForm.controls['fillAmount'].value)

    const body = {
      user_id: this.userId,
      mobile: localStorage.getItem('dmi_phone'),
      decision: this.offerdecision,
      loan_offer_type: this.registerForm.controls['lessAmountType'].value,
      loan_offer_value: this.registerForm.controls['fillAmount'].value,
      expiry_date: this.expiryDate,
      campaign_sub_category: this.Campign_category,
      sourcing_channel: this.sourcing_channel1

    }
    this.data.customer_OffersDecision(body).subscribe(res => {
      this.eventTracking('Offer Changes With Reason ' + this.registerForm.controls['lessAmountType'].value);
      this.model_overlay = true;
      this.loaderUnlock = false;

    }, (error) => {
      this.model_overlay = false;
      this.loaderUnlock = false;
      this.SelectNooffer = true;
    })
  }
  closeModelpopup() {
    this.GetOffers();
    this.SelectNooffer = false;
    this.model_overlay = false;
  }
  loanDetails(id) {
    const body = {
      blog_id: id
    }
    this.data.getBlogDetails(body).subscribe(res => {
      this.blogDetailsData = res;
      localStorage.setItem('sliderBlogDetail', JSON.stringify(this.blogDetailsData.data))
      this.router.navigate(['blogdetails'])
    });
  }

  numberOnly(event: any) {
    const pattern = /^([1-9][0-9]*|0)$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();

    }
    return true;
  }

  eventTracking(Event_Name) {
    const body = {
      phone: localStorage.getItem('dmi_phone'),
      eventname: Event_Name,
      campign_number: localStorage.getItem('single_loan_name'),
      source: 'DMI-Customer-Portal',
      partner: sessionStorage.getItem('company_Name'),

    }
    this.data.getEventName(body).subscribe();

  }

  ngOnDestroy() {
    clearTimeout(this.userActivity);
  }

}
