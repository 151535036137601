import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { DataService } from '../data.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private dataService: DataService
  ) {}

  // Date: 01-03-2025
  // Time: 18:00 IST
  // Developer: Naman Jain
  // Description : Here canActivate method check localStorage for allowed navigation.

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.isLoggedIn.pipe(
      take(1),
      switchMap((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.router.navigate(['/']); // Redirect to login if not authenticated
          return of(false);
        }

        if (state.url === '/refund') {
          //  Fetch refund tab visibility before allowing access
          return this.dataService
            .Show_Refund_Tab({
              phone_number: localStorage.getItem('dmi_phone') || '',
              opp_name: localStorage.getItem('single_loan_name') || '',
            })
            .pipe(
              map((res) => {
                const showTabBar = res.data.show_tab;

                if (showTabBar) {
                  localStorage.setItem('refund_tab_visible', 'true');
                  this.authService.allowNavigation('/refund');
                  return true;
                } else {
                  localStorage.removeItem('refund_tab_visible');
                  this.router.navigate(['/dashboard']); //  Redirect if unauthorized
                  return false;
                }
              }),
              catchError((error) => {
                console.error('Error fetching refund tab:', error);
                this.router.navigate(['/dashboard']); //  Redirect if API fails
                return of(false);
              })
            );
        }

        return of(true); //  Allow access for other routes
      })
    );
  }
}
