import { Component, OnInit, HostListener } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from 'src/app/data.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {
  NgbDateStruct,
  NgbCalendar,
  NgbDatepickerConfig,
} from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { encryptString, decryptString } from 'src/Utils/encryption';
// import { Modal } from 'bootstrap';

@Component({
  selector: 'app-create-tickets',
  templateUrl: './create-tickets.component.html',
  styleUrls: ['./create-tickets.component.scss'],
})
export class CreateTicketsComponent implements OnInit {
  userActivity;
  userInactive: Subject<any> = new Subject();
  uploadtktimage = '';
  uploadtktimagewrite: any = '';
  tktimgset: boolean = false;
  remarkErr: any;
  remarkErrwrite: any;
  paymentDerr: any;
  transactionRefNoerr: any;
  amtPaidErr: any;
  emailIdErr: any;
  subjectErr: any;
  subjectErrwrite: any;
  bankErrwrite: any;
  imagePath: any;
  loanDetail: any;
  paymentDate: string = '';
  loanNane: string = '';
  loanNanewrite: string = '';
  transactionRefNo: string = '';
  amtPaid: string = '';
  emailId: string = '';
  remarks: string = '';
  remarkswrite: string = '';
  tktResData: any;
  statusData: string = 'Open';
  origin: string = 'Customer Portal';
  priority: string = 'Medium';
  division: string = 'Retail';
  ticketType: string = 'Complaint';
  ticketSubType: string = 'EPI Related Complaint';
  contact_info: any;
  userBasicInfo: any;
  userPhone: any;
  subject: string = '';
  subjectwrite: string = '';

  bankname: string = '';
  other: any;
  samsungUI: boolean = false;
  createTktSection: boolean = false;
  notallowedcreateTkt: boolean = false;
  paymentnotreflectinsection: boolean = false;
  otherSection: boolean = true;
  EmandateSection: boolean = true;
  display = 'none';
  display1 = 'none';
  fileSizeExceedErr: any;
  disableDate: any;
  loanNameErr: any;
  loanNameErrwrite: any;
  attachmentReq: string = '';
  attachmentReqwrite: string = '';
  campign_number: any;
  eventName: any;
  issubmitTkt: boolean = false;
  tktcaseno: any;

  filetoUpload: Array<File> = [];
  filetoUploadWrite: Array<File> = [];

  fileName: string = 'Upload File';
  fileNameWrite: string = 'Upload File';

  attachFile: any = 'assets/attachment-icon.svg';
  extension: any;
  attachFileWrite: any = 'assets/attachment-icon.svg';
  extensionWrite: any;
  issidenaav: boolean = false;
  showloader: boolean = false;
  showAlert: boolean = false;
  userName1: any;
  company_name: any = '';
  channelName: any = '';
  channelPartnerName: any = '';
  selectionType: any = '';
  samsungSubject: any = '';
  selectedLoan: any = '';
  faqArr: any = [];
  click = -1;
  openTicket: any[] = [];
  allTickets: any[] = [];
  closeTicket: any[] = [];
  ticketResponse: any;
  ticketDetailsData: any;
  allTicketsFlag: boolean = true;
  status: string = 'active';
  caseLoader: boolean = false;
  getInput: any;
  accordionDataBackup: any[] = [];
  selectionTypeArray: any = [
    { name: 'Payment made but not reflecting', Id: 4 },
    { name: 'Write to Us', Id: 5 },
  ];
  // modal: any

  constructor(
    public domSanatize: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private data: DataService,
    private router: Router,
    private config: NgbDatepickerConfig,
    private http: HttpClient
  ) {
    this.setTimeout();
    this.userInactive.subscribe(() => {
      localStorage.clear();
      // Logout chatbot when session times out
      if ((window as any).Kommunicate) {
        (window as any).Kommunicate.logout();
      }
      this.router.navigate(['customerportal&currentuser&sessiontimeout']);
    });
  }

  setTimeout() {
    this.userActivity = setTimeout(
      () => this.userInactive.next(undefined),
      900000
    );
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  lead_source: any;

  ngOnInit() {
    var chatContext = {
      loggedin: true,
      accountid: sessionStorage.getItem('accountId')
        ? sessionStorage.getItem('accountId')
        : '',
      mobile: localStorage.getItem('dmi_phone')
        ? localStorage.getItem('dmi_phone')
        : '',
      plateform: 'Customer Portal',
    };

    if (window && (window as any).Kommunicate) {
      (window as any).Kommunicate.updateChatContext(chatContext);
    } else {
      console.error('Kommunicate SDK not loaded');
    }

    this.lead_source = sessionStorage.getItem('company_Name');

    const body2 = {
      phone: localStorage.getItem('dmi_phone'),
    };

    this.data.getChannelPartner(body2).subscribe((res) => {
      this.channelName = res;
      this.channelPartnerName = this.channelName.data;
    });

    this.selectionType = this.selectionTypeArray[1].Id;

    this.userName1 = localStorage.getItem('user_basic_name');

    if (this.router.url === '/createtickets') {
      this.issidenaav = true;
    }

    let phone = localStorage.getItem('dmi_phone');
    if (localStorage.getItem('single_loan_name')) {
      this.campign_number = localStorage.getItem('single_loan_name');
      this.company_name = sessionStorage.getItem('company_Name');
      this.eventName =
        'Service ticket creation From ' +
        localStorage.getItem('single_loan_name');
    } else {
      this.campign_number = 'Not Available';
      this.company_name = this.channelPartnerName;
      this.eventName =
        'Service ticket creation For Search Term "from Help Center"';
    }
    let source = 'DMI-Customer-Portal';
    this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
    console.log('this.loanDetail', this.loanDetail);
    const body1 = {
      phone: localStorage.getItem('dmi_phone'),
      eventname: this.eventName,
      campign_number: this.campign_number,
      source: source,
      partner: this.company_name,
    };

    this.data.getEventName(body1).subscribe((res) => {});
    const current = new Date();
    this.disableDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate() - 3,
    };
    if (this.other) {
      this.ticketType = '';
      this.ticketSubType = '';
    }

    this.getFAQList();
    this.loadKommunicateScript();
    this.getAllTicket();
  }

  showDialog() {
    document.getElementById('openContactModal').click();

    const confirmationModal = document.getElementById('contactModal');
    if (confirmationModal) {
      (confirmationModal as any).classList.add('show');
      (confirmationModal as any).style.display = 'block';
      document.body.classList.add('modal-open');
    }
  }

  getFAQList() {
    const body = {
      user_id: localStorage.getItem('userId'),
    };

    this.data.getTicketingSystemFaq(body).subscribe((res) => {
      this.faqArr = res.data;
      this.accordionDataBackup = this.faqArr;
    });
  }

  getInputValues() {
    this.faqArr = this.accordionDataBackup;
    this.faqArr = this.faqArr.filter((item: any) => {
      if (item.title.toLowerCase().includes(this.getInput.toLowerCase())) {
        return item;
      }
      return null;
    });
  }

  listClick(ind: any) {
    console.log(ind);

    if (this.click === ind) {
      this.click = -1;
    } else {
      this.click = ind;
    }
  }

  //   showDialog() {
  //   this.modal.show();
  // }

  // Load the Kommunicate script dynamically
  loadKommunicateScript() {
    (function (d, m) {
      var kommunicateSettings = {
        botId: environment.BOT_ID,
        appId: environment.APP_ID,
        onInit: function () {
          console.log('Kommunicate initialized');

          // Store the original sendMessage function
          (window as any).KommunicateGlobal =
            (window as any).KommunicateGlobal || {};
          (window as any).KommunicateGlobal.originalSendMessage = (
            window as any
          ).Kommunicate.sendMessage;

          // Override sendMessage to modify metadata before sending
          (window as any).Kommunicate.sendMessage = function (message: any) {
            console.log('Intercepting message before sending:', message);

            // Retrieve the latest metadata values
            const accountId = sessionStorage.getItem('accountId') || '';
            const mobile = localStorage.getItem('dmi_phone') || '';
            const source = 'Customer Portal Chat Bot';

            // Ensure metadata exists
            message.metadata = message.metadata || {};
            message.metadata.account_id = accountId;
            message.metadata.mobile_number = mobile;
            message.metadata.source = source;

            // Ensure KM_CHAT_CONTEXT is updated correctly
            message.metadata.KM_CHAT_CONTEXT = JSON.stringify({
              kmUserLocale: 'en',
              account_id: accountId,
              mobile_number: mobile,
              source: source,
              botId: environment.BOT_ID,
              appId: environment.APP_ID,
            });

            console.log('Updated message metadata before sending:', message);

            // Call the original sendMessage function
            return (window as any).KommunicateGlobal.originalSendMessage(
              message
            );
          };
        },
      };

      var s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://widget.kommunicate.io/v2/kommunicate.app';
      var h = document.getElementsByTagName('head')[0];
      h.appendChild(s);
      (window as any).kommunicate = m;
      m._globals = kommunicateSettings;
    })(document, (window as any).kommunicate || {});
  }

  setChatMetadata() {
    (window as any).Kommunicate.updateChatContext({
      metadata: {
        source: ' Customer Portal Chat Bot',
        botId: environment.BOT_ID,
        appId: environment.APP_ID,
      },
    });
  }

  // Open Kommunicate chat
  openKommunicateChat() {
    if ((window as any).Kommunicate) {
      this.setChatMetadata(); // Ensure metadata is updated before starting
      console.log('Opening chat...');
      (window as any).Kommunicate.startConversation(
        (response: any) => {
          console.log('Conversation started:', response);
        },
        (error: any) => {
          console.error('Error starting conversation:', error);
        }
      );
    } else {
      console.error('Kommunicate is not initialized.');
    }
  }

  openUrl(url: any, type: any) {
    if (type == 'pdf') {
      window.open(url, '_blank');
    } else {
      window.open(url, '_blank');
    }
  }

  getAllTicket() {
    //     const body = {
    //     user_id: 228,
    //     phone_number: "6397070327",
    //     account_id: "001Bg0000054fUjIAI"
    // }

    const body = {
      user_id: localStorage.getItem('userId'),
      phone_number: localStorage.getItem('dmi_phone'),
      account_id: sessionStorage.getItem('accountId'),
    };

    this.caseLoader = true;

    this.data.getCaseDeatails(body).subscribe(
      (res) => {
        if (res.status == 200) {
          this.ticketResponse = res;
          this.allTickets = this.ticketResponse.data.activeCases;
          this.openTicket = this.allTickets.slice(0, 4);
          this.caseLoader = false;
        } else {
          this.caseLoader = true;
          this.openTicket = [];
        }
      },
      (error) => {
        this.caseLoader = false;
        this.openTicket = [];
      }
    );
  }

  onValueChange(event: any) {
    this.status = event;
    if (this.status == 'active') {
      this.allTickets = this.ticketResponse.data.activeCases;
      this.openTicket = this.allTickets.slice(0, 4);
      this.allTicketsFlag = true;
    } else {
      this.allTickets = this.ticketResponse.data.closedCases;
      this.openTicket = this.allTickets.slice(0, 4);
      this.allTicketsFlag = true;
    }
  }

  // ticketDetails(ticket: any) {
  //   console.log('paymentlinkCreate');

  //   const paymentlinkCreate = document.getElementById('paymentlinkCreate');

  //   if (paymentlinkCreate) {
  //     paymentlinkCreate.click();
  //   }

  //   this.ticketDetailsData = ticket;
  // }

  ticketDetails(ticket: any) {
    console.log('paymentlinkCreate');

    const paymentlinkCreate = document.getElementById('paymentlinkCreate');

    if (paymentlinkCreate) {
      paymentlinkCreate.click();
    }

    this.ticketDetailsData = ticket;
  }

  getAllTickets() {
    this.openTicket = this.allTickets;
    this.allTicketsFlag = false;
  }

  getBack() {
    this.allTicketsFlag = true;
    if (this.status == 'active') {
      this.allTickets = this.ticketResponse.data.activeCases;
      this.openTicket = this.allTickets.slice(0, 4);
    } else {
      this.allTickets = this.ticketResponse.data.closedCases;
      this.openTicket = this.allTickets.slice(0, 4);
    }
  }

  // Extract file name from URL
  getFileNameFromUrl(url: string): string {
    const parts = url.split('/');
    const fileName = parts[parts.length - 1].split('?')[0]; // Get the last part and remove query params
    return decodeURIComponent(fileName);
  }

  // Method to download file
  downloadFile(url: string) {
    const fileName = this.getFileNameFromUrl(url);

    this.http.get(url, { responseType: 'blob' }).subscribe(
      (blob) => {
        // Create a link element to trigger the download
        const link = document.createElement('a');
        const fileUrl = window.URL.createObjectURL(blob);
        link.href = fileUrl;
        link.download = fileName; // Use the extracted file name
        document.body.appendChild(link);
        link.click(); // Trigger the download
        document.body.removeChild(link);
        window.URL.revokeObjectURL(fileUrl); // Clean up URL object
      },
      (error) => {
        console.error('Download error:', error);
      }
    );
  }
  ngOnDestroy() {
    clearTimeout(this.userActivity);
  }
}
