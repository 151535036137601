import { Component, OnInit, HostListener } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DataService } from '../data.service';
import { Subject } from 'rxjs';
declare var Razorpay: any;
@Component({
  selector: 'app-noc',
  templateUrl: './noc.component.html',
  styleUrls: ['./noc.component.scss']
})
export class NocComponent implements OnInit {
  userActivity;
  userInactive: Subject<any> = new Subject();
  userBasicInfo: any;
  speceficLoanDetail: any;
  nocStatus: boolean = false;
  noc_letter_pdf: SafeResourceUrl;
  welcomedoc1: any;
  createdWelcomeLetter: any;
  welcomeLetterUrl: any;
  userName: any;
  loanAccountNo: any;
  phone: any;
  address: any;
  opp_Acc_Name: any;
  loanDetail: any;
  amount: any;
  Opp_Name: any;
  noc_letter_data: any;
  noc_letter: any;
  loanName: any
  userName1: any;
  channelName: any = '';
  channelPartnerName: any = ''; noc_ammount: any = '';
  payNowcustomerId: any; payNoworderId: any; payNowData: any; display2 = 'none'; payNowModel = 'none'; email: any;
  loanId = localStorage.getItem('specefic_loan_id');
  chargeData: any = ''; campign_number: any;
  paynowDisable: boolean = false;
  razorpayKey: any
  constructor(public sanitizer: DomSanitizer,
    private router: Router,
    private data: DataService) {
    this.setTimeout();
    this.userInactive.subscribe(() => {
      localStorage.clear();
      this.router.navigate(['customerportal&currentuser&sessiontimeout'])
    });
  }
  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 900000);
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  ngOnInit() {
    this.userName1 = localStorage.getItem('user_basic_name')
    this.userBasicInfo = JSON.parse(localStorage.getItem('user_basic_info')) || [];
    this.email = this.userBasicInfo.data[0].Email;
    // var accessToken = localStorage.getItem('dmi_token')
    // var url = localStorage.getItem('dmi_instance_url')
    var parterName = sessionStorage.getItem('company_Name')
    this.razorpayKey = this.data.razorpayKey;

    this.userName = this.userBasicInfo.data[0].Name
    //  this.phone = this.userBasicInfo.data[0].MobilePhone
    this.address = this.userBasicInfo.data[0].MailingStreet + ', ' + this.userBasicInfo.data[0].MailingCity + ', ' + this.userBasicInfo.data[0].MailingState + ', ' + this.userBasicInfo.data[0].MailingPostalCode + ', ' + this.userBasicInfo.data[0].MailingCountry;
    this.loanAccountNo = "52456312123"

    this.loanDetail = JSON.parse(localStorage.getItem('specific_loan_detail'));
    this.amount = this.loanDetail[0].Sanction_Amount__c;
    this.Opp_Name = this.loanDetail[0].name;
    const body1 = {
      phone: localStorage.getItem('dmi_phone')
    }
    this.data.getChannelPartner(body1).subscribe(res => {
      this.channelName = res;
      this.channelPartnerName = this.channelName.data;
    });
    // Start event tracking
    let eventName = 'NOC Document Page';
    let phone = localStorage.getItem('dmi_phone');
    this.campign_number = this.Opp_Name;
    let source = 'DMI-Customer-Portal';
    if (this.channelName != '') {
      const body1 = {
        phone: phone,
        eventname: eventName,
        campign_number: this.campign_number,
        source: source,
        partner: this.loanDetail[0].Pulled_Lead_Source_Name__c

      }
      this.data.getEventName(body1).subscribe(res => {
      });
    }
    // end
    const body = {
      opp_Acc_Name: this.userName,
      OppAddress: this.address,
      Opp_Name: this.Opp_Name,
      amount: this.amount,
      partner: parterName
    }


    this.data.createNocLetter(body).subscribe(res => {
      this.noc_letter_data = res;
      let nocLetter = this.noc_letter_data.data.FileURL;

      this.noc_letter = nocLetter.slice(27);
      this.chargeData = this.noc_letter_data.data.IsChargesPending;
      this.noc_ammount = this.noc_letter_data.data.Amount;

      let final_nocLetter = this.noc_letter.split(';')[0]
      this.noc_letter_pdf = this.sanitizer.bypassSecurityTrustResourceUrl(nocLetter);

      let eventName = 'NOC Document Created';
      let phone = localStorage.getItem('dmi_phone');
      // let campign_number = this.Opp_Name;
      let source = 'DMI-Customer-Portal';
      this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
      if (this.channelName != '') {
        const body1 = {
          phone: phone,
          eventname: eventName,
          campign_number: this.campign_number,
          source: source,
          partner: this.loanDetail[0].Pulled_Lead_Source_Name__c

        }
        this.data.getEventName(body1).subscribe(res => {
        });
      }
    }, error => {
      if (error.error.message.error === 'Sorry NOC is not available.') {
        this.nocStatus = true;
      }
    });
  }
  payAmountNow() {

    // Start event tracking
    this.paynowDisable = true;
    let eventName = 'Pay Loan EMI Amount'
    this.phone = localStorage.getItem('dmi_phone');
    this.loanName = this.campign_number;

    let source = 'Cp-noc-payment';
    let method = 'Payment';

    this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
    if (this.channelName != '') {
      const body1 = {
        phone: this.phone,
        eventname: eventName,
        campign_number: this.campign_number,
        source: source,
        partner: sessionStorage.getItem('company_Name')

      }
      this.data.getEventName(body1).subscribe(res => {
      });
    }
    // end

    // this.paidPayableAmount = this.forcluserdata[0].total_amount_to_be_paid;
    // this.totalAmt  = this.paidPayableAmount.toString().replace(/[^A-Za-z0-9]+/g,"");
    let amount = Math.trunc(this.noc_ammount);
    // console.log(amount)
    const body =
    {
      phone: this.phone,
      name: this.userName,
      loanname: this.loanName,
      amount: amount
    }
    this.data.getPayNow(body).subscribe(res => {
      this.paynowDisable = false;
      this.payNowData = res

      this.payNowcustomerId = this.payNowData.data.customerid.id;
      this.payNoworderId = this.payNowData.data.orderid.id;
      var payNowOptions = {

        "key": this.razorpayKey,
        "currency": "INR",
        "name": this.loanName,
        "description": "Pay your emi",
        "image": "https://los.dmifinance.in/assets/images/logo.svg",
        "order_id": this.payNoworderId,//Order ID is generated as Orders API has been implemented. Refer the Checkout form table given below
        "handler": (response) => {
          this.display2 = 'block';
          const body = {
            phone: this.phone,
            loan_number: this.loanName,
            amount: amount,
            source: source,
            method: method,
            customer_id: this.payNowcustomerId,
            order_id: this.payNoworderId,
            name: this.userName,
            email: this.email
          }
          this.data.getpaymentRecords(body).subscribe(res => {
            if (res) {
              // this.data.foreclosurePayInfo(this.phone, this.loanName, amount, 'null' ).subscribe(res => {} )
            }
            // alert('Saved payment record successfully.')
          })
        },
        "prefill": {
          "email": this.email,
          "contact": this.phone
        },
        "notes": {
          "opportunityName": this.loanName,
          "senderId": "DMI",
          "opportunityID ": this.loanId,
          "billingAddress": this.address,
          "customerName": this.userName1
        },
        "theme": {
          "color": "#F37254"
        }
      };

      let eventName = 'Razorpay_Popup_Open_NOC'

      const razorpayBody = {
        phone: this.phone,
        eventname: eventName,
        campign_number: this.loanName,
        source: 'DMI-Customer-Portal',
        partner: sessionStorage.getItem('company_Name'),
        remark_1: payNowOptions

      }

      if (this.loanName) {
        this.data.getEventName(razorpayBody).subscribe();
        var rzp1 = new Razorpay(payNowOptions);
        rzp1.open();
        if (rzp1.open()) {
          this.payNowModel = 'none';
        }
      }
      else {

      }

    })
  }


  ngOnDestroy() {
    clearTimeout(this.userActivity);
  }
}
