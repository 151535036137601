import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-section',
  templateUrl: './footer-section.component.html',
  styleUrls: ['./footer-section.component.scss']
})
export class FooterSectionComponent implements OnInit {
  partnername: any;
  constructor() {
    this.partnername = sessionStorage.getItem('company_Name');
    console.log(this.partnername, '1')
  }

  ngOnInit() {
    this.partnername = sessionStorage.getItem('company_Name');
    console.log(this.partnername, '2')
  }



}
