import {
  AfterViewInit,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from '../data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Utils } from 'ngx-bootstrap';
import { decryptString } from 'src/Utils/encryption';
import { environment } from 'src/environments/environment.prod';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-proactive-refund',
  templateUrl: './proactive-refund.component.html',
  styleUrls: ['./proactive-refund.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProactiveRefundComponent implements OnInit, AfterViewInit {
  secretKey = environment.secretKey;

  refundForm: FormGroup;
  submitted: boolean = false;
  formEdited = false;
  validationResult: string = '';
  caseId: string | null = null;
  opportunityName: string | null = null;
  userId: string | null = null;
  displayModal: any = 'none';
  isLoading: boolean = false;
  isSubmitDisabled = false;
  isReadonly = false;
  userName1: string = 'M/S Shakti Resort';

  freezeForm: string = 'false';
  freezeFormExist: boolean = false;
  isFreshCase: boolean = true;

  isActive: boolean = false;
  showTabBar: boolean = false;
  loader: boolean = false;

  ngOnInit() {
    this.loader = true; // ✅ Show loader initially

    setTimeout(() => {
      this.loader = false; // Hide loader after 4 seconds
    }, 4000);

    this.checkRefundTabVisibility(); // ✅ Fetch refund visibility first

    this.initializeCase();
  }

  checkRefundTabVisibility() {
    const body = {
      phone_number: localStorage.getItem('dmi_phone') || '',
      opp_name: localStorage.getItem('single_loan_name') || '',
    };

    this.dataService.Show_Refund_Tab(body).subscribe(
      (res) => {
        this.showTabBar = res.data.show_tab;
        this.loader = true; // Hide loader when authorized

        // ✅ Store refund tab visibility in localStorage
        if (this.showTabBar) {
          localStorage.setItem('refund_tab_visible', 'true');
        } else {
          localStorage.removeItem('refund_tab_visible');
        }

        // ✅ Call navigation logic only AFTER API response
        this.handleNavigation();
      },
      (error) => {
        console.error('Error fetching refund tab:', error);
        this.router.navigate(['/dashboard']); // Redirect if API fails
      },
      () => {
        this.loader = false; // ✅ Hide loader after API response
      }
    );
  }

  handleNavigation() {
    const isRefundTabStored =
      localStorage.getItem('refund_tab_visible') === 'true';

    if (
      !isRefundTabStored ||
      !this.authService.isNavigationAllowed('/refund')
    ) {
      this.router.navigate(['/dashboard']);
    } else {
      this.loader = false; // ✅ Hide loader only if allowed
    }
  }

  ngAfterViewInit(): void {
  
    // If the session value is null, set it to "false"
    if (sessionStorage.getItem('freeze') === null) {
      sessionStorage.setItem('freeze', 'false');
    }

    // Get the session value (or set default)
    this.freezeForm = sessionStorage.getItem('freeze')
      ? sessionStorage.getItem('freeze')
      : 'false';
    if (this.freezeForm === 'true') {
      this.patchFormIfNeeded();
    }
  }

  // Check if a case is already created
  initializeCase() {
    const userId = localStorage.getItem('userId');

    if (!userId) {
      console.error('User ID is missing');
      return;
    }

    // Check if case data is available in sessionStorage or localStorage
    // const storedCase = sessionStorage.getItem('caseData');
    // const currentTime = new Date().getTime();

    // if (storedCase) {
    //   const { caseId, timestamp, opportunityName } = JSON.parse(storedCase);
    //   if (currentTime - timestamp < 24 * 60 * 60 * 1000) {
    //     // Reuse the case if still valid within 24 hours
    //     this.caseId = caseId;
    //     this.opportunityName = opportunityName;
    //     console.log('Reusing existing case:', this.caseId);
    //     this.submitted = true; // Set form to read-only once the case is reused
    //     // Patch the form with existing case data

    //     return;
    //   }
    // }
    this.eventTracking('Refund Tab Clicked');
    this.createCase();
  }

  isCaseCreated: boolean = false;

  regretErrMessage: string = '';

  patchFormIfNeeded() {
    // Dummy data for patching
    const dummyData = {
      aadharNumber: sessionStorage.getItem('aadhaar_number')
        ? sessionStorage.getItem('aadhaar_number')
        : '',
      panNumber: sessionStorage.getItem('pan_no')
        ? sessionStorage.getItem('pan_no')
        : '',
      bankName: sessionStorage.getItem('bank_name')
        ? sessionStorage.getItem('bank_name')
        : '',
      accountNumber: sessionStorage.getItem('account_number')
        ? sessionStorage.getItem('account_number')
        : '',
      reAccountNumber: sessionStorage.getItem('account_number')
        ? sessionStorage.getItem('account_number')
        : '',
      ifscCode: sessionStorage.getItem('ifsc_code')
        ? sessionStorage.getItem('ifsc_code')
        : '',
      beneficiaryName: sessionStorage.getItem('account_holder_number')
        ? sessionStorage.getItem('account_holder_number')
        : '',

      // add other form controls as needed
    };

    // Patch the form with the dummy data
    this.refundForm.patchValue(dummyData);

    // Optionally disable the form if that’s part of your testing scenario

  }

  eventTracking(Event_Name) {
    const body = {
      phone: localStorage.getItem('dmi_phone'),
      eventname: Event_Name,
      campign_number: localStorage.getItem('single_loan_name'),
      source: 'DMI-Customer-Portal',
      partner: sessionStorage.getItem('company_Name'),
    };
    this.dataService.getEventName(body).subscribe();
  }

  // Create a new case if none exists
  createCase() {
    const userId = localStorage.getItem('userId');
    const phoneNumber = localStorage.getItem('dmi_phone');
    const storedOpportunityName = localStorage.getItem('name');
    const alterLead_source_Name = sessionStorage.getItem('company_Name');

    if (!userId || !phoneNumber || !storedOpportunityName) {
      console.error('Missing required fields for case creation');
      this.snackbar.open(
        'Unable to create case. Missing required fields.',
        'Close',
        {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: 'custom-snackbar-danger',
        }
      );
      return;
    }

    const createCaseBody = {
      user_id: userId,
      phone_number: phoneNumber,
      opp_name: storedOpportunityName,
      lead_source: alterLead_source_Name,
    };

    // console.log('Payload:', createCaseBody);

    this.dataService.createCase(createCaseBody).subscribe(
      (response) => {
    

        this.regretErrMessage = response.data.msg;


        const case_already_created = response.data.case_already_created;

        const aadharNumber = response.data.aadhaar_number;
        const panNumber = response.data.pan_no;
        const bankName = response.data.bank_name;
        const account_number = response.data.account_number;
        const ifscCode = response.data.account_number;
        const beneficiaryName = response.data.account_holder_number;
        const reAccountNumber = response.data.account_number;

        // console.log(panNumber)
        // console.log(
        //   case_already_created === true &&
        //     (account_number === null ||
        //       bankName === null ||
        //       aadharNumber === null ||
        //       panNumber === null ||
        //       ifscCode === null ||
        //       beneficiaryName === null ||
        //       reAccountNumber === null)
        // );
        if (
          case_already_created === true &&
          (account_number === null ||
            bankName === null ||
            aadharNumber === null ||
            panNumber === null ||
            ifscCode === null ||
            beneficiaryName === null ||
            reAccountNumber === null)
        ) {
          this.refundForm.enable();
          this.submitted = false;

          this.isCaseCreated = true;
     
          // Update your variable when condition is true
        } else if (
          case_already_created === false &&
          (account_number === null ||
            bankName === null ||
            aadharNumber === null ||
            panNumber === null ||
            ifscCode === null ||
            beneficiaryName === null ||
            reAccountNumber === null)
        ) {
          this.refundForm.enable();
          this.submitted = false;

          this.isCaseCreated = true;
          // console.log(this.isCaseCreated);
        } else {
     
          this.refundForm.disable();
          this.submitted = true;
        }
        if (
          account_number === null ||
          bankName === null ||
          aadharNumber === null ||
          panNumber === null ||
          ifscCode === null ||
          beneficiaryName === null ||
          reAccountNumber === null
        ) {
          this.refundForm.enable();
        }

        this.freezeForm = response.data.disable;
        this.freezeFormExist = response.data.disable;

        this.refundForm.enable();

        sessionStorage.setItem(
          'account_number',
          response.data.account_number !== null
            ? response.data.account_number
            : ''
        );
        sessionStorage.setItem(
          'pan_no',
          response.data.pan_no !== null ? response.data.pan_no : ''
        );
        sessionStorage.setItem(
          'ifsc_code',
          response.data.ifsc_code !== null ? response.data.ifsc_code : ''
        );
        sessionStorage.setItem(
          'bank_name',
          response.data.bank_name !== null ? response.data.bank_name : ''
        );
        sessionStorage.setItem(
          'account_holder_number',
          response.data.account_holder_number !== null
            ? response.data.account_holder_number
            : ''
        );
        sessionStorage.setItem(
          'aadhaar_number',
          response.data.aadhaar_number !== null
            ? response.data.aadhaar_number
            : ''
        );
        sessionStorage.setItem(
          'case_already_created',
          response.data.case_already_created
        );
        sessionStorage.setItem('freeze', response.data.disable);

        if (response.data.case_already_created) {
          // this.snackbar.open(
          //   'Case already created. Reusing existing case.',
          //   'Close',
          //   {
          //     duration: 3000,
          //     verticalPosition: 'top',
          //     panelClass: 'custom-snackbar-warning',
          //   }
          // );

          this.caseId = response.data.Case_Record_Id;
          this.opportunityName = storedOpportunityName;
          // this.submitted = true; // Set form to read-only once the case is created

          // Patch the form with data from the response
          this.patchFormIfNeeded();

          // Store case data in sessionStorage
          const currentTime = new Date().getTime();
          const caseData = {
            caseId: this.caseId,
            timestamp: currentTime,
            opportunityName: this.opportunityName,
            userId: userId,
          };
          sessionStorage.setItem('caseData', JSON.stringify(caseData));
          return;
        }

        this.caseId = response.data.Case_Record_Id;
        this.opportunityName = storedOpportunityName;

        if (!this.caseId) {
          console.error('Case ID is missing in the API response.');
          this.snackbar.open(
            'Case creation failed. Please try again.',
            'Close',
            {
              duration: 3000,
              verticalPosition: 'top',
              panelClass: 'custom-snackbar-danger',
            }
          );
          return;
        }

        // Store the case data in sessionStorage
        const currentTime = new Date().getTime();
        const caseData = {
          caseId: this.caseId,
          timestamp: currentTime,
          opportunityName: this.opportunityName,
          userId: userId,
        };

        sessionStorage.setItem('caseData', JSON.stringify(caseData));
        // console.log(
        //   'New case created and stored in sessionStorage:',
        //   this.caseId
        // );
      },
      (error) => {
        console.error('Failed to create case:', error);
        this.snackbar.open(
          'Unable to create case. Please try again later.',
          'Close',
          {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: 'custom-snackbar-danger',
          }
        );
      }
    );
  }

  clickCount: number = 0;
  maxClicks: number = 3;
  isDisabled: boolean = false;

  onSubmit() {
    const userId = localStorage.getItem('userId');
    // console.log('User ID in onSubmit:', userId);

    if (
      this.refundForm.valid &&
      this.caseId &&
      this.opportunityName &&
      userId
    ) {
      this.isFreshCase = false;
      // Success case: Disable form and store data
      this.submitted = true;
      this.isLoading = true;

      setTimeout(() => {
        this.isLoading = false;
      }, 100);

      // Save form data to sessionStorage after successful submission
      sessionStorage.setItem('formData', JSON.stringify(this.refundForm.value));
      this.eventTracking('Refund Form Opened');

    }
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private snackbar: MatSnackBar,
    private dataService: DataService,
    private authService: AuthService
  ) {
    this.refundForm = this.fb.group(
      {
        aadharNumber: [
          '',
          [Validators.pattern(/^\d{12}$/)], // 12-digit Aadhar number
        ],
        bankName: [
          '',
          [
            Validators.required,
            this.bankNameValidator, // Allows only single spaces between words
          ],
        ],
        accountNumber: [
          '',
          [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(16),
            Validators.pattern(/^\d{10,16}$/), // Ensure this is present
          ],
        ], // Minimum length 10
        reAccountNumber: ['', Validators.required], // Matches account number
        ifscCode: [
          '',
          [Validators.required, Validators.pattern(/^[A-Z]{4}0[A-Z0-9]{6}$/)], // Valid IFSC code format
        ],
        beneficiaryName: [
          '',
          [Validators.required, Validators.pattern(/^[A-Za-z\s]+$/)], // Only alphabets and spaces
        ],
        panNumber: [
          '',
          [
            Validators.pattern(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/), // PAN format
          ],
        ],
      },
      {
        validators: [
          this.atLeastOneRequiredValidator,
          this.accountNumbersMatchValidator,
        ],
      }
    );
  }

  atLeastOneRequiredValidator(
    group: AbstractControl
  ): { [key: string]: boolean } | null {
    const aadhar = group.get('aadharNumber').value;
    const pan = group.get('panNumber').value;

    if (!aadhar && !pan) {
      return { atLeastOneRequired: true };
    }
    return null;
  }
  bankNameValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value || '';

    if (!value.trim()) {
      return { required: true }; // Required validation
    }

    if (/\d/.test(value)) {
      return { containsNumbers: true }; // Prevents numbers
    }

    if (/[^A-Za-z ]/.test(value)) {
      return { containsSpecialCharacters: true }; // Prevents special characters
    }

    if (!/^[A-Za-z]+( [A-Za-z]+)*$/.test(value.trim())) {
      return { invalidSpacing: true }; // Ensures only single spaces
    }

    return null; // Valid input
  }

  preventNumericInput(event: KeyboardEvent) {
    const charCode = event.key;
    if (!/^[A-Za-z\s]$/.test(charCode)) {
      event.preventDefault();
    }
  }

  accountNumbersMatchValidator(
    group: FormGroup
  ): { [key: string]: boolean } | null {
    const accountNumberControl = group.get('accountNumber');
    const reAccountNumberControl = group.get('reAccountNumber');

    const accountNumber = accountNumberControl
      ? accountNumberControl.value
      : null;
    const reAccountNumber = reAccountNumberControl
      ? reAccountNumberControl.value
      : null;

    if (accountNumber && reAccountNumber && accountNumber !== reAccountNumber) {
      return { accountMismatch: true };
    }
    return null;
  }

  onEdit() {
    this.eventTracking('Refund Edit Clicked');
    this.submitted = false;
    this.isSubmitDisabled = false;
    this.formEdited = true;
    this.refundForm.enable();
    // console.log('Form is now editable.');
  }

  errData: any;
  errPopup: boolean = false;

  isErrMessage: boolean = false;
  isCalling: boolean = false;
  onConfirm() {
    this.eventTracking('Refund Confirm Clicked');
    if (!this.submitted) {
      alert('Please submit the form before confirming.');
      return;
    }

    const alterLead_source_Name = sessionStorage.getItem('company_Name');

    // Prepare final payload for submission
    const finalRequestBody = {
      case_id: this.caseId,
      user_id: localStorage.getItem('userId'),
      opp_name: this.opportunityName,
      account_number: this.refundForm.value.accountNumber,
      account_holder_name: this.refundForm.value.beneficiaryName,
      ifsc_code: this.refundForm.value.ifscCode,
      aadhaar_number: this.refundForm.value.aadharNumber,
      pan_no: this.refundForm.value.panNumber, // Use 'panNumber' as in formControlName

      bank_name: this.refundForm.value.bankName.trim().replace(/\s+/g, ' '),
      lead_source: alterLead_source_Name,
    };

    // console.log('Final API Request Payload:', finalRequestBody);
    // console.log(localStorage.getItem('userId'));
    this.isCalling = true;

    // API call on confirmation
    this.dataService.verifyBankDetails(finalRequestBody).subscribe(
      (response) => {
        // console.log('API Response:', response);
        this.isCalling = false;

        if (response.status === 200) {
          // this.snackbar.open('Refund request submitted successfully.', '', {
          //   duration: 4000,
          //   verticalPosition: 'top',
          //   panelClass: 'custom-snackbar-success',
          // });

          // console.log(response.pan_no)

          this.submitted = false; // Prevent re-editing after confirmation
          this.isLoading = false;
          this.isSubmitDisabled = true; // Disable further actions

          document.getElementById('openConfirmModal').click();

          // Open the modal
          const confirmationModal =
            document.getElementById('confirmationModal');
          if (confirmationModal) {
            (confirmationModal as any).classList.add('show');
            (confirmationModal as any).style.display = 'block';
            document.body.classList.add('modal-open');
          }
        }
      },
      (error) => {
        this.isCalling = false;

        const confirmationModal = document.getElementById('confirmationModal');
        if (confirmationModal) {
          (confirmationModal as any).classList.add('hide');
          (confirmationModal as any).style.display = 'none';
          document.body.classList.add('modal-open');
        }

        this.errData = JSON.parse(
          decryptString(error.error.data, this.secretKey)
        );
        // console.log(this.errData);

        this.regretErrMessage = this.errData.message.msg;
        // if (this.regretErrMessage.length > 0) {
        //   console.log('called');
        //   this.submitted = false;
        // }

        sessionStorage.setItem('freeze', this.errData.message.disable);

        if (sessionStorage.getItem('freeze')) {
          this.freezeForm = sessionStorage.getItem('freeze');
        }
        sessionStorage.setItem('freeze', this.errData.message.disable);

        // this.snackbar.open('Invalid details shared', '', {
        //   duration: 4000,
        //   verticalPosition: 'top',
        //   panelClass: 'custom-snackbar-danger',
        // });

        //second popup
        // Open the modal

        // Close popup when clicking outside
        this.errPopup = true;

        this.isLoading = false;
      }
    );
  }

  hidePopup() {
    this.errPopup = false;
  }
  navigateToDashboard(): void {
    this.router.navigate(['/dashboard']);
  }

  preventAction(event: ClipboardEvent) {
    event.preventDefault();
  }
}
