declare var Razorpay: any;
import { Component, OnInit, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ShareDataService } from '../share-data.service';
import { UserIdleService } from 'angular-user-idle';
import { Subject } from 'rxjs';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-customer-dashboard',
  templateUrl: './customer-dashboard.component.html',
  styleUrls: ['./customer-dashboard.component.scss']
})
export class CustomerDashboardComponent implements OnInit {


  user_id: any;
  eventTrigger: Subject<void> = new Subject<void>();
  userActivity;
  userInactive: Subject<any> = new Subject();
  public isCollapsed = false;
  showPagination: boolean = false;
  just: any;
  userBasicInfo: any;
  userInfo: any;
  getPPName: any;
  bankAccountDetails: any;
  loanDetail: any;
  loanOffer: any;
  lastLogin: any;
  mobileNumber: any;
  selectedId: string;
  SpecificLoanDetail: any;
  isLoading: boolean;
  loanAmount: any = "Loan Amount";
  convertedLoanAmt: any;
  offerAmount: any;
  convertedOfferAmt: any;
  welcomedata: any;
  loanImage: any;
  loanName: any;
  welcomeLetterUrl: any;
  createWelcomeLetterData: any;
  userName: any;
  phone: any;
  address: any;
  principleAmt: any;
  dueMonth: any;
  dueAmt: any;
  loanAccountNo: any;
  payNowForm: FormGroup;
  display: any;
  payNowModel: any;
  paidPayableAmount: any;
  phoneNo: any;
  payNowData: any;
  payNowcustomerId: any;
  payNoworderId: any;
  billingAddress: any;
  email: any;
  loanId: any;
  payableInput: boolean = true;
  showLoader: boolean = false;
  display2 = 'none';
  displayModal: any = 'none';
  searchTerm: any;
  itemsCopy: any;
  sortByAsc: any;
  loanData: any;
  amtArea: boolean = false;
  dateArea: boolean = false;
  startDate: any;
  endDate: any;
  minAmt: any;
  maxAmt: any;
  emiAmount: any;
  name: any;
  loanNameValue: any;
  amount: any;
  totalpayableamount: any;
  paymentAmont: any;
  userBasicInfo1: any;
  userName1: any;
  presendat: any;
  sendPPName: any;
  getPPAmount: any = '';
  specificLoanAmt1: any;
  specificLoanAmt2: any;
  accountNo: any;
  channelName: any = '';
  requiredError: any;
  requiredError1: any;
  requiredError2: any;
  accountIFSC: any;
  paymentMethod: any;
  companyName: any = '';
  netPaymentC: any;
  maxAmount: any;
  amtToString: any;
  forNetBankingAmt: any;
  netBanking: any = 'Net Banking';
  createCO: any;
  customerId: any;
  orderId: any;
  fourDigitIFSC: any;
  selectedBankName: any;
  gettokendata: any;
  tokennew: any;
  nachResponse: any;
  channelPartnerName: any = '';
  Accountid: any;
  specificLoanAmt: any;
  acHolderName: any;
  newPage: any = 1;
  config = { itemsPerPage: 5, currentPage: this.newPage, totalItems: 0 };

  // disable buttton 
  paynowdisable: boolean = false; loandetailnachdisable: boolean = false
  //nach
  nachCheckData: any;
  alreadyRegisNach: boolean;
  forNachRegis: boolean;
  date: any;
  status: any;
  dueDate: any = '';
  accountType: any;
  modeofpayment: any;
  payNow: boolean = false;
  register: boolean = false;
  nachErrData: any;
  debitCard: any;
  isNetBanking: boolean = true;
  isDebit: boolean = false;
  userNameForm: any;
  deviceInfo: any = '';
  deviceType: any = '';
  customer_name: any;
  aa_callback_res: any;
  accountid: any;
  mandate_loan_amount: any;

  selectedOption: any = 'Emi_Amount';
  // isButtonDisabled:boolean = true;
  PayableAmountres: any;
  Loan_Type: any;
  Stage_Name: any;
  loan_stage: any;
  Emi_Amount: any;
  total_PreclosureAmount: any;
  total_OverdueAmount: any;
  total_PendingCharge: any;
  Display_Others: any;
  nachRejectioncharge1: any;
  Customer_Name1: any;
  lead_source: any;
  razorpayKey: any

  PaymentMethodOption = [{ name: "Net Banking" }, { name: "Debit Card" }];
  userId: any;
  netbankingDropdown: any = [
    { key: 'Andhra Bank', value: 'ANDB' },
    { key: 'Axis Bank', value: 'UTIB' },
    { key: 'Bank of Baroda', value: 'BARB_R' },
    { key: 'Bank of Maharashtra', value: 'MAHB' },
    { key: 'Canara Bank', value: 'CNRB' },
    { key: 'Central Bank of India', value: 'CBIN' },
    { key: 'City Union Bank', value: 'CIUB' },
    { key: 'Cosmos Co-operative Bank', value: 'COSB' },
    { key: 'Deutsche Bank', value: 'DEUT' },
    { key: 'Dhanalaxmi Bank', value: 'DLXB' },
    { key: 'Equitas Small Finance Bank', value: 'ESFB' },
    { key: 'Federal Bank', value: 'FDRL' },
    { key: 'HDFC Bank', value: 'HDFC' },
    { key: 'ICICI Bank', value: 'ICIC' },
    { key: 'IDBI Bank', value: 'IBKL' },
    { key: 'IDFC First Bank', value: 'IDFB' },
    { key: 'Indian Overseas Bank', value: 'IOBA' },
    { key: 'Indusind Bank', value: 'INDB' },
    { key: 'Kotak Mahindra Bank', value: 'KKBK' },
    { key: 'Karnataka Bank LTD', value: 'KARB' },
    { key: 'Oriental Bank of Commerce', value: 'ORBC' },
    { key: 'Paytm Payments Bank', value: 'PYTM' },
    { key: 'Punjab National Bank', value: 'PUNB_R' },
    { key: 'RBL Bank', value: 'RATN' },
    { key: 'Standard Charted Bank', value: 'SCBL' },
    { key: 'State Bank of India', value: 'SBIN' },
    { key: 'Tamilnadu Mercantile Bank', value: 'TMBL' },
    { key: 'Ujjivan Small Finance Bank LTD', value: 'USFB' },
    { key: 'United Bank of India', value: 'UTBI' },
    // {key:'Yes Bank',value:'YESB'},
  ];

  debitDropdown: any = [
    { key: 'Bank of Maharashtra', value: 'MAHB' },
    { key: 'Citi Bank', value: 'CITI' },
    { key: 'Deutsche Bank', value: 'DEUT' },
    { key: 'Equitas Small Finance Bank', value: 'ESFB' },
    { key: 'Federal Bank', value: 'FDRL' },
    { key: 'HDFC Bank', value: 'HDFC' },
    { key: 'ICICI Bank', value: 'ICIC' },
    { key: 'IDBI Bank', value: 'IBKL' },
    { key: 'IDFC First Bank', value: 'IDFB' },
    { key: 'Indusind Bank', value: 'INDB' },
    { key: 'Karnataka Bank LTD', value: 'KARB' },
    { key: 'Kotak Mahindra Bank', value: 'KKBK' },
    { key: 'Punjab National Bank', value: 'PUNB_R' },
    { key: 'RBL Bank', value: 'RATN' },
    { key: 'South Indian Bank', value: 'SIBL' },
    { key: 'State Bank of India', value: 'SBIN' },
    { key: 'Ujjivan Small Finance Bank LTD', value: 'USFB' },
    { key: 'United Bank of India', value: 'UTBI' },
    // {key:'Yes Bank',value:'YESB'},
  ];

  UserAccountType = [{ name: "Savings", value: 'savings' }, { name: "Current", value: 'current' }];

  constructor(
    private data: DataService,
    private http: HttpClient,
    private router: Router,
    private formBuilder: FormBuilder, private service: ShareDataService,
    private userIdle: UserIdleService, private modalService: NgbModal,
    private device: DeviceDetectorService) {
    this.setTimeout();
    this.userInactive.subscribe(() => {
      localStorage.clear();
      this.router.navigate(['customerportal&currentuser&sessiontimeout'])
    });
  }


  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 900000);
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }


  ngOnInit() {

    this.userId = sessionStorage.getItem('userID');
    this.razorpayKey = this.data.razorpayKey;
    this.deviceInfo = this.device.getDeviceInfo();
    if (this.device.isMobile() == true) { this.deviceType = 'Mobile' }
    else if (this.device.isTablet() == true) { this.deviceType = 'Tablet' }
    else if (this.device.isDesktop() == true) { this.deviceType = 'Desktop' };

    let deviceDetails = {
      'userAgent': this.deviceInfo.userAgent,
      'os': this.deviceInfo.os,
      'browser': this.deviceInfo.browser,
      'deviceType': this.deviceType,
      'os_version': this.deviceInfo.os_version,
      'browser_version': this.deviceInfo.browser_version,
    }
    const body = {
      Mobile: localStorage.getItem('dmi_phone'),
      User_Info: deviceDetails
    }
    this.data.deviceTracking(body).subscribe();


    this.paymentMethod = 'Net Banking'
    this.selectedBankName = 'Andhra Bank'
    this.fourDigitIFSC = 'ANDB';
    this.accountType = this.UserAccountType[0].name;

    if (!localStorage.getItem('dmi_phone') || localStorage.getItem('dmi_phone') == 'null' || localStorage.getItem('dmi_phone') == 'undefined' || localStorage.getItem('dmi_phone') == '') {
      localStorage.clear();
      this.router.navigate(['/']);
    }

    this.payNowForm = this.formBuilder.group({
      payableAmount: ['']
    });

    const body5 = {
      loan_number: localStorage.getItem('single_loan_name')
    }

    this.data.getChechNach(body5).subscribe(res => {
      this.nachCheckData = res;
      this.alreadyRegisNach = true;
      this.forNachRegis = false;
      this.date = this.nachCheckData.data.Current_Date;
      this.status = this.nachCheckData.data.Status__c;
      let dateCondition = this.dueDate - 3;
      if (this.status == 'SUCCESS' || this.status == 'Success' || this.status == 'success' || this.status == 'ACCEPTED' || this.status == 'Accepted') {
        this.modeofpayment = true;
        this.payNow = true;
        this.register = false;
      }
      else {
        this.modeofpayment = false;
        this.payNow = true; this.register = true;
      }
    },
      err => {
        this.nachErrData = err;
        this.forNachRegis = true;
        this.alreadyRegisNach = false;
      });
    if (this.netBanking == 'Net Banking') { this.forNetBankingAmt = '0'; }
    else { this.forNetBankingAmt = '1'; }

    if (localStorage.getItem('user_basic_info') === null) {
      const body = {
        phone: localStorage.getItem('dmi_phone')
      }
      this.data.getUserInfo(body).subscribe(
        data => {
          this.userBasicInfo = data
          let userPhoneNum: any;
          let userMobileNum: any;
          this.userName = this.userBasicInfo.data[0].Name;
          userPhoneNum = this.userBasicInfo.data[0].Phone;
          userMobileNum = this.userBasicInfo.data[0].MobilePhone;
          this.acHolderName = this.userBasicInfo.data[0].Name;
          if (userPhoneNum == null) {
            this.phoneNo = userMobileNum;
          }
          if (userMobileNum == null) {
            this.phoneNo = userPhoneNum;
          }
          if (userPhoneNum !== null && userMobileNum !== null) {
            this.phoneNo = userPhoneNum;
          }
          localStorage.setItem('user_basic_info', JSON.stringify(this.userBasicInfo))
        },
        err => { this.userBasicInfo = [] }
      );
      const body1 = {
        phone: localStorage.getItem('dmi_phone')
      }


      this.data.getUserName(body1).subscribe(
        data => {
          this.userBasicInfo1 = data;
          this.userName1 = this.userBasicInfo1.data[0].Name;
          localStorage.setItem('user_basic_name', this.userName1)
          this.userNameForm = localStorage.getItem('user_basic_name');
        },
        err => { this.userBasicInfo1 = [] }
      );
    }
    else {
      let userPhoneNum: any;
      let userMobileNum: any;
      this.userBasicInfo = JSON.parse(localStorage.getItem('user_basic_info'))
      userPhoneNum = this.userBasicInfo.data[0].Phone;
      userMobileNum = this.userBasicInfo.data[0].MobilePhone;
      this.billingAddress = this.userBasicInfo.data[0].MailingCity + ', ' + this.userBasicInfo.data[0].MailingCountry + ', ' +
        this.userBasicInfo.data[0].MailingPostalCode + ', ' + this.userBasicInfo.data[0].MailingState + ', ' + this.userBasicInfo.data[0].MailingStreet
      this.email = this.userBasicInfo.data[0].Email;
      this.userName = this.userBasicInfo.data[0].Name;
      if (userPhoneNum == null) {
        this.phoneNo = userMobileNum;
      }
      if (userMobileNum == null) {
        this.phoneNo = userPhoneNum;
      }
      if (userPhoneNum !== null && userMobileNum !== null) {
        this.phoneNo = userPhoneNum;
      }
      this.userName1 = localStorage.getItem('user_basic_name');
    }
    const body1 = {
      phone: localStorage.getItem('dmi_phone')
    }
    this.data.getLoanCount(body1).subscribe(res => {
      this.config.totalItems = res.expr0;
    });
    const body2 = {
      phone: localStorage.getItem('dmi_phone'),
      limit: this.config.itemsPerPage,
      offset: 0
    }
    this.data.getLoanCountDetails(body2).subscribe(
      res => {
        this.loanData = res;

        this.loanDetail = this.loanData.data;
        this.amount = this.loanDetail[0].Net_Payment__c;
        this.name = this.loanDetail[0].name;
        sessionStorage.setItem('oppId', this.name);
        this.accountid = this.loanDetail[0].Account_Id;
        this.mandate_loan_amount = this.loanDetail[0].Sanction_Amount__c;
        this.totalpayableamount = this.loanDetail[0].totalpayableamount;
        localStorage.setItem('single_loan_name', this.loanDetail[0].name)

        sessionStorage.setItem('company_Name', this.loanDetail[0].Pulled_Lead_Source_Name__c);
        this.lead_source = this.loanDetail[0].Pulled_Lead_Source_Name__c;
        // console.log(this.lead_source,'cheklead')
        this.eventTrigger.next();
        localStorage.setItem('name', this.loanDetail[0].name);

        localStorage.setItem('loanData', JSON.stringify(this.loanData));

        localStorage.setItem('loanDetail', JSON.stringify(this.loanData.data));
        if (res) {
          if (this.loanDetail.length > 4) { this.showPagination = true; }
          for (let i = 0; i < this.loanDetail.length; i++) {
            let msgArray = [];
            msgArray.push(this.loanDetail[i])

            const body = {
              phone: localStorage.getItem('dmi_phone'),
              nach_button_status: this.loanDetail[i].Nach_Button,
              payment_button_status: this.loanDetail[i].Payment_Button,
              loan_id: this.loanDetail[i].name,
              msg: msgArray
            }
            this.data.getNachPaymentStatus(body).subscribe(res => {
              if (res) {
                const body1 = {
                  phone: localStorage.getItem('dmi_phone')
                }
                this.data.getChannelPartner(body1).subscribe(res => {
                  this.channelName = res;
                  this.channelPartnerName = this.channelName.data;

                  console.log('this.channelPartnerName', this.channelPartnerName)

                  //Dashboard Events
                  let eventName = 'Dashboard'
                  let phone = localStorage.getItem('dmi_phone')
                  let campign_number = this.loanDetail[0].name;
                  let source = 'DMI-Customer-Portal'
                  const body = {
                    phone: phone,
                    eventname: eventName,
                    campign_number: campign_number,
                    source: source,
                    partner: this.loanDetail[0].Pulled_Lead_Source_Name__c

                  }
                  this.data.getEventName(body).subscribe(res => {

                  });
                });
              }
            });
          }
        }
      },
      err => { this.loanDetail = [] }
    );
    const body3 = {
      phone: localStorage.getItem('dmi_phone')
    }
    this.data.getLoanOffer(body3).subscribe(
      data => {
        this.loanOffer = data
        localStorage.setItem('loan_offer', JSON.stringify(this.loanOffer.data));
      },
      err => { this.loanOffer = [] }
    );
    this.lastLogin = localStorage.getItem('last_login');

    this.checkAA();

  }


  showModal1: boolean = false;
  aa_required: any;
  checkAA() {
    const body = { user_id: this.userId, mobile: localStorage.getItem('dmi_phone') }
    this.data.check_AA(body).subscribe(res => {
      this.service.setAA(res.data.AA_Required);
      if (res.data.AA_Required == 'Yes' || res.data.AA_Required == 'yes') {
        this.eventTracking('AA Banner Show');
        this.showModal1 = true;
      }
      else {
        this.eventTracking('AA Banner Not Show');
        this.showModal1 = false;
      }
    }, (error) => {
      this.showModal1 = false;
    });
  }

  stop() {
    this.userIdle.stopTimer();
  }

  selectedBank(event) {
    this.selectedBankName = event;
    for (let i = 0; i < this.netbankingDropdown.length; i++) {
      if (this.selectedBankName == this.netbankingDropdown[i].key) {
        this.fourDigitIFSC = this.netbankingDropdown[i].value;
      }
    }
  }

  closeModal() {
    this.displayModal = 'none';
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  selectedAccountType(event) {
    this.accountType = event;
  }

  closePayModal() {
    this.display2 = 'none';
  }


  // Pay now RZP model open
  RzploanName: any;
  RzploanId: any;
  OtherAmount: any;
  Otherinput: boolean = false;
  rzp_Amount: any;
  Stage_Name1: any;
  Amountchecked: any;
  payNowModelData: any[] = []

  Nachrzpopen(loan: any) {

    this.Otherinput = false;
    this.paynowdisable = false
    // Start event tracking
    let eventName = 'Open_Popup_for_Pay_Loan_EMI_dashboard_page'
    this.phone = localStorage.getItem('dmi_phone');
    let campign_number = loan.name;
    let source = 'DMI-Customer-Portal';
    let method = 'Payment';
    this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
    const body1 = {
      phone: this.phone,
      eventname: eventName,
      campign_number: campign_number,
      source: source,
      partner: sessionStorage.getItem('company_Name')

    }
    this.data.getEventName(body1).subscribe();
    this.RzploanName = loan.name;
    this.RzploanId = loan.Id;


    const payblebody = {
      loan_number: this.RzploanName,
      user_id: this.userId,
      lead_source: sessionStorage.getItem('company_Name')
    }
    this.data.getPaybleAmount(payblebody).subscribe(res => {
      document.getElementById('paymentlink').click();
      this.PayableAmountres = res.data;
      const data = this.PayableAmountres.details;
      const emiItem = data.find((item: any) => item.label === 'EMI Amount');
      this.rzp_Amount = emiItem ? emiItem.value : 'Not available';
      // const filteredData = data.filter((item: any) => item.value !== "0.00");
      this.payNowModelData = this.filterAmounts(this.PayableAmountres.loanStage, data);

    })
  }



  filterAmounts(loanStage: string, amounts: any[]): any[] {
    return amounts.filter(item => {
      switch (item.label) {
        case 'EMI Amount':
          return (loanStage === 'Current' || loanStage === 'Delinquent');
        case 'Total Overdue Amount':
        case 'Late Payment Fees':
          return (loanStage === 'Current' || loanStage === 'Delinquent' ||
            loanStage === 'Written-Off' || loanStage === 'Matured' ||
            loanStage === 'Written Off');
        case 'Preclosure Amount':
          return (loanStage === 'Current' || loanStage === 'Delinquent');
        default:
          return true;
      }
    });
  }


  ///Number Validation
  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  NachAmountdata(event: any) {

    this.rzp_Amount = event.value;

    if (event.label == 'Others') {
      this.Otherinput = true;
    }
    else {
      this.Otherinput = false;
    }
  }

  proceedRzp() {

    const body = {
      phone: this.phone,
      name: this.userName,
      loanname: this.RzploanName,
      amount: this.rzp_Amount
    }

    this.data.getPayNow(body).subscribe(res => {
      this.paynowdisable = false;
      this.payNowData = res;

      if (this.payNowData.data.orderid.error || this.payNowData.data.customerid.error) {
        console.log('error')
      }
      else {

        this.payNowcustomerId = this.payNowData.data.customerid.id;
        this.payNoworderId = this.payNowData.data.orderid.id;
        this.email = this.userBasicInfo.data[0].Email;
        var payNowOptions = {
          "key": this.razorpayKey,
          "name": this.RzploanName,
          "description": "Pay your emi",
          "image": "https://los.dmifinance.in/assets/images/logo.svg",
          "order_id": this.payNoworderId,
          "handler": (res) => {
            this.display2 = 'block';
            const body = {
              phone: this.phone,
              loan_number: this.RzploanName,
              amount: this.rzp_Amount,
              source: 'DMI-Customer-Portal',
              method: 'Payment',
              customer_id: this.payNowcustomerId,
              order_id: this.payNoworderId,
              name: this.userName,
              email: this.email
            }


            this.data.getpaymentRecords(body).subscribe(res => {
            })
          },
          "prefill": {
            "email": this.email,
            "contact": this.phone
          },
          "notes": {
            "opportunityName": this.RzploanName,
            "senderId": "DMI",
            "opportunityID ": this.RzploanId,
            "billingAddress": this.billingAddress,
            "customerName": this.userName1
          },
          "theme": {
            "color": "#F37254"
          }
        };

        let eventName = 'Razorpay_Popup_Open_Dashboard'

        const razorpayBody = {
          phone: this.phone,
          eventname: eventName,
          campign_number: localStorage.getItem('single_loan_name'),
          source: 'DMI-Customer-Portal',
          partner: sessionStorage.getItem('company_Name'),
          remark_1: payNowOptions

        }

        if (this.RzploanName) {
          this.data.getEventName(razorpayBody).subscribe();

          var rzp1 = new Razorpay(payNowOptions);
          rzp1.open();
        }
        else {

        }
      }

    })

  }



  openNACHmodel() {
    this.display = 'block';
    this.accountNo = null;
    this.accountIFSC = null;
    this.acHolderName = null;
  }

  Preclosureshow: any;

  loandetail(loan) {
    let loanname = loan.name;
    localStorage.setItem('single_loan_name', loan.name)
    this.lead_source = loan.Pulled_Lead_Source_Name__c,
      sessionStorage.setItem('company_Name', this.lead_source)
    this.loanNameValue = loanname;
    this.service.setName(this.loanNameValue);
    // event Tracking
    let eventName = 'View Loan Details'
    let phone = localStorage.getItem('dmi_phone')
    let campign_number = loanname
    let source = 'DMI-Customer-Portal';
    //Prepayment Date range api call start
    const preclosuredata = {
      user_id: sessionStorage.getItem('userID'),
      stage_name: loan.StageName,
      leadsource: loan.Pulled_Lead_Source_Name__c
    }

    const body = {
      phone: phone,
      eventname: eventName,
      campign_number: campign_number,
      source: source,
      partner: loan.Pulled_Lead_Source_Name__c,

    }
    this.data.getEventName(body).subscribe(res => {
    });
    this.selectedId = loan.Id;
    localStorage.setItem('specefic_loan_id', loan.Id);
    localStorage.removeItem('specific_loan_detail')
    this.data.Check_date_preclosure(preclosuredata).subscribe(res => {
      this.Preclosureshow = res.data;
      localStorage.setItem('Preclosureshow', this.Preclosureshow.button);
      localStorage.setItem('preClosurePopup', this.Preclosureshow.popup);
      this.router.navigate(['/loanDetail']);
    })
    //prepayment date range api call end 

  }

  removeErr() {
    this.requiredError = '';
    this.requiredError1 = '';
    this.requiredError2 = '';
  }

  selectedMethod(event) {
    this.netBanking = event;
    this.debitCard = event
    if (event == "Net Banking") {
      this.forNetBankingAmt = '0';
      this.paymentMethod = "Net Banking"
      this.selectedBankName = 'Andhra Bank'
      this.fourDigitIFSC = 'ANDB'
      this.isNetBanking = true;
      this.isDebit = false;
    } if (event == "Debit Card") {
      this.forNetBankingAmt = '1';
      this.paymentMethod = "Debit Card"
      this.selectedBankName = 'Bank of Maharashtra'
      this.fourDigitIFSC = 'MAHB'
      this.isDebit = true;
      this.isNetBanking = false;
    }
  }
  Account_ID: any;
  loandetailnach(loan, firstModal) {
    this.loandetailnachdisable = true
    this.modalService.open(firstModal);
    let loanname = loan.name;
    this.getPPName = loan.parent_opp;
    localStorage.setItem('single_loan_name', loan.name)
    this.loanNameValue = loanname;
    this.getPPAmount = loan.pp_amount;
    this.specificLoanAmt1 = loan.Original_Face__c;
    this.companyName = loan.Pulled_Lead_Source_Name__c;
    this.netPaymentC = loan.Net_Payment__c;
    this.Accountid = loan.Accountid;
    this.Account_ID = loan.Account_Id
    this.specificLoanAmt = loan.Original_Face__c;
    // console.log(loan,'dndkjn')
    this.loanName = loan.name;
    this.userNameForm = loan.Group_Label__c;
    this.convertedLoanAmt = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(this.specificLoanAmt);
    this.service.setName(this.loanNameValue);
    this.dueDate = localStorage.getItem('dueMonth');
    if (this.companyName == 'ZestMoney' || this.companyName == 'Redcarpet' || this.companyName == 'Slicepay') {
      this.register = false;
    }
    if (this.companyName == 'Samsung') { this.loanAmount = 'EMI Amount'; this.specificLoanAmt = this.SpecificLoanDetail.data[0].Net_Payment__c; }

    this.service.setLoanId(this.loanId);
    this.service.setLoanName(this.loanName);

    this.principleAmt = this.specificLoanAmt;

    // event Tracking
    let eventName = 'Dashboard Button - Register for Auto-Debit'
    let phone = localStorage.getItem('dmi_phone')
    let campign_number = loanname
    let source = 'DMI-Customer-Portal';
    this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
    const body = {
      phone: localStorage.getItem('dmi_phone'),
      eventname: eventName,
      campign_number: campign_number,
      source: source,
      partner: loan.Pulled_Lead_Source_Name__c

    }
    this.data.getEventName(body).subscribe(res => {
      this.loandetailnachdisable = false
    })
    this.selectedId = loan.Id;
    localStorage.setItem('specefic_loan_id', loan.Id);
    localStorage.removeItem('specific_loan_detail')
  }


  // Emandate Function
  EmandateOpen() {

    const emandatebody =
    {
      user_id: this.userId,
      opp_name: this.loanName,
      account_id: this.Account_ID,
      loan_amount: this.specificLoanAmt,
      borrower_name: this.userName1,
      mobile_number: localStorage.getItem('dmi_phone')

    }
    this.data.emandateapply(emandatebody).subscribe(res => {

      this.eventTracking("E-Mandate Key Generation Success");
      window.open(res['data'], '_blank')

    }, (error) => {
      this.loandetailnachdisable = false;
    })
  }
  //Mandate End

  nachRegistration(cancelEvent, successModal) {
    // Start event tracking
    // this.loanName=localStorage.getItem('single_loan_name'),
    this.email = this.userBasicInfo.data[0].Email;
    this.displayModal = 'none';
    let eventName = 'Dashboard - NACH Registration';
    let phone = localStorage.getItem('dmi_phone');
    let campign_number = this.loanName;
    let source = 'DMI-Customer-Portal';
    this.presendat = '2020-01-05';

    if (this.getPPName == 'null') { this.sendPPName = this.loanName }
    else if (this.getPPName != 'null') { this.sendPPName = this.getPPName; }

    if (this.getPPAmount == 'null') {
      this.specificLoanAmt2 = this.specificLoanAmt1;
    }
    else if (this.getPPAmount != 'null') {
      this.specificLoanAmt2 = this.getPPAmount;
    }

    this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
    if (this.channelName != '') {

      const body = {
        phone: localStorage.getItem('dmi_phone'),
        eventname: eventName,
        campign_number: campign_number,
        source: source,
        partner: sessionStorage.getItem('company_Name')

      }
      this.data.getEventName(body).subscribe(res => {

      })
    }

    // end
    if (this.accountNo === null || this.accountNo == "") {
      this.requiredError = 'Account Number Required.'
      return;
    } else if (this.accountIFSC === null || this.accountIFSC == "") {
      this.requiredError1 = 'IFSC Code Required.'
      return;
    } else if (this.userName1 === null || this.userName1 == "") {
      this.requiredError2 = 'Account Holder Name Required.'
      return;
    }
    else {
      if (this.paymentMethod == 'Net Banking') {
        this.paymentMethod = "Net"
      }
      if (this.paymentMethod == 'Debit Card') {
        this.paymentMethod = "Debit"
      }
      if (this.companyName == 'Samsung') {
        this.loanAmount = 'EMI Amount'
        if (this.netPaymentC >= '100000') {
          this.maxAmount = "9999900";
          this.amtToString = this.maxAmount.toString();
        }
        else {
          this.maxAmount = this.netPaymentC * 100;
          this.amtToString = this.maxAmount.toString();
        }
      }
      else {
        if (this.specificLoanAmt2 >= '100000') {
          this.maxAmount = "9999900";
          this.amtToString = this.maxAmount.toString();
        }
        else {

          this.maxAmount = this.specificLoanAmt2 * 100;
          this.amtToString = this.maxAmount.toString();
        }
      }

      // this.fourDigitIFSC = this.accountIFSC.substring(0, 4)

      let account_type: any;
      for (let i = 0; i < this.UserAccountType.length; i++) {
        if (this.accountType == this.UserAccountType[i].name) {
          account_type = this.UserAccountType[i].value;
        }
      }

      if (this.accountIFSC.length < 11) {
        alert('The ifsc code must be 11 characters.')
        return;
      }
      const body =
      {
        phone: localStorage.getItem('dmi_phone'),
        email: this.email,
        name: this.userName1,
        loanamount: this.forNetBankingAmt,
        methodcheck: this.paymentMethod,
        loanname: this.loanName,
        account: this.accountNo,
        account_type: account_type,
        ifsc: this.accountIFSC
      }

      this.data.getCreateCo(body).subscribe(res => {
        this.createCO = res;
        this.customerId = this.createCO.data.customerid.id;
        localStorage.setItem('customerId', this.customerId)
        this.orderId = this.createCO.data.orderid.id;

        if (this.paymentMethod == "Net") {
          var options = {
            "key": this.razorpayKey,
            "amount": "0",
            "name": this.userName,
            "order_id": this.orderId,
            "customer_id": this.customerId,
            "currency": "INR",
            "receipt": this.sendPPName,
            "description": "",
            "auth_type": "netbanking",
            "recurring": "1",
            "recurring_token.max_amount": this.amtToString,
            "recurring_token.expire_by": "31-12-2035",
            "prefill": {
              "name": this.userName1,
              "email": this.email,
              "contact": this.phoneNo,
              "bank": this.fourDigitIFSC,
              "method": "emandate",
              "bank_account[name]": this.userName1,
              "bank_account[account_number]": this.accountNo,
              "bank_account[ifsc]": this.accountIFSC,
            },
            "notes": {
              "address": "cp",
              "PartnerName": this.companyName,
              "BankName": this.selectedBankName,
              "OppName": this.sendPPName

            },
            "handler": (response) => {
              const body = {
                payment_id: response.razorpay_payment_id
              }
              this.data.getTokenData(
                body
              ).subscribe(res => {
                this.gettokendata = res
                this.tokennew = this.gettokendata.data;
                const body = {
                  token: localStorage.getItem('dmi_token'),
                  url: localStorage.getItem('dmi_instance_url'),
                  mobile: localStorage.getItem('dmi_phone'),
                  loanname: this.sendPPName,
                  email: this.email,
                  account: this.accountNo,
                  ifsc: this.accountIFSC,
                  bankformifsc: this.fourDigitIFSC,
                  selectoption: this.paymentMethod,
                  loanamount: this.specificLoanAmt2,
                  name: this.userName,
                  customer_id: this.customerId,
                  order_id: response.razorpay_order_id,
                  signature: response.razorpay_signature,
                  payment_id: response.razorpay_payment_id,
                  account_id: this.Accountid,
                  parntername: this.companyName,
                  presentationdate: this.presendat,
                  rztoken: this.tokennew,
                  account_type: account_type
                }
                this.data.getNachRegistration(body).subscribe(res => {
                  this.nachResponse = res;
                  this.closeModal();
                  let sourcename = 'DMI-Customer-Portal'
                  const body = {
                    phone: localStorage.getItem('dmi_phone'),
                    loan_number: this.loanName,
                    payment_id: response.razorpay_payment_id,
                    method: this.paymentMethod,
                    source: sourcename,
                    ppname: this.sendPPName
                  }

                  this.data.saveNACHrecords(body).subscribe(res => {
                  })
                  let eventName = "NACH Registration-Success"
                  this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
                  if (this.channelName != '') {
                    const body = {
                      phone: localStorage.getItem('dmi_phone'),
                      eventname: eventName,
                      campign_number: campign_number,
                      source: source,
                      partner: this.channelPartnerName

                    }
                    this.data.getEventName(body).subscribe(res => {
                    })
                  }
                  // this.display1 = 'block';
                  this.modalService.dismissAll();
                  this.modalService.open(successModal);
                  //alert('NACH Registration Successfully.');
                })
              })
            },
            "modal": {
              "ondismiss": () => {
                this.modalService.dismissAll();
                this.modalService.open(cancelEvent);
                let eventName = "NACH Registration-Fail"
                this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
                if (this.channelName != '') {
                  const body = {
                    phone: localStorage.getItem('dmi_phone'),
                    eventname: eventName,
                    campign_number: campign_number,
                    source: source,
                    partner: this.channelPartnerName

                  }
                  this.data.getEventName(body).subscribe(res => {
                  })
                }
                // window.location.replace("https://dmi.vistaconnect.com/eMandate/?key=" + localStorage.getItem('loginKey'));
              }
            },

            "theme": { "color": "#F37254" }
          };


        } else {
          options = {

            "key": this.razorpayKey,
            "amount": "1",
            "name": this.userName1,
            "order_id": this.orderId,
            "customer_id": this.customerId,
            "currency": "INR",
            "receipt": this.sendPPName,
            "description": "",
            "auth_type": "",
            "recurring": "1",
            "recurring_token.max_amount": " ",
            "recurring_token.expire_by": "31-12-2035",
            "prefill": {
              "name": this.userName,
              "email": this.email,
              "contact": this.phoneNo,
              "bank": " ",
              "method": "",
              "bank_account[name]": this.userName1,
              "bank_account[account_number]": this.accountNo,
              "bank_account[ifsc]": this.accountIFSC,
            },
            "notes": {
              "address": "cp",
              "PartnerName": this.companyName,
              "BankName": this.selectedBankName,
              "OppName": this.sendPPName

            },
            "handler": (response) => {
              const body = {
                payment_id: response.razorpay_payment_id
              }
              this.data.getTokenData(
                body
              ).subscribe(res => {
                this.gettokendata = res;
                this.tokennew = this.gettokendata.data;
                let accnumdebit = "null";
                let ifscdebit = "null";
                const body = {
                  token: localStorage.getItem('dmi_token'),
                  url: localStorage.getItem('dmi_instance_url'),
                  mobile: localStorage.getItem('dmi_phone'),
                  loanname: this.sendPPName,
                  email: this.email,
                  account: accnumdebit,
                  ifsc: ifscdebit,
                  bankformifsc: this.fourDigitIFSC,
                  selectoption: this.paymentMethod,
                  loanamount: this.specificLoanAmt2,
                  name: this.userName,
                  customer_id: this.customerId,
                  order_id: response.razorpay_order_id,
                  signature: response.razorpay_signature,
                  payment_id: response.razorpay_payment_id,
                  account_id: this.Accountid,
                  parntername: this.companyName,
                  presentationdate: this.presendat,
                  rztoken: this.tokennew,
                  account_type: account_type
                }
                this.data.getNachRegistration(body).subscribe(res => {
                  this.nachResponse = res
                  this.closeModal();
                  let sourcename = 'DMI-Customer-Portal'

                  const body = {
                    phone: localStorage.getItem('dmi_phone'),
                    loan_number: this.loanName,
                    payment_id: response.razorpay_payment_id,
                    method: this.paymentMethod,
                    source: sourcename,
                    ppname: this.sendPPName
                  }

                  this.data.saveNACHrecords(body).subscribe(res => {
                  })

                  let eventName = "NACH Registration-Success"
                  this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
                  if (this.channelName != '') {
                    const body = {
                      phone: localStorage.getItem('dmi_phone'),
                      eventname: eventName,
                      campign_number: campign_number,
                      source: source,
                      partner: this.channelPartnerName

                    }
                    this.data.getEventName(body).subscribe(res => {
                    })
                  }
                  // this.display1 = 'block';
                  this.modalService.dismissAll();
                  this.modalService.open(successModal);
                  // alert('NACH Registration Successfully.');
                })
              })
            },
            "modal": {
              "ondismiss": () => {
                this.modalService.dismissAll();
                this.modalService.open(cancelEvent);
                let eventName = "NACH Registration-Fail";
                this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
                if (this.channelName != '') {
                  const body = {
                    phone: localStorage.getItem('dmi_phone'),
                    eventname: eventName,
                    campign_number: campign_number,
                    source: source,
                    partner: this.channelPartnerName

                  }
                  this.data.getEventName(body).subscribe(res => {
                  })
                }
                // window.location.replace("https://dmi.vistaconnect.com/eMandate/?key=" + localStorage.getItem('loginKey'));
              }
            },

            "theme": { "color": "#F37254" }
          }
        }

        let eventName = 'Razorpay_Popup_Open_Dashboard'

        const razorpayBody = {
          phone: this.phone,
          eventname: eventName,
          campign_number: localStorage.getItem('single_loan_name'),
          source: 'DMI-Customer-Portal',
          partner: sessionStorage.getItem('company_Name'),
          remark_1: options

        }

        if (this.sendPPName) {
          this.data.getEventName(razorpayBody).subscribe();

          var rzp1 = new Razorpay(options);

          rzp1.open();

          if (rzp1.open()) {

            this.display = 'none';
          }
        }
        else {

        }

      })
    }

    this.modalService.dismissAll();
  }
  RegisterClosemodel() {
    this.loandetailnachdisable = false;
  }
  closeModalDialog() {
    this.display = 'none';
    this.payNowModel = 'none';
    this.payNowForm.reset();
  }

  openModal() {
    this.modalService.dismissAll();
  }

  payableAmt() {
    this.payableInput = true;
    // this.otherInput = false;
  }

  pageChange(newP: number) {
    let lastindex: number;
    this.newPage = newP;
    this.config.currentPage = this.newPage;
    if (newP == 1) { lastindex = 0; }
    else { lastindex = 5 * newP - 5; }
    this.loanDetail = [];
    this.showLoader = true;
    localStorage.removeItem('loanData');
    localStorage.removeItem('loanDetail');
    const body2 = {
      phone: localStorage.getItem('dmi_phone'),
      limit: this.config.itemsPerPage,
      offset: lastindex
    }
    this.data.getLoanCountDetails(body2).subscribe(res => {
      this.loanData = res;
      this.showLoader = false;
      this.loanDetail = this.loanData.data;
      this.amount = this.loanDetail[0].Net_Payment__c;
      this.name = this.loanDetail[0].name;
      this.totalpayableamount = this.loanDetail[0].totalpayableamount;
      localStorage.setItem('loanData', JSON.stringify(this.loanData));
      localStorage.setItem('loanDetail', JSON.stringify(this.loanData.data));
      if (res) {
        if (this.loanDetail.length > 4) { this.showPagination = true; }
        for (let i = 0; i < this.loanDetail.length; i++) {
          let msgArray = [];
          msgArray.push(this.loanDetail[i])
          const body = {
            phone: localStorage.getItem('dmi_phone'),
            nach_button_status: this.loanDetail[i].Nach_Button,
            payment_button_status: this.loanDetail[i].Payment_Button,
            loan_id: this.loanDetail[i].name,
            msg: msgArray
          }
          this.data.getNachPaymentStatus(body).subscribe(res => { });
        }
      }
    });
  }

  AAwindow!: Window | null;
  checkDmiAAWindowInterval: any;
  get payFormData() { return this.payNowForm.controls; }

  isActive: boolean
  routerLinkActiveOptions: {
    exact: boolean;
  }
  closeModal1(): void {
    this.eventTracking('AA Banner Closed Forcefully')
    this.showModal1 = false;
  }

  //  AA_Stack_Start

  isOverlay: boolean = false;

  aa_proceed() {
    this.isOverlay = true;
    this.forcefullyclosed = false;
    const body = { user_id: this.userId, mobile: localStorage.getItem('dmi_phone'), customer_name: this.userName1 }
    this.data.AASatellite(body).subscribe(res => {
      if (res.status == 200) {
        const width = screen.width * 0.62 < 400 ? 400 : screen.width * 0.62;
        const height = screen.height * 0.62;
        const left = (screen.width / 2) - (width / 2);
        const top = (screen.height / 2) - (height / 2);
        this.AAwindow = window.open(res.data, '_blank', ',scrollbars=1,menubar=0,resizable=1,width = ' + width + ', height = ' + height + ', top = ' + top + ', left = ' + left);
        this.checkDmiAAWindowInterval = setInterval(() => {
          this.checkIfDmiAAClosed();
        }, 300);
        this.eventTracking('AA Stack Window Open');

        this.CheckAAstatus()
      }
    }, (error) => {
      this.isOverlay = false;
    });
  }
  forcefullyclosed: boolean = false;
  checkIfDmiAAClosed() {
    if (this.AAwindow.closed) {
      this.isOverlay = false;
      this.forcefullyclosed = true;
      this.eventTracking('AA Stack Window Closed');
      clearInterval(this.checkDmiAAWindowInterval);
    }
  }

  eventTracking(Event_Name) {
    const body = {
      phone: localStorage.getItem('dmi_phone'),
      eventname: Event_Name,
      campign_number: localStorage.getItem('single_loan_name'),
      source: 'DMI-Customer-Portal',
      partner: sessionStorage.getItem('company_Name'),

    }
    this.data.getEventName(body).subscribe();

  }
  count: number = 0;
  CheckAAstatus() {
    const body = { user_id: this.userId, Mobile: localStorage.getItem('dmi_phone') }
    this.data.AA_Callback(body).subscribe(res => {
      this.aa_callback_res = res.data;
      if (this.forcefullyclosed == true) {
        this.count = 0;
        return;
      }

      if (this.aa_callback_res.AA_Status == 'Success' || this.aa_callback_res.AA_Status == 'success') {
        this.eventTracking('AA Status ' + this.aa_callback_res.AA_Status);
        this.AAwindow!.close();
        clearInterval(this.checkDmiAAWindowInterval);
        this.showModal1 = false;
        this.isOverlay = false;
        this.router.navigate(['/loanOffer'])
      }
      else if (this.aa_callback_res.AA_Status == 'Failed' || this.aa_callback_res.AA_Status == 'Failure' || this.aa_callback_res.AA_Status == 'Exit' || this.aa_callback_res.AA_Status == 'Denied') {
        this.eventTracking('AA Status ' + this.aa_callback_res.AA_Status);
        this.AAwindow!.close();
        clearInterval(this.checkDmiAAWindowInterval);
        this.isOverlay = false;
      }
      else {
        this.count += 1;
        if (this.count <= 100) {
          setTimeout(() => {
            this.CheckAAstatus();
          }, 4000);
        }
        else {
          this.count = 0;
          this.AAwindow!.close();
          this.isOverlay = false;
          this.showModal1 = false;
          this.eventTracking('AA Callback Time Out');
          clearInterval(this.checkDmiAAWindowInterval);
          this.router.navigate(['']);
        }
        return;
      }
    });
  }
  ngOnDestroy() {
    this.modalService.dismissAll();
    clearTimeout(this.userActivity);
  }


}


