import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../data.service';

@Injectable()
export class AuthService {
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private allowedUrls: Set<string> = new Set(); // Track allowed URLs

//  allowedURLS ensures users can only navigate to pages they have permission for.

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(private router: Router, private http: HttpClient, private data: DataService) {


    this.loadAllowedUrls(); //  Load allowed URLs from localStorage on init
  }
  // Date: 01-03-2025
  // Time: 19:00 IST
  // Developer: Naman Jain
  // Description : This function checks user do not loose access to their permiited routes

  private loadAllowedUrls() {
    const storedUrls = localStorage.getItem('allowed_urls');
    if (storedUrls) {
      this.allowedUrls = new Set(JSON.parse(storedUrls));
    }

    // If storedUrls exists nsures users do not lose access to their permitted routes after a page reload.

  }

  allowNavigation(url: string) {
    this.allowedUrls.add(url);
    localStorage.setItem('allowed_urls', JSON.stringify(Array.from(this.allowedUrls))); // spread operator doesn't work
  }

  isNavigationAllowed(url: string): boolean {
    return this.allowedUrls.has(url);
  }

//   allowNavigation(url) → Saves allowed URLs in localStorage.
// 🔄 loadAllowedUrls() → Retrieves allowed URLs on app startup.
// 🚀 isNavigationAllowed(url) → Checks if a user can access a page.


  logout() {
    this.loggedIn.next(false);
    this.allowedUrls.clear();
    localStorage.removeItem('allowed_urls'); //  Clear storage on logout
    this.router.navigate(['/']);
  }
}



    // login(user: User) {
    //   if (user.phone !== '' && user.password !== '' ) {
    //     this.data.getUserLogin(user.phone,user.password).subscribe(res=>{
    //       this.loginData = res;
    //       // localStorage.setItem('phone', this.loginData.data[0].mobile)
    //       // localStorage.setItem('id', this.loginData.data[0].id)  
    //       this.loggedIn.next(true);
    //       this.router.navigate(['/dashboard']);
    //     },
    //     err=>{
    //       alert("Invalid login credentials.");
    //     })
    //   }
    // }
  
    // otplogin(user: User) {
    //   if (user.otp !== '') {
    //     this.data.verifyOtp(user.otp).subscribe(res=>{
    //       this.loginData = res;
    //       // localStorage.setItem('phone', this.loginData.data[0].mobile)
    //       // localStorage.setItem('id', this.loginData.data[0].id)
    //       this.loggedIn.next(true);
    //       // this.router.navigate(['/dashboard']);
    //     },
    //     err=>{
    //       alert('Please enter a valid OTP');
    //       return;
    //     })
    //   }
    // }
