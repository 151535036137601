import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/data.service';
import { ShareDataService } from 'src/app/share-data.service';
import Swal from 'sweetalert2';
import { ExperianModalComponent } from 'src/app/modal/experian-modal/experian-modal.component';

@Component({
  selector: 'app-experian-history',
  templateUrl: './experian-history.component.html',
  styleUrls: ['./experian-history.component.scss']
})
export class ExperianHistoryComponent implements OnInit {
  creditScoreData: any = [];
  getCreditScore: any;
  isDisabled: boolean;
  progressBarDynamicValue: any;
  campign_number: any;
  eventName: any;
  channelPartnerName: any = null;
  daysBetween: any;
  leftDays: any;

  constructor(private data: DataService, private router: Router,
    private modalService: NgbModal, public service: ShareDataService) { }

  ngOnInit() {
    // "2020-11-20 23:58:21"
    this.eventTracking('Monthly-Experian-Reports-Modal-Open')
    this.creditScoreData = JSON.parse(localStorage.getItem('Credit_Score_Data')) || [];
    let startDate = new Date(this.creditScoreData[0].created_date);
    let lastDate = new Date();
    this.days_between(startDate, lastDate);

    this.campign_number = localStorage.getItem('single_loan_name')
  }

  days_between(startDate, lastDate) {
    const ONE_DAY = 1000 * 60 * 60 * 24;
    const differenceMs = Math.abs(lastDate - startDate);
    this.daysBetween = Math.round(differenceMs / ONE_DAY);
    let allDays = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0).getDate();
    this.leftDays = allDays - this.daysBetween;
  }

  openExperianForm() {
    this.eventTracking('Generate-Current-Report-Click');
    let modalObj: NgbModalOptions = {
      backdrop: 'static',
      centered: true,
      windowClass: 'myCustomModalClass',
      keyboard: false,
    }
    const modal = this.modalService.open(ExperianModalComponent, modalObj);
  }

  viewMoreCreditScore(multibureauId) {
    this.isDisabled = true;
    this.eventTracking('View-More-Details')
    const body={
      multibureauId:multibureauId
    }
    this.data.getExperianDataByMBID(body).subscribe(res => {
      this.getCreditScore = res;
      this.isDisabled = false;
      this.eventTracking('Get-Experian-Data-MBID-Success');
      if (this.getCreditScore.data.SCORE !== null) {
        let jsonResponse = { score: this.getCreditScore.data.SCORE.BureauScore, account: this.getCreditScore.data.ACCOUNT.CAIS_Summary.Credit_Account }
        sessionStorage.setItem('CRValue', JSON.stringify(jsonResponse));
        sessionStorage.setItem('refno', this.getCreditScore.data.referncenumber);
        this.router.navigate(["/creditScore"]);
      } else {
        Swal.fire({
          title: 'Alert',
          text: 'No More Record found!',
          type: 'warning',
          confirmButtonText: 'OK'
        });
      }
    },error=>{
      this.eventTracking('Get-Experian-Data-MBID-Error');
    });
  }

  eventTracking(eventName) {
    let phone = localStorage.getItem('dmi_phone');
    let campign_number = localStorage.getItem('single_loan_name');
    const body1={
      phone:phone,
       eventname:this.eventName, 
       campign_number:this.campign_number, 
       source:'DMI-Customer-Portal', 
       partner:sessionStorage.getItem('company_Name')

    }
    this.data.getEventName(body1).subscribe();
  }

  masterValue(value) {
    this.progressBarDynamicValue = ((value / 900) * 100).toFixed(2);
  }

}
