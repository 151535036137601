import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, } from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from '../../app/data.service';
import { environment } from 'src/environments/environment';
import { decryptString } from '../../Utils/encryption';

@Component({
  selector: 'app-get-passkey',
  templateUrl: './get-passkey.component.html',
  styleUrls: ['./get-passkey.component.scss']
})
export class GetPasskeyComponent implements OnInit {
  submitted = false;
  constructor(private router: Router, private data: DataService, private formBuilder: FormBuilder
  ) { }
  userName: any;
  secretKey = environment.secretKey;
  PasskeyForm: FormGroup;
  lead_source: any;
  ngOnInit() {
    this.lead_source = sessionStorage.getItem('company_Name')
    this.userName = localStorage.getItem('user_basic_name');
    document.getElementById("openModalButton").click();
    (document.getElementsByClassName('modal-backdrop')[0] as HTMLDivElement).style.setProperty('position', 'fixed', 'important');
    this.getinitkey()
    this.getpass();
  }

  getinitkey() {
    this.PasskeyForm = this.formBuilder.group(
      {
        Passpin: new FormControl('', [Validators.required]),
      });
  }

  get f() {
    return this.PasskeyForm.controls;
  }
  passkey: boolean = false;
  loandetailsloader: boolean = false;
  isDisabled: boolean = false;
  hidemodeldata: boolean = false;
  showloader: boolean = false;
  Error: any
  async proceed(PasskeyForm: any): Promise<void> {

    this.submitted = true;

    if (PasskeyForm.invalid) {
      PasskeyForm.controls.Passpin.touched = true;
      this.isDisabled = false;
      return;
    }
    this.passpin = this.PasskeyForm.get('Passpin').value;

    if (this.passpin == '' || this.passpin == undefined) {

      this.isDisabled = true;
    }
    else if (this.passpin != '') {

      this.isDisabled = false;

      const body = {
        loan_id: '',
        pass_key: this.passpin,
        user_id: ''
      }
      try {
        // this.showloader = true;

        this.loandetailsloader = true;
        this.isDisabled = true;
        this.hidemodeldata = true;
        const keyresponse = await this.data.getpasskey(body).toPromise();

        if (keyresponse.status == 200) {
          this.hidemodeldata = false;
          this.Passkeydone = true;
          this.showloader = false;
          this.passkeydata = keyresponse.data.pinNumber;
          this.loandetailsloader = false;
          this.passkey = false;
        }
        else {

          this.isDisabled = false;
          this.loandetailsloader = false;
          this.showloader = false;
          this.isDisabled = false;
          ///alert message
          this.passkey = false;
          this.Passkeydone = false;
          this.Alert = true;
          this.errorMessage = true;
          this.message = JSON.parse(decryptString(keyresponse.data, this.secretKey))

          this.error = this.message.message.ErrMessage;

        }
      }
      catch (error) {
        this.showloader = false;
        this.loandetailsloader = false;
        this.hidemodeldata = false;
        ///alert message
        this.passkey = false;
        this.Passkeydone = false;
        this.Alert = true;
        this.errorMessage = true;
        this.message = JSON.parse(decryptString(error.error.data, this.secretKey))

        this.error = this.message.message.ErrMessage;

      }

    }
  }
  errorMessage: boolean = false;
  error: any;
  message: any;
  passpin: any;
  passkeydata: any;
  active: boolean = false;
  getpass() {
    this.PasskeyForm.reset();
    this.hidemodeldata = false;
    this.active = true;
    this.passkey = true;
    this.Alert = false;
    this.Passkeydone = false;
    this.loandetailsloader = false;
    this.submitted = false;
  }
  Passkeydone: boolean = false;
  closemodel1() {
    this.hidemodeldata = true;
    this.PasskeyForm.reset();
    this.active = false;
    this.submitted = false;
    this.Passkeydone = false;
    this.passkey = false;
  }
  closemodel() {
    (document.getElementsByClassName('modal-backdrop')[0] as HTMLDivElement).style.removeProperty('position');
    this.hidemodeldata = true;
  }
  Alert: boolean = false;
  MobileNumber: any;

  eventTracking(Request_Key, Event_Type, Event_Name) {
    const body = {
      user_id: Request_Key,
      Activity_ID: '',
      Event_Type: Event_Type,
      Event_Name: Event_Name,
      Event_Source: 'Dealer Portal',
      Event_Origin: 'Frontend',
      Remark_1: '',
      Remark_2: '',
      Remark_3: '',
      Remark_4: '',
      Remark_5: ''
    }
    this.data.getEventName(body).subscribe();

  }
}
