import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'input,textarea'
})
export class SanitizeInputDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initialValue = this.el.nativeElement.value;
    const sanitizedValue = initialValue.replace(/[<>!#$%^&*(),?":{}|<>]/g, '');
    if (initialValue !== sanitizedValue) {
      this.el.nativeElement.value = sanitizedValue;
    }
  }
}
