import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ShareDataService } from 'src/app/share-data.service';
import { DataService } from 'src/app/data.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-category-details',
  templateUrl: './category-details.component.html',
  styleUrls: ['./category-details.component.scss']
})
export class CategoryDetailsComponent implements OnInit {
  userActivity;
  userInactive: Subject<any> = new Subject();
  categorylist: boolean = true;
  categorylistdetails: boolean = false;
  clickedId: any = '';
  page: any = 1;
  records: any = 8;
  categoryArr: any = [];
  categoryListArr: any = [];
  recentArr: any = [];
  likedValue: any;
  details: any = [];
  categoryId: any;
  categoryIdDetails: any;
  clickedSearch: boolean = false;
  article_id: any;
  setClickedFromFirst: boolean = false;
  mobile: any;
  clickedButton: any;
  response: any;
  clickedCategoryName: any;
  campign_number: any;
  eventName: any;
  loanDetail: any = '';
  channelName: any = '';
  channelPartnerName: any = '';

  constructor(private router: Router, private data: ShareDataService, private service: DataService) {
    this.setTimeout();
    this.userInactive.subscribe(() => {
      localStorage.clear();
      this.router.navigate(['customerportal&currentuser&sessiontimeout'])
    });
  }
  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 900000);
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
  lead_source: any;
  ngOnInit() {
    this.lead_source = sessionStorage.getItem('company_Name')
    const body1 = {
      phone: localStorage.getItem('dmi_phone')
    }
    this.service.getChannelPartner(body1).subscribe(res => {
      this.channelName = res;
      this.channelPartnerName = this.channelName.data;
      if (this.channelName != '') {
        let phone = localStorage.getItem('dmi_phone')
        this.campign_number = 'null';
        this.eventName = 'Visited: Category Details';
        let source = 'DMI-Customer-Portal';
        this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
        const body1 = {
          phone: phone,
          eventname: this.eventName,
          campign_number: this.campign_number,
          source: source,
          partner: this.channelPartnerName

        }

        this.service.getEventName(body1).subscribe(res => {
        });
      }
    });
    this.mobile = localStorage.getItem("dmi_phone");
    this.setClickedFromFirst = this.data.getClickedFromFirst();
    if (this.setClickedFromFirst == true) {
      this.categoryId = this.data.getCategoryId();
      this.clickedCategoryName = this.data.getCategoryName();
      this.getCategoryClickedDetail();
      this.getRecentClickedDetails();
    }
    this.getCategories();
  }

  // listCategoryPage(){
  //   this.categorylistdetails = true;
  //   this.categorylist = false;
  // }

  getDetails(id, value) {
    if (this.channelName != '') {
      let phone = localStorage.getItem('dmi_phone')
      this.campign_number = 'null';
      this.eventName = 'Made an action to view "' + value + '" from Category Details';
      let source = 'DMI-Customer-Portal';
      this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
      const body1 = {
        phone: phone,
        eventname: this.eventName,
        campign_number: this.campign_number,
        source: source,
        partner: this.channelPartnerName

      }

      this.service.getEventName(body1).subscribe(res => {
      });
    }
    this.categoryId = id;
    this.clickedCategoryName = value;
    this.setClickedFromFirst = false;
    this.categorylistdetails = false;
    this.categorylist = true;
    const body = {
      category_id: this.categoryId,
      pageno: this.page,
      no_of_records_per_page: this.records
    }

    this.service.getCategoryDetails(body).subscribe(res => {
      this.categoryArr = res;
    });
    this.getRecent(this.categoryId);
  }

  getCategories() {
    this.service.getCategoryList().subscribe(res => {
      this.categoryListArr = res;
    });
  }

  getRecent(cat_id) {
    const body = {
      category_id: cat_id
    }
    this.service.getRecentActivity(body).subscribe(res => {
      this.recentArr = res;
    });
  }

  getDetailArticle(event) {
    if (this.channelName != '') {
      let phone = localStorage.getItem('dmi_phone')
      this.campign_number = 'null';
      this.eventName = 'Opened the article' + "" + event.title + "" + 'from Category Detail';
      let source = 'DMI-Customer-Portal';
      this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
      const body1 = {
        phone: phone,
        eventname: this.eventName,
        campign_number: this.campign_number,
        source: source,
        partner: this.channelPartnerName

      }

      this.service.getEventName(body1).subscribe(res => {
      });
    }
    this.article_id = event;
    const body = {
      article_id: event
    }
    this.service.getArticleDetails(body).subscribe(res => {
      this.details = res;
    });
    this.categorylistdetails = true;
    this.categorylist = false;
  }

  likedArticle() {
    if (this.channelName != '') {
      let phone = localStorage.getItem('dmi_phone')
      this.campign_number = 'null';
      this.eventName = 'Article Feedback: "Liked"';
      let source = 'DMI-Customer-Portal';
      this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
      const body1 = {
        phone: phone,
        eventname: this.eventName,
        campign_number: this.campign_number,
        source: source,
        partner: this.channelPartnerName

      }

      this.service.getEventName(body1).subscribe(res => {
      })
    }

    this.clickedButton = 1;
    this.imageClicked();
  }

  unlikedArticle() {
    if (this.channelName != '') {
      let phone = localStorage.getItem('dmi_phone')
      this.campign_number = 'null';
      this.eventName = 'Article Feedback: "Un-Liked"';
      let source = 'DMI-Customer-Portal';
      this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
      const body1 = {
        phone: phone,
        eventname: this.eventName,
        campign_number: this.campign_number,
        source: source,
        partner: this.channelPartnerName

      }

      this.service.getEventName(body1).subscribe(res => {
      })
    }
    this.clickedButton = 0;
    this.imageClicked();
  }

  imageClicked() {
    // let userId = this.data.getUserId();
    let userId = localStorage.getItem('userId');

    const body = {
      user_id: userId,
      phone: this.mobile,
      article_id: this.article_id,
      likeunlike: this.clickedButton
    }
    this.service.likeArticle(body).subscribe(res => {
      this.response = res;
      if (res) {
        if (this.setClickedFromFirst == true) {
          this.getCategoryClickedDetail();
          this.getRecentClickedDetails();
        }
        else {

          const body = {
            category_id: this.categoryId,
            pageno: this.page,
            no_of_records_per_page: this.records
          }
          this.service.getCategoryDetails(body).subscribe(res => {
            this.categoryArr = res;
          });
          this.getRecent(this.categoryId);
        }
        this.categorylistdetails = false;
        this.categorylist = true;
      }
      alert(this.response.message);
    });
  }

  getCategoryClickedDetail() {
    const body = {
      category_id: this.categoryId,
      pageno: this.page,
      no_of_records_per_page: this.records
    }
    this.service.getCategoryDetails(body).subscribe(res => {
      this.categoryArr = res;
    });
    this.categorylistdetails = false;
    this.categorylist = true;
  }

  getRecentClickedDetails() {

    const body = {
      category_id: this.categoryId
    }
    this.service.getRecentActivity(body).subscribe(res => {
      this.recentArr = res;
    });
  }

  ngOnDestroy() {
    clearTimeout(this.userActivity);
  }

}
