import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/data.service';
import { ShareDataService } from 'src/app/share-data.service';
import { HttpClient } from '@angular/common/http';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ExperianModalComponent } from 'src/app/modal/experian-modal/experian-modal.component';
import { ExperianPopupComponent } from 'src/app/modal/experian-popup/experian-popup.component';
import { Observable, Subscription } from 'rxjs';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  @Input() getEvent: Observable<void>;
  @ViewChild('closeFeedback') closeFeedback: ElementRef;
  eventsSubscription: Subscription;
  mobile: any;
  pageno: any = 1;
  records: any = 2;
  notification_type: any = 1;
  notificationArr: any = [];
  rating: any = '';
  ratingLog: any = '';
  reviews: any = '';
  showDial: boolean = false;
  campign_number: any;
  eventName: any;
  showGood: boolean = false;
  showAvg: boolean = false;
  showPoor: boolean = false;
  clickedStar: boolean = false;
  checkedFeedOnly: boolean = true;
  checkedFeedLog: boolean = true;
  getSession: any = '';
  giveFeedback: boolean = true;
  sessionAlert: boolean = false;
  sessionExist: boolean = false;
  showFeedback: boolean = false;
  feedBackLiabrary: boolean = false;
  user_details: any;
  params: any;
  isFound: boolean = false;
  feedbackResp: any = '';
  feedbackResponse: any = [];
  customer_id: any = "";
  name: any = "";
  user_email: any = "";
  user_phone: any = "";
  otpSentResponse: any = '';
  app_id: any = "";
  notify_channel = "none";
  experian_error: any = '';
  is_generate_short_url = false;
  showCreditScore: boolean = false;
  userBasicInfo: any = '';
  url: SafeResourceUrl;
  channelName: any = '';
  channelPartnerName: any = [];
  selectedGender: number;
  responseResult: any = [];
  titleAlert: string = 'This field is required';
  loanDetail: any = '';
  creditScoreRes: any = [];
  AA_Requried: any;
  feedBackDoneTost:boolean = false;
  feedBackDone:any;
  feedbackText:any;
  proceedText:string = 'Submit'
  chooseSatisfaction:boolean = true;
  feedBackDoneText:string = '';
  alreadyFeedback:boolean = false;
  tabName:any;
  constructor(private router: Router, private data: DataService,
    public sanitizer: DomSanitizer, private http: HttpClient,
    private modalService: NgbModal, private toastr: ToastrService,
    public service: ShareDataService) { }

  ngOnInit() {
   
    this.getChannelPartnerName();
    if (localStorage.getItem('loanDetail')) { this.giveFeedback = false; }
    this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
    this.userBasicInfo = JSON.parse(localStorage.getItem('user_basic_info'));
    this.mobile = localStorage.getItem('dmi_phone');
    if (this.userBasicInfo != null) {
      this.customer_id = this.userBasicInfo.data[0].Id;
    }
    setTimeout(() => {
      if (localStorage.getItem('loanDetail')) { this.giveFeedback = false; }
      // console.log('Header')
    }, 20000);
  }
  Emandate() {
    this.router.navigate(['/helpcenter/createtickets']);
    // console.log("hekklk")
  }
  experian() {
    let phone = localStorage.getItem('dmi_phone')
    this.campign_number = 'null';
    let source = 'DMI-Customer-Portal';
    const body1 = {
      phone: phone,
      eventname: 'Get-Credit-Score',
      campign_number: this.campign_number,
      source: source,
      partner: this.channelPartnerName

    }


    this.data.getEventName(body1).subscribe();
    const body = {
      phone: phone
    }
    this.data.getCreditScoreRecord(body).subscribe(res => {
      if (res) {
        this.creditScoreRes = res;
        localStorage.setItem("Credit_Score_Data", JSON.stringify(this.creditScoreRes.data));
        const body1 = {
          phone: phone,
          eventname: 'Get-Credit-Score-Success',
          campign_number: this.campign_number,
          source: source,
          partner: this.channelPartnerName

        }
        this.data.getEventName(body1).subscribe();
        this.router.navigate(['/creditScoreDetail']);
      } else {
        let modalObj: NgbModalOptions = {
          backdrop: 'static',
          centered: true,
          windowClass: 'myCustomModalClass',
          keyboard: false,
        }
        const modal = this.modalService.open(ExperianModalComponent, modalObj);
      }
    },
      err => {
        const body1 = {
          phone: phone,
          eventname: 'Get-Credit-Score-Failed',
          campign_number: this.campign_number,
          source: source,
          partner: this.channelPartnerName

        }
        this.data.getEventName(body1).subscribe();
        let modalObj: NgbModalOptions = {
          backdrop: 'static',
          centered: true,
          windowClass: 'myCustomModalClass',
          keyboard: false,
        }
        const modal = this.modalService.open(ExperianModalComponent, modalObj);
      });
  }

  getExperianUser() {
    let phone = localStorage.getItem('dmi_phone')
    this.campign_number = 'null';
    let source = 'DMI-Customer-Portal';
    const body = {
      Mobile: localStorage.getItem('dmi_phone')
    }

    this.data.experianCheckUser(body).subscribe(res => {
      if (res.message == "Users Success.") {
        this.showCreditScore = true;
        const body1 = {
          phone: phone,
          eventname: 'Experian-User-WhiteListed',
          campign_number: this.campign_number,
          source: source,
          partner: this.channelPartnerName

        }
        this.data.getEventName(body1).subscribe();
      }
      else {
        const body1 = {
          phone: phone,
          eventname: 'Experian-User-Not-WhiteListed',
          campign_number: this.campign_number,
          source: source,
          partner: this.channelPartnerName

        }
        this.data.getEventName(body1).subscribe();
      }
    });
  }

  openExperianPopup() {
    this.AA_Requried = this.service.getAA();
    if (this.AA_Requried == 'No' || this.AA_Requried == 'no') {

      let modalObj: NgbModalOptions = {
        backdrop: 'static',
        centered: true,
        windowClass: 'otpCustomModal experianPopup',
        keyboard: false,
      }
      const modal = this.modalService.open(ExperianPopupComponent, modalObj);
      modal.componentInstance.proceedEvent.subscribe(res => {
        if (res.proceed) {
          let modalObj: NgbModalOptions = {
            backdrop: 'static',
            centered: true,
            windowClass: 'myCustomModalClass',
            keyboard: false,
          }
          const modal = this.modalService.open(ExperianModalComponent, modalObj);
        }
      });
    }
  }
 


  npsModel(event:any){

    this.tabName = event
    this.url = '';
    if (this.channelName != '') {
      let phone = localStorage.getItem('dmi_phone');
      this.campign_number = 'null';
      this.eventName = `Click on the ${this.tabName} tab`;  // Ensure there's a space after 'the'
      let source = 'DMI-Customer-Portal';
    
      const body1 = {
        phone: phone,
        eventname: this.eventName,
        campign_number: this.campign_number,
        source: source,
        partner: this.channelPartnerName
      };
    
      this.data.getEventName(body1).subscribe(res => {
        // handle response if necessary
      });
    }

    const checkQDSBody = {
      phone_no: localStorage.getItem('dmi_phone')
    }

    this.data.checkQdsFeedback(checkQDSBody).subscribe((res)=>{
      if(res.status==200){
        if(res.data.is_eligible === true){
          document.getElementById('paymentlink').click(); 
          this.chooseSatisfaction =true;
          this.feedbackText = '';
          this.feedBackDoneText = '';
          this.feedBackDoneTost = false;
        }
        else{
          if (this.tabName === 'logout') {
            this.logout();
            this.sessionExist = false;
          }
          else{
            this.alreadyFeedback = true;
            this.feedBackDone =  res.message;
            setTimeout(()=>{
              this.alreadyFeedback = false;
            },2000)
          }
        }
       
      }
      else{
       console.log('')
      }
    })
  
  }

  userNotSatisfied(){
    this.chooseSatisfaction = !this.chooseSatisfaction;
    if (this.channelName != '') {
      let phone = localStorage.getItem('dmi_phone');
      this.campign_number = 'null';
      this.eventName = `Tab name ${this.tabName} : Not satisfied with your service`;  // Ensure there's a space after 'the'
      let source = 'DMI-Customer-Portal';
      const body1 = {
        phone: phone,
        eventname: this.eventName,
        campign_number: this.campign_number,
        source: source,
        partner: this.channelPartnerName
      };
    
      this.data.getEventName(body1).subscribe(res => {
        // handle response if necessary
      });
    }
  }

 

  getQdsFeedback(){

    const body = {
      contact_no: localStorage.getItem('dmi_phone'),
      voc: this.feedbackText
  }

    this.proceedText = 'Loading...'
    this.data.storeQdsFeedback(body).subscribe(res=>{
      if(res.status==200){
        this.proceedText = 'Proceed' 
        this.feedBackDoneTost = true;
        this.feedBackDoneText = res.message
        if (this.channelName != '') {
          let phone = localStorage.getItem('dmi_phone');
          this.campign_number = 'null';
          this.eventName = `Tab name ${this.tabName} : Custom feedback stored`;  // Ensure there's a space after 'the'
          let source = 'DMI-Customer-Portal';
          const body1 = {
            phone: phone,
            eventname: this.eventName,
            campign_number: this.campign_number,
            source: source,
            partner: this.channelPartnerName
          };
        
          this.data.getEventName(body1).subscribe(res => {
            // handle response if necessary
          });
        }
      }
      else{
        this.proceedText = 'Loading...'
        
      }

    },(error)=>{
      
      this.proceedText = 'Proceed'
      if (this.channelName != '') {
        let phone = localStorage.getItem('dmi_phone');
        this.campign_number = 'null';
        this.eventName = `Tab name ${this.tabName} : Custom feedback stored failed`;  // Ensure there's a space after 'the'
        let source = 'DMI-Customer-Portal';
        const body1 = {
          phone: phone,
          eventname: this.eventName,
          campign_number: this.campign_number,
          source: source,
          partner: this.channelPartnerName
        };
      
        this.data.getEventName(body1).subscribe(res => {
          // handle response if necessary
        });
      }
    })
  }


  checkRatingStats() {
    this.showFeedback = true;
    this.userBasicInfo = '';
    this.customer_id = '';
    this.userBasicInfo = JSON.parse(localStorage.getItem('user_basic_info'));
    this.customer_id = this.userBasicInfo.data[0].Id;
    this.name = this.userBasicInfo.data[0].Name;
    this.user_email = this.userBasicInfo.data[0].Email;
    if (this.userBasicInfo.data[0].Phone == null) {
      this.user_phone = this.userBasicInfo.data[0].MobilePhone;
    } else { this.user_phone = this.userBasicInfo.data[0].Phone; }
    const body1 = {
      phone: localStorage.getItem('dmi_phone')
    }
    this.data.getChannelPartner(body1).subscribe(res => {
      this.channelName = res;
      this.channelPartnerName = this.channelName.data;
      if (this.channelName != '') {
        this.app_id = this.service.getAppIdforFeedback(this.channelPartnerName);
        if (this.app_id == '') { this.app_id = 'juz6_test'; }

        this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
        const body = {
          phone: this.user_phone,
          loan_id: this.loanDetail[0].name,
          loan_amount: this.loanDetail[0].Original_Face__c,
          loan_disbursement_date: this.loanDetail[0].Disbursement_Date__c,
          channel_partner_name: this.channelPartnerName,
          email_id: this.user_email,
          name: this.name
        }

        this.data.getFeedbackUrl(body).subscribe(res => {
          
          if (res) {
            this.feedbackResp = res;
            if (this.feedbackResp.data === '') { 
              this.feedBackDone = this.feedbackResp.message
              
              if (this.channelName != '') {
                let phone = localStorage.getItem('dmi_phone');
                this.campign_number = 'null';
                this.eventName = `Tab name ${this.tabName} : You have already given your feedback.`;  // Ensure there's a space after 'the'
                let source = 'DMI-Customer-Portal';
              
                const body1 = {
                  phone: phone,
                  eventname: this.eventName,
                  campign_number: this.campign_number,
                  source: source,
                  partner: this.channelPartnerName
                };
              
                this.data.getEventName(body1).subscribe(res => {
                  // handle response if necessary
                });
              }
              if (this.tabName === 'logout') {
                this.logout();
                this.sessionExist = false;
              }
            }
            else {
              this.feedbackResponse = this.feedbackResp.data.url;
              this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.feedbackResponse);
            }
          }
        });
      }
    });
    
    ////////////////////////////document///////////////////////////

    if (window.addEventListener) {
      window.addEventListener("message", onMessage, false);
    }
    else if ((<any>window).attachEvent) {
      (<any>window).attachEvent("onmessage", onMessage, false);
    }
    function onMessage(event) {
      if (event.origin !== "https://app.litmusworld.com" || event.origin !== "https://app-india.litmusworld.com" || event.origin !== "https://dashboard.litmusworld.com" ||
        event.origin !== "https://dashboard-india.litmusworld.com") return;

      var data = event.data;
      if (typeof (window[data.func]) == "function") {
        // window[data.func].call(null, data.message);
        alert(event);
      }
    }

    ////////////////////////////document///////////////////////////
  }



  checkRatingLogout() {
    this.getSession = localStorage.getItem('sesssionRating');
    if (this.getSession != null) {
      this.sessionExist = true;
    } else if (this.getSession == null) {
      this.logout();
      this.sessionExist = false;
    }
  }

  logout() {
    
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/']);
  }

  getfeedbackLogout() {
    let phone = localStorage.getItem('dmi_phone')
    this.campign_number = 'null';
    this.eventName = 'Made an action to "Logout" from Top Menu'
    let source = 'DMI-Customer-Portal'
    this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
    if (this.channelName != '') {

      const body1 = {
        phone: phone,
        eventname: this.eventName,
        campign_number: this.campign_number,
        source: source,
        partner: this.channelPartnerName

      }
      this.data.getEventName(body1).subscribe(res => {
      })
    }

    if (this.reviews == '') {
      this.reviews = '-';
    }
    if (this.clickedStar == true) {
      localStorage.removeItem('sesssionRating');
      const body = {
        phone: this.mobile,
        rating: this.ratingLog,
        description: this.reviews

      }
      this.data.getFeedback(body).subscribe(res => {
        if (res) {
        }
        this.ratingLog = '';
        this.showDial = false;
        this.showPoor = false; this.showAvg = false; this.showGood = false;
        this.logout();
      });
    }
    else {
      this.logout();
    }
  }

  getfeedbackOnly() {
    let phone = localStorage.getItem('dmi_phone')
    this.campign_number = 'null';
    this.eventName = 'Made an action to "Give Feedback" From Top Menu'
    let source = 'DMI-Customer-Portal'
    this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
    if (this.channelName != '') {
      const body1 = {
        phone: phone,
        eventname: this.eventName,
        campign_number: this.campign_number,
        source: source,
        partner: this.channelPartnerName

      }
      this.data.getEventName(body1).subscribe(res => {
      })
    }
    if (this.reviews == '') {
      this.reviews = '-';
    }
    localStorage.removeItem('sesssionRating');
    const body = {
      phone: this.mobile,
      rating: this.rating,
      description: this.reviews

    }
    this.data.getFeedback(body).subscribe(res => {
      if (res) {
      }
      this.rating = '';
      this.showDial = false;
      this.showPoor = false; this.showAvg = false; this.showGood = false;
    });
  }

  onRate(newValue: number) {
    this.clickedStar = false;
    if (newValue >= 1) {
      this.checkedFeedOnly = false;
    }
    this.showPoor = false; this.showAvg = false; this.showGood = false;
    this.rating = newValue;
    if (newValue) { this.showDial = true; }
    if (newValue <= 7) { this.showPoor = true; }
    else if (newValue < 10 && newValue > 7) { this.showAvg = true; }
    else if (newValue > 9) { this.showGood = true; }
  }

  onRateLogout(newValue: number) {
    this.clickedStar = true;
    if (newValue >= 1) {
      this.checkedFeedLog = false;
    }
    this.showPoor = false; this.showAvg = false; this.showGood = false;
    this.ratingLog = newValue;
    if (newValue) { this.showDial = true; }
    if (newValue <= 7) { this.showPoor = true; }
    else if (newValue < 10 && newValue > 7) { this.showAvg = true; }
    else if (newValue > 9) { this.showGood = true; }
  }

  closeRate() {


    if (this.channelName != '') {
      let phone = localStorage.getItem('dmi_phone')
      this.campign_number = 'null';
      this.eventName = `${this.tabName} tab: Click on NPS model close button from top menu`
      let source = 'DMI-Customer-Portal';
      const body1 = {
        phone: phone,
        eventname: this.eventName,
        campign_number: this.campign_number,
        source: source,
        partner: this.channelPartnerName

      }
      this.data.getEventName(body1).subscribe(res => {
      })
    }
    if (this.tabName === 'logout') {
      this.logout();
      this.sessionExist = false;
    }
  }

  closeRateLogout() {
    if (this.channelName != '') {
      let phone = localStorage.getItem('dmi_phone')
      this.campign_number = 'null';
      this.eventName = 'Logout from Feedback window'
      let source = 'DMI-Customer-Portal';
      const body1 = {
        phone: phone,
        eventname: this.eventName,
        campign_number: this.campign_number,
        source: source,
        partner: this.channelPartnerName

      }
      this.data.getEventName(body1).subscribe(res => {
      });
      this.logout();
    }
  }



  getNotification() {
    if (sessionStorage.getItem('firstTime')) {
      const body = {
        phone: localStorage.getItem('dmi_phone')
      }
      this.data.getCreditScoreRecord(body).subscribe(res => {
        if (res) {
          this.creditScoreRes = res;
          let source = 'DMI-Customer-Portal';
          this.campign_number = 'null';
          localStorage.setItem("Credit_Score_Data", JSON.stringify(this.creditScoreRes.data));
          const body1 = {
            phone: localStorage.getItem('dmi_phone'),
            eventname: 'Get-Credit-Score-Success',
            campign_number: this.campign_number,
            source: source,
            partner: this.channelPartnerName

          }

          this.data.getEventName(body1).subscribe();
        } else {
          setTimeout(() => {
            this.openExperianPopup();
          }, 2000);
        }
      }, error => {
        setTimeout(() => {
          this.openExperianPopup();
        }, 2000);
      });
    }
    const body = {
      mobile: this.mobile,
      pageno: this.pageno,
      no_of_records_per_page: this.records,
      notification_type: this.notification_type
    }

    this.data.getNotificationList(body).subscribe(res => { this.notificationArr = res; });
  }

  notificationDetails() {
    this.router.navigate(['helpcenter/notification&alertdetails'])
  }

  getSafeUrl() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.feedbackResponse);
  }

  getChannelPartnerName() {
    const body = {
      phone: localStorage.getItem('dmi_phone')
    }
    this.data.getChannelPartner(body).subscribe(res => {
      this.channelName = res;
      this.channelPartnerName = this.channelName.data;
      this.getNotification();
      //this.getExperianUser();
    });
  }

  ngOnDestroy() {
    this.modalService.dismissAll();
  }

}

