import { Component, OnInit } from '@angular/core';

import { Router,NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {

  constructor(private router:Router) { 
    
    
  }
  
  ngOnInit() {
   
    if (!localStorage.getItem('foo')) { 
      localStorage.setItem('foo', 'no reload');
     console.log(true)
     window.location.reload() 
    } else {
      console.log(false)
      localStorage.removeItem('foo') 
    }
  }

  reloadpage(){
    if(sessionStorage.getItem('URL')){
      window.location.reload();
    }
    

  }
  

}
