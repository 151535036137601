import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-experian-terms',
  templateUrl: './experian-terms.component.html',
  styleUrls: ['./experian-terms.component.scss']
})
export class ExperianTermsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
