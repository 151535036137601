import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-experian-popup',
  templateUrl: './experian-popup.component.html',
  styleUrls: ['./experian-popup.component.scss']
})
export class ExperianPopupComponent implements OnInit {

  @Output() proceedEvent:EventEmitter<any> = new EventEmitter();

  constructor(private activeModal: NgbActiveModal,
    private data:DataService) { }

  ngOnInit() {
    this.eventTracking('Experian-Popup-Check-Score-Open');
  }

  close(){
    sessionStorage.removeItem('firstTime');
    this.eventTracking('Experian-Popup-Check-Score-Close');
    this.currentModal.dismiss();
  }

  get currentModal(){
    return this.activeModal;
  }

  proceed(){
    this.currentModal.dismiss();
    sessionStorage.removeItem('firstTime');
    this.eventTracking('Experian-Popup-Check-Score-Proceed');
    this.proceedEvent.emit({proceed : true});
  }

  eventTracking(eventName){
    let phone = localStorage.getItem('dmi_phone');
    let campign_number = localStorage.getItem('single_loan_name');
    const body1={
      phone:phone,
       eventname:eventName, 
       campign_number:campign_number, 
       source:'DMI-Customer-Portal', 
       partner:sessionStorage.getItem('company_Name')

    }
    this.data.getEventName(body1).subscribe();
  }

}
