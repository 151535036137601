export const environment = {
  production: false,

  // PROD
  baseurl: 'https://businesspartner-be.dmifinance.in/backend/api/V1/',

  // UAT
  // baseurl: "https://dev.dmifinance.co/Customer-Portal/Backend/api/V1/",

  // Dev
  // baseurl: "https://dpcp.dmifinance.co/customer_portal/api/V1/",
  //

  secretKey:
    'QK8IAINPHWEYCBJ80UDLKHE5FZLUCQTXFCWKGXLIJKNSAEBFTCF6ZSIZHCONG5BZPAKAALLBAEXDUGZMTPGVLN99KVZBFGEZ66Z4BVZNHJQDPNEAISTXASQYCHJJ3I24LJ0PM3F8XF8RNCB45KIEKSZBJLQNRLN9OZ821X9QILY6O1SZLAPNHSZD2Z0WQ4BHBJVI1EDE',
};
