declare var Razorpay: any;
import { Component, OnInit, HostListener, ViewChild, ViewContainerRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';
import { ShareDataService } from '../share-data.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { decryptString } from 'src/Utils/encryption';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-loan-detail',
  templateUrl: './loan-detail.component.html',
  styleUrls: ['./loan-detail.component.scss']
})
export class LoanDetailComponent implements OnInit {
  userActivity;
  secretKey = environment.secretKey;
  Dateform: FormGroup;
  model_overlay: boolean = false;
  displayModal: any = 'none';
  userInactive: Subject<any> = new Subject();
  userBasicInfo: any;
  loanDetails: any;
  loanId: any;
  isLoading: boolean;
  UnlockLoader2: boolean = false;
  welcomedata: any;
  selectedId: any;
  SpecificLoanDetail: any;
  loanStatementData: any;
  specificLoanAmt: any;
  convertedLoanAmt: any;
  display = 'none';
  display1 = 'none';
  display2 = 'none';
  display3 = 'none';
  payNowModel = 'none';
  paymentMethod: any;
  accountType: any;
  isNetBanking: boolean = true;
  isDebit: boolean = false;
  enableBtn: boolean = false;
  netBanking: any = 'Net Banking';
  debitCard: any;
  selectedBankName: any;
  selectedBankName2: any;
  loanName: any;
  userName: any;
  email: any;
  phoneNo: any;
  orderId: any;
  customerId: any;
  createCO: any;
  forNetBankingAmt: any;
  accountNo: any;
  accountIFSC: any;
  acHolderName: any;
  requiredError: any;
  requiredError1: any;
  requiredError2: any;
  nachResponse: any;
  fourDigitIFSC: any;
  interval: any;
  timeLeft: number = 8;
  payableInput: boolean = true;
  otherInput: boolean = false;
  paidPayableAmount: any;
  otherAmount: any;
  payNowData: any;
  payNowcustomerId: any;
  payNoworderId: any;
  billingAddress: any;
  city: any;
  country: any;
  postalCode: any;
  state: any;
  street: any;
  nachCheckData: any;
  nachErrData: any;
  alreadyRegisNach: boolean;
  forNachRegis: boolean;
  loanNumber: any;
  payNowForm: FormGroup;
  principleAmt: number;
  dueAmt: number;
  PaidAmt: number;
  staticDueAmt: any;
  emitotalTenor: any;
  dueAmount: any;
  perc: any;
  uiPercentage: any;
  response: any;
  totalpaid: any;
  totalunpaid: any;
  totalemiamount: any;
  totalpaidemi: any;
  totalemi: any;
  totalpayableamount: any;
  ifTotalpayMount: any;
  loanNameValue: any;
  phone: any;
  localData: any;
  previous_amount: any;
  current_amount: any;
  current_month_due_date: any;
  status: any;
  date: any;
  payNow: boolean = false;
  register: boolean = false;
  payPrevious: boolean = false;
  payTotal: boolean = false;
  stageName: any;
  maturedCond: boolean;
  PaymentMethodOption = [{ name: "Net Banking" }, { name: "Debit Card" }];
  UserAccountType = [{ name: "Savings", value: 'savings' }, { name: "Current", value: 'current' }];
  lastPaidAmt: any;
  lastPaymentDate: any = '';
  matureSum: any;
  incaseNoOpp: boolean = false;
  maturedcase: boolean = true;
  companyName: any = '';
  dueDate: any = '';
  modeofpayment: any;
  Accountid: any;
  presendat: any;
  maxAmount: any;
  amtToString: any;
  netPaymentC: any;
  gettokendata: any;
  getPPName: any;
  sendPPName: any;
  getPPAmount: any = '';
  specificLoanAmt1: any;
  specificLoanAmt2: any;
  userName1: any;
  userNameForm: any;
  loanAmount: any = "Loan Amount";
  loanDetail: any = '';
  tokennew: any;
  sanction_amount: any;
  channelName: any = '';
  channelPartnerName: any = '';
  paymentsyncloader: boolean = false;
  payNowModelData: any[] = []
  eventsSubject: Subject<void> = new Subject<void>();
  // isButtonDisabled:boolean=true;

  Customer_Name1: any;
  nachRejectioncharge1: any;
  PayableAmountres: any;
  Loan_Type: any;
  Stage_Name1: any;
  Emi_Amount: any;
  total_PreclosureAmount: any;
  total_OverdueAmount: any;
  total_PendingCharge: any;
  Display_Others: any;
  // razorpayKey: any
  payableAmount:boolean = true;
  selectPaymentType:boolean = false;
  mobileEdit:boolean= false;
  isSMSSend:boolean = false;
  messageResponse:any;
  buttonLoader:boolean = false;
  mobileform!: FormGroup;
  submitted = false;
  disabled:boolean = true;
  paymentTypes:string = 'Send Payment Link'
  amountLabel:string = 'EMI Amount';
  // button disable 
  paynowdisable: boolean = false; loandetailnachdisable: boolean = false

  netbankingDropdown: any = [
    { key: 'Andhra Bank', value: 'ANDB' },
    { key: 'Axis Bank', value: 'UTIB' },
    { key: 'Bank of Baroda', value: 'BARB_R' },
    { key: 'Bank of Maharashtra', value: 'MAHB' },
    { key: 'Canara Bank', value: 'CNRB' },
    { key: 'Central Bank of India', value: 'CBIN' },
    { key: 'City Union Bank', value: 'CIUB' },
    { key: 'Cosmos Co-operative Bank', value: 'COSB' },
    { key: 'Deutsche Bank', value: 'DEUT' },
    { key: 'Dhanalaxmi Bank', value: 'DLXB' },
    { key: 'Equitas Small Finance Bank', value: 'ESFB' },
    { key: 'Federal Bank', value: 'FDRL' },
    { key: 'HDFC Bank', value: 'HDFC' },
    { key: 'ICICI Bank', value: 'ICIC' },
    { key: 'IDBI Bank', value: 'IBKL' },
    { key: 'IDFC First Bank', value: 'IDFB' },
    { key: 'Indian Overseas Bank', value: 'IOBA' },
    { key: 'Indusind Bank', value: 'INDB' },
    { key: 'Kotak Mahindra Bank', value: 'KKBK' },
    { key: 'Karnataka Bank LTD', value: 'KARB' },
    { key: 'Oriental Bank of Commerce', value: 'ORBC' },
    { key: 'Paytm Payments Bank', value: 'PYTM' },
    { key: 'Punjab National Bank', value: 'PUNB_R' },
    { key: 'RBL Bank', value: 'RATN' },
    { key: 'Standard Charted Bank', value: 'SCBL' },
    { key: 'State Bank of India', value: 'SBIN' },
    { key: 'Tamilnadu Mercantile Bank', value: 'TMBL' },
    { key: 'Ujjivan Small Finance Bank LTD', value: 'USFB' },
    { key: 'United Bank of India', value: 'UTBI' },
    // {key:'Yes Bank',value:'YESB'},
  ];

  debitDropdown: any = [
    { key: 'Bank of Maharashtra', value: 'MAHB' },
    { key: 'Citi Bank', value: 'CITI' },
    { key: 'Deutsche Bank', value: 'DEUT' },
    { key: 'Equitas Small Finance Bank', value: 'ESFB' },
    { key: 'Federal Bank', value: 'FDRL' },
    { key: 'HDFC Bank', value: 'HDFC' },
    { key: 'ICICI Bank', value: 'ICIC' },
    { key: 'IDBI Bank', value: 'IBKL' },
    { key: 'IDFC First Bank', value: 'IDFB' },
    { key: 'Indusind Bank', value: 'INDB' },
    { key: 'Karnataka Bank LTD', value: 'KARB' },
    { key: 'Kotak Mahindra Bank', value: 'KKBK' },
    { key: 'Punjab National Bank', value: 'PUNB_R' },
    { key: 'RBL Bank', value: 'RATN' },
    { key: 'South Indian Bank', value: 'SIBL' },
    { key: 'State Bank of India', value: 'SBIN' },
    { key: 'Ujjivan Small Finance Bank LTD', value: 'USFB' },
    { key: 'United Bank of India', value: 'UTBI' },
    // {key:'Yes Bank',value:'YESB'},
  ];
  PTPModel: boolean = false;
  PTP_overlay: boolean = false;
  constructor(private router: Router,
    private data: DataService,
    private formBuilder: FormBuilder, private service: ShareDataService, private modalService: NgbModal) {
    this.setTimeout();
    this.userInactive.subscribe(() => {
      localStorage.clear();
      this.router.navigate(['customerportal&currentuser&sessiontimeout'])
    });
  }

  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 1500000);
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
  userId: any;
  paymentType: any;
  lead_source: any;
  ngOnInit() {

    this.lead_source = sessionStorage.getItem('company_Name')
    this.userId = sessionStorage.getItem('userID');

    // this.razorpayKey = this.data.razorpayKey;

    const body3 = {
      phone: localStorage.getItem('dmi_phone')
    }

    this.mobileform = this.formBuilder.group({
      mobile: ["", Validators.required],
    });

    this.data.getChannelPartner(body3).subscribe(res => {
      this.channelName = res;
      this.channelPartnerName = this.channelName.data;
    });
    this.checkPTPStatus();
    this.phone = localStorage.getItem('dmi_phone');
    this.accountType = this.UserAccountType[0].name;
    this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
    this.loanNameValue = this.service.getName();
    this.payNowForm = this.formBuilder.group({ payableAmount: [''] });
    this.userName1 = localStorage.getItem('user_basic_name');
    this.userNameForm = this.userName1;

    const body = {
      user_id: this.userId,
      loanid: localStorage.getItem('specefic_loan_id')
    }
    this.Dateform = this.formBuilder.group({
      DOB: ["", Validators.required],
    });
    this.data.getSpecificLoan(body).subscribe(data => {
      this.SpecificLoanDetail = data;
      localStorage.setItem('principleAmt', this.SpecificLoanDetail.data[0].Original_Face__c);
      localStorage.setItem('dueMonth', this.SpecificLoanDetail.data[0].Date__c);
      localStorage.setItem('dueAmt', this.SpecificLoanDetail.data[0].Due);
      localStorage.setItem('specific_loan_detail', JSON.stringify(this.SpecificLoanDetail.data));
      this.paidPayableAmount = this.SpecificLoanDetail.data[0].EMI__c;
      this.dueAmount = this.SpecificLoanDetail.data[0].Due;
      this.emitotalTenor = this.SpecificLoanDetail.data[0].Loan_Tenor_in_Month__c;
      this.specificLoanAmt = this.SpecificLoanDetail.data[0].Original_Face__c;
      this.specificLoanAmt1 = this.SpecificLoanDetail.data[0].Original_Face__c;
      this.netPaymentC = this.SpecificLoanDetail.data[0].Net_Payment__c;
      this.convertedLoanAmt = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(this.specificLoanAmt);
      this.loanId = this.SpecificLoanDetail.data[0].Id;
      this.loanName = this.SpecificLoanDetail.data[0].name;
      this.stageName = this.SpecificLoanDetail.data[0].StageName;
      this.companyName = this.SpecificLoanDetail.data[0].Pulled_Lead_Source_Name__c;
      this.Accountid = this.SpecificLoanDetail.data[0].Accountid;
      sessionStorage.setItem('accountId', this.Accountid);
      this.getPPName = this.SpecificLoanDetail.data[0].parent_opp;
      this.getPPAmount = this.SpecificLoanDetail.data[0].pp_amount;
      this.overdueAmount = this.SpecificLoanDetail.data[0].Current_Total_Outstanding__c;
      this.paymentType = this.SpecificLoanDetail.data[0].Payment_Type;
      this.sanction_amount = this.SpecificLoanDetail.data[0].Sanction_Amount__c;
      this.dueDate = localStorage.getItem('dueMonth');
      const nachStatus = this.SpecificLoanDetail.data[0].Nach.Status__c;

      // PTP model open
      // (this.currentTime >= 20 || this.currentTime <= 5)
      if ((this.stageName == 'Current' || this.stageName == 'Delinquent') && this.paymentType == 'Overdue Amount' && this.overdueAmount != 0 && this.companyName == "Samsung" && (this.PTP_Popup == 1 || this.PTP_Popup == '1')) {
        this.PTP_overlay = true;
        this.PTPModel = true;
        document.getElementById("PTPatomatic").click();

      }
      else {
        this.PTP_overlay = false;
      }

      if (nachStatus == 'SUCCESS' || nachStatus == 'Success' || nachStatus == 'success'
        || nachStatus == 'ACCEPTED' || nachStatus == 'Accepted' || nachStatus == "Active" || nachStatus == "Activate" ||
        nachStatus == 'Verified' || nachStatus == 'confirmed' || nachStatus == 'Confirmed' || nachStatus == "ACTIVATE" || nachStatus == "ACTIVE") {
        this.modeofpayment = true;
        this.payNow = true;
        this.register = false;
        return;
      }
      else {

        this.modeofpayment = false;
        this.payNow = true;
        this.register = true;
      }

      if (this.stageName == 'Matured' || this.stageName == 'Matured Closed') {
        this.maturedcase = false;
        this.maturedCond = false;
      } else {
        this.maturedCond = true;
      }
      if (this.companyName == 'ZestMoney' || this.companyName == 'Redcarpet' || this.companyName == 'Slicepay') {
        this.register = false;
      }
      if (this.companyName == 'Samsung') { this.loanAmount = 'EMI Amount'; this.specificLoanAmt = this.SpecificLoanDetail.data[0].Net_Payment__c; }

      this.service.setLoanId(this.loanId);
      this.service.setLoanName(this.loanName);
      this.principleAmt = this.specificLoanAmt
      this.dueAmt = this.dueAmount
      this.PaidAmt = this.principleAmt - this.dueAmt
      let principal = this.principleAmt
      let paid = this.PaidAmt
      this.perc = "";
      if (isNaN(principal) || isNaN(paid)) {
        this.perc = " ";
      } else {
        this.perc = ((paid / principal) * 100).toFixed(0);
        this.uiPercentage = this.perc - 2.4
      }
      let progressStatus = document.getElementById('dynamicProgress');
      let statusIcon = document.getElementById('statusIcon');
      progressStatus.style.width = this.perc + '%';
      statusIcon.style.left = this.uiPercentage + '%';
    },
      err => { this.SpecificLoanDetail = [] }
    );

    this.paymentMethod = 'Net Banking'
    this.selectedBankName = 'Andhra Bank'
    this.fourDigitIFSC = 'ANDB';
    this.checkDeviceLockStatus();
    this.userBasicInfo = JSON.parse(localStorage.getItem('user_basic_info')) || [];
    this.city = this.userBasicInfo.data[0].MailingCity;
    this.country = this.userBasicInfo.data[0].MailingCountry;
    this.postalCode = this.userBasicInfo.data[0].MailingPostalCode;
    this.state = this.userBasicInfo.data[0].MailingState;
    this.street = this.userBasicInfo.data[0].MailingStreet;
    this.billingAddress = this.city + ',' + this.country + ',' + this.postalCode + ',' + this.state + ',' + this.street;
    this.userName = this.userBasicInfo.data[0].Name;
    this.email = this.userBasicInfo.data[0].Email;
    this.acHolderName = this.userBasicInfo.data[0].Name;
    this.loanDetails = JSON.parse(localStorage.getItem('loan_details')) || [];

    if (this.userBasicInfo.data[0].MobilePhone == null || this.userBasicInfo.data[0].MobilePhone == '' || this.userBasicInfo.data[0].MobilePhone == 'null') { this.phoneNo = this.userBasicInfo.data[0].Phone; }
    else { this.phoneNo = this.userBasicInfo.data[0].MobilePhone; }
    const body4 = {
      loan_number: localStorage.getItem('single_loan_name')
    }

    this.data.getChechNach(body4).subscribe(res => {
      this.nachCheckData = res;
      this.alreadyRegisNach = true;
      this.forNachRegis = false;
      this.date = this.nachCheckData.data.Current_Date;
      this.status = this.nachCheckData.data.Status__c;
      let dateCondition = this.dueDate - 3;

      // if(this.status=='SUCCESS' || this.status=='Success' || this.status=='success' 
      // || this.status=='ACCEPTED' || this.status=='Accepted' || this.status=="Active" || this.status=="Activate" ||
      // this.status=='Verified' || this.status=='Confirmed' || this.status=="ACTIVATE" || this.status == "ACTIVE")
      // {
      //   this.modeofpayment=true;
      //   this.payNow=true;
      //   this.register=false;
      // }
      // else {
      //   this.modeofpayment=false;
      //   this.payNow=true;this.register=true;
      // }
    },
      err => {
        this.nachErrData = err;
        this.forNachRegis = true;
        this.alreadyRegisNach = false;
      })
    if (this.netBanking == 'Net Banking') {
      this.forNetBankingAmt = '0';
    } else {
      this.forNetBankingAmt = '1';
    }
    const body2 = {
      loanname: localStorage.getItem('single_loan_name')
    }

    this.data.getDetailsLoan(body2).subscribe(res => {
      this.response = res;
      this.lastPaidAmt = this.response.data[0].last_paid_amount
      this.lastPaymentDate = this.response.data[0].last_piad_date;

      if (this.stageName !== 'Matured') {
        this.totalpaid = this.response.data[0].totalpaidemiamount;

      }

      this.totalunpaid = this.response.data[0].totalunpaidemiamount;
      this.totalemi = this.response.data[0].totalemi;
      this.totalpaidemi = this.response.data[0].totalpaidemi;
      this.totalemiamount = this.response.data[0].totalemiamount;
      this.totalpayableamount = this.response.data[0].totalpayableamount;
      this.ifTotalpayMount = this.response.data[0].totalpayableamount = 0 || this.response.data[0].totalpayableamount <= 1;
      this.previous_amount = this.response.data[0].previous_outstanding_amount;
      this.current_amount = this.response.data[0].current_month_amount;
      this.current_month_due_date = this.response.data[0].current_month_due_date;

      if (this.previous_amount == '0.00' && this.current_amount == '0.00') {
        this.payPrevious = false; this.payTotal = false;
      }
      else if (this.previous_amount !== '0.00' && this.current_amount == '0.00') {
        this.payPrevious = true; this.payTotal = false;
      }
      else if (this.previous_amount == '0.00' && this.current_amount !== '0.00') {
        this.payPrevious = false; this.payTotal = true;
      }
      else if (this.previous_amount !== '0.00' && this.current_amount !== '0.00') {
        this.payPrevious = true; this.payTotal = true;
      }
    });
    // const body1 = {
    //   loanname: localStorage.getItem('single_loan_name')
    // }
    // this.data.getmaturesumvalue(body1).subscribe(res => {
    //   this.matureSum = res;
    //   if (this.stageName == 'Matured') {
    //     this.totalpaid = this.matureSum.data[0].expr0;
    //   }
    // })


  }
  ptpResponse: any;
  PTP_Popup: any;
  TemporaryUnlock_Button: any;

  //Check PTP status 
  checkPTPStatus() {
    const body = {
      user_id: this.userId,
      loan_id: localStorage.getItem('single_loan_name'),
    }
    this.data.PTPStatus(body).subscribe(res => {
      this.ptpResponse = res.data;
      this.PTP_Popup = this.ptpResponse.PTP_Popup;
      this.TemporaryUnlock_Button = this.ptpResponse.Temporary_Unlock_Button;
      this.eventTracking('Check_PTP_Service');

    }, (error) => {
      console.log(error)
      this.eventTracking('Check_PTP_Service_Failure');
    })
  }

  lock_State: any;
  response_1: any;
  checkDeviceLockStatus() {
    const lockbody = {
      user_id: this.userId,
      loan_id: localStorage.getItem('single_loan_name')
    }
    this.data.DeviceLockStatus(lockbody).subscribe(res => {
      this.lock_State = res.data.lockState;
      this.eventTracking('Device_lock_Status');
      if (this.lock_State == 'Locked') {
        if (this.TemporaryUnlock_Button == 1 || this.TemporaryUnlock_Button == '1') {
          this.temporyhidden = true;
        }
        else {
          this.temporyhidden = false;
        }
      }

    }, (error) => {
      this.eventTracking('Device_lock_Status_Failure');
    })
  }
  Message: any;
  overdueAmount: any;

  // device locked status
  getlockedstatus() {
    this.UnlockLoader2 = true;
    this.model_overlay = false;
    const body = {
      user_id: this.userId,
      loan_id: localStorage.getItem('single_loan_name'),
      stage_name: this.stageName,
      overdue_amount: this.overdueAmount,
      device_status: this.lock_State
    }
    this.data.DeviceUnlock(body).subscribe(res => {
      this.response_1 = res.message;
      this.UnlockLoader2 = false;
      this.model_overlay = true;
      this.eventTracking('Device_Unlock_Status');
    }, (error) => {
      this.UnlockLoader2 = false;
      this.model_overlay = true;
      this.Message = JSON.parse(decryptString(error.error.data, this.secretKey))
      this.response_1 = this.Message.message;
      this.eventTracking('Device_Unlock_Status_Failure');
    })

  }
  CloseUnlockstatus() {
    this.model_overlay = false;
  }
  Alert: boolean = false;
  dob: any;
  newdate: any;
  isDisabled: boolean = true;
  datechangedModel: boolean = false;
  loader: boolean = false;
  promisepayloader: boolean = false;
  loaderUnlock: boolean = false;
  ptpresponse: any;
  disableinput: boolean = false;
  temporyhidden: boolean = false;
  DOB: any;
  //Promise to pay (Temporay Unlock)
  async Promisetopay(Dateform: any): Promise<void> {
    this.model_overlay = false;
    this.PTPModel = false;
    this.isDisabled = true;
    this.loaderUnlock = true;
    this.dob = this.date;
    this.disableinput = true;
    const PTPbody =

    {
      user_id: this.userId,
      loan_id: localStorage.getItem('single_loan_name'),
      mobile: this.phone,
      ptp_date: this.newdate
    }

    this.data.ptpDate(PTPbody).subscribe(async res => {
      this.ptpresponse = res;
      this.eventTracking('PTP_Capture');
      const Tempbody =
      {
        user_id: this.userId,
        loan_id: localStorage.getItem('single_loan_name'),
        mobile: this.phone,
      }
      try {
        const payresponse = await this.data.promisetodate(Tempbody).toPromise();

        this.isDisabled = false;
        this.promisepayloader = false;
        this.Dateform.reset();
        this.loaderUnlock = false;
        this.isDisabled = false;
        this.datechanged = true;
        this.datechangedModel = false;
        this.eventTracking('Temporary_Unlock');

      }
      catch (error) {
        this.disableinput = false;
        this.promisepayloader = false;
        this.Alert = true;
        this.isDisabled = false;
        this.datechanged = false;
        this.datechangedModel = false;
        this.loaderUnlock = false;
        this.errorMessage = true;
        this.message = JSON.parse(decryptString(error.error.data, this.secretKey))
        this.error = this.message.message.ErrMessage;
        this.eventTracking('Temporary_Unlock_Failure');
      }
    }, (error) => {
      this.loaderUnlock = false;
      this.disableinput = false;
      this.promisepayloader = false;
      this.Alert = true;
      this.isDisabled = false;
      this.datechanged = false;
      this.datechangedModel = false;
      this.errorMessage = true;
      this.message = JSON.parse(decryptString(error.error.data, this.secretKey))
      this.error = this.message.message.ErrMessage;
      this.eventTracking('PTP_Capture_Failure');
    })
  }

  PTPSuccess: boolean = false;
  PTPErrorAlert: boolean = false;
  //18 years validation
  maxDobString: any;
  minDobString: any;
  demoData: any;
  YearsValidation() {

    var days = (1000 * 60 * 60 * 24);
    var now = new Date().getTime();
    var maxDobMS = now + (3 * days);
    var minDobMS = now;
    this.maxDobString = new Date(maxDobMS).toISOString().slice(0, 10);
    this.minDobString = new Date(minDobMS).toISOString().slice(0, 10);
  };
  //PTP Captured
  async PTPdate(Dateform: any): Promise<void> {
    this.isDisabled = true;
    this.loaderUnlock = true;
    this.dob = this.date;
    this.disableinput = true;
    const body =
    {
      user_id: this.userId,
      loan_id: localStorage.getItem('single_loan_name'),
      mobile: this.phone,
      ptp_date: this.newdate
    }
    try {
      const PTPresponse = await this.data.ptpDate(body).toPromise();
      if (PTPresponse.data[0].Status_Message == "Success") {
        this.isDisabled = false;
        this.loaderUnlock = false;
        this.PTPSuccess = true;
        this.isDisabled = false;
        this.PTPModel = false;
        this.datechangedModel = false;
        this.eventTracking('PTP_Capture');
        this.Dateform.reset();
        return;
      }

    }
    catch (error) {
      this.disableinput = false;
      this.PTPErrorAlert = true;
      this.isDisabled = false;
      this.PTPSuccess = false;
      this.PTPModel = false;
      this.loaderUnlock = false;
      this.errorMessage = true;
      this.message = JSON.parse(decryptString(error.error.data, this.secretKey))
      this.error = this.message.message.ErrMessage;
      this.eventTracking('PTP_Capture_Failure');
    }
  }

  errorMessage: boolean = false;
  error: any;
  datechanged: boolean = false;
  modelopen1() {
    document.getElementById('Temporaryunlock').click()
    this.Dateform.reset();
    this.DOB = '';
    this.datechangedModel = true;
    this.datechanged = false;
    this.Alert = false;
    this.Dateform.patchValue({
      DOB: ''
    })
    this.isDisabled = true;
    this.Datetext = false;
    this.datestatus = false;
    (document.getElementsByClassName('modal-backdrop')[0] as HTMLDivElement).style.setProperty('position', 'fixed', 'important');
  }
  closedatemodel() {
    this.Dateform.reset();
    this.DOB = '';
    this.Datetext = false;
    this.isDisabled = true;
    (document.getElementsByClassName('modal-backdrop')[0] as HTMLDivElement).style.removeProperty('position');
  }
  closePTPmodel() {
    this.Dateform.reset();
    this.DOB = '';
    this.Datetext = false;
    this.isDisabled = true;
    this.PTP_overlay = false;
  }
  Datetext: boolean = false;
  // button hide
  datestatus: boolean = false;
  DateOfBirth(event: any) {
    this.date = event.target.value;
    if (event.target.value) {
      this.datestatus = true;
    }
    this.newdate = this.date.split("-").reverse().join("/");
    if (this.date != '') {
      this.Datetext = true;
      this.isDisabled = false;
    }
    else {
      this.isDisabled = true;
      this.Datetext = false;
    }
    // this.DOB = this.DOB.split("-").join("/");
  }
  paymentsync: boolean = false;
  paymentsync1: boolean = false;
  paymentsyncdisable: boolean = false;
  errormsg: any;
  message: any;
  paysyncresponse: any;

  async paymentsyncfun(): Promise<void> {
    this.paymentsyncloader = true;
    this.paymentsyncdisable = true;
    this.paymentsync1 = false;
    try {
      const body = {
        user_id: this.userId,
        loan_id: localStorage.getItem('single_loan_name'), mobile: this.phone
      }
      const response = await this.data
        .payment_sync(body)
        .toPromise();
      this.paysyncresponse = response;
      this.paymentsync1 = false;
      if (this.paysyncresponse.status == 200) {
        this.paymentsync = true;
        this.paymentsyncloader = false;
        this.eventTracking('Payment_Sync');
      }


    } catch (error) {
      this.paymentsyncdisable = false;
      this.paymentsyncloader = false;
      this.paymentsync1 = true;
      this.paymentsync = false;
      this.message = JSON.parse(decryptString(error.error.data, this.secretKey))
      this.errormsg = this.message.message.ErrMessage;
      this.eventTracking('Payment_Sync_Failure');
    }
  }


  // -----------------------
  selectedMethod(event) {
    this.netBanking = event;
    this.debitCard = event
    if (event == "Net Banking") {
      this.forNetBankingAmt = '0';
      this.paymentMethod = "Net Banking"
      this.selectedBankName = 'Andhra Bank'
      this.fourDigitIFSC = 'ANDB'
      this.isNetBanking = true;
      this.isDebit = false;
    } if (event == "Debit Card") {
      this.forNetBankingAmt = '1';
      this.paymentMethod = "Debit Card"
      this.selectedBankName = 'Bank of Maharashtra'
      this.fourDigitIFSC = 'MAHB'
      this.isDebit = true;
      this.isNetBanking = false;
    }
  }

  selectedAccountType(event) {
    this.accountType = event;
  }

  openModal() {
    this.modalService.dismissAll();
  }

  selectedBank(event) {
    this.selectedBankName = event;
    for (let i = 0; i < this.netbankingDropdown.length; i++) {
      if (this.selectedBankName == this.netbankingDropdown[i].key) {
        this.fourDigitIFSC = this.netbankingDropdown[i].value;
      }
    }

  }

  removeErr() {
    this.requiredError = '';
    this.requiredError1 = '';
    this.requiredError2 = '';
  }

  openNACHmodel() {
    this.display = 'block';
    this.accountNo = null;
    this.accountIFSC = null;
    this.acHolderName = null;
  }

  closeModalDialog() {
    this.display = 'none';
    this.payNowModel = 'none';
    this.payNowForm.reset();
  }

  payableAmt() {
    this.payableInput = true;
    this.otherInput = false;
  }

  otherAmt() {
    this.payableInput = false;
    this.otherInput = true;
  }

  get payFormData() { return this.payNowForm.controls; }

  // Pay now RZP model open
  Nachrzpopen1() {

    this.paynowdisable = false
    this.Otherinput = false
    this.payableAmount = true;
    this.selectPaymentType = false;
    this.mobileEdit = false;
    this.mobileform.get('mobile').disable();
    this.message = '';
    this.isSMSSend = false;
    this.amountLabel = 'EMI Amount'
    this.paymentTypes = 'Send Payment Link'
    // Start event tracking
    let eventName = 'Open_Popup_for_Pay_Loan_EMI_Loan_Detail_Page'
    this.phone = localStorage.getItem('dmi_phone');
    let campign_number = this.RzploanName;
    let source = 'DMI-Customer-Portal';
    let method = 'Payment';
    this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
    const body1 = {
      phone: this.phone,
      eventname: eventName,
      campign_number: campign_number,
      source: source,
      partner: sessionStorage.getItem('company_Name')

    }
    this.data.getEventName(body1).subscribe();
    const payblebody = {
      user_id: this.userId,
      loan_number: this.loanName,
      lead_source: sessionStorage.getItem('company_Name')
    }
    this.data.getPaybleAmount(payblebody).subscribe(res => {
      document.getElementById('paymentlinkMain').click();
      this.PayableAmountres = res.data;
      const data = this.PayableAmountres.details;
      const emiItem = data.find((item: any) => item.label === 'EMI Amount');
      this.rzp_Amount = emiItem ? emiItem.value : 'Not available';
      this.payNowModelData = this.filterAmounts(this.PayableAmountres.loanStage, data);
    })
  }

  filterAmounts(loanStage: string, amounts: any[]): any[] {
    return amounts.filter(item => {
      switch (item.label) {
        case 'EMI Amount':
          return (loanStage === 'Current' || loanStage === 'Delinquent');
        case 'Total Overdue Amount':
        case 'Late Payment Fees':
          return (loanStage === 'Current' || loanStage === 'Delinquent' ||
            loanStage === 'Written-Off' || loanStage === 'Matured' ||
            loanStage === 'Written Off');
        case 'Preclosure Amount':
          return (loanStage === 'Current' || loanStage === 'Delinquent');
        default:
          return true;
      }
    });
  }

  rzp_Amount: any;
  Amountchecked: any;
  ///Number Validation
  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  selectPaymentMode(){
    // this.payableAmount = false;
    // this.selectPaymentType = true;
    this.proceedRzp();
  }

  payment(){
   if(this.paymentTypes=='Send Payment Link'){
    this.selectPaymentType= false;
    this.mobileEdit = true;
    this.mobileform.patchValue({ mobile: localStorage.getItem('dmi_phone') });
    this.mobileform.get('mobile').disable();
   }
   else{
    this.proceedRzp();
   }
  }
  
  mobileNumberEdit(){
    this.mobileform.get('mobile').enable();
    this.disabled = false;
  }

  sendPaymentLinkMobile(){

   let Data = JSON.parse(localStorage.getItem('user_basic_info')).data[0];

    const body = {
      amount: this.rzp_Amount,
      name: this.PayableAmountres.customer_name,
      email: Data.Email,
      user_id: localStorage.getItem('userId'), 
      opp_id: this.RzploanName,
      address: `${Data.MailingStreet}, ${Data.MailingCity}, ${Data.MailingState}, ${Data.MailingCountry}, ${Data.MailingPostalCode}`,
      phone: this.mobileform.value.mobile,
      loan_name: this.RzploanName,
  }

  this.buttonLoader = true;

    this.data.sendPaymentLink(body).subscribe(res=>{
      this.messageResponse = res;
      if(this.messageResponse.status==200){
        this.message = this.messageResponse.message
        this.disabled = true;
        this.isSMSSend = true;
        this.buttonLoader = false;
      }
    },(error)=>{
      let data = JSON.parse(decryptString(error.error.data, this.secretKey));
      this.messageResponse = data.status;
      this.message = data.message.ErrMessage;
      this.disabled = true;
      this.isSMSSend = true;
      this.buttonLoader = false;
    })
  }



  NachAmountdata(event: any) {

    this.rzp_Amount = event.value;
    this.amountLabel = event.label;
    if (event.label == 'Others') {
      this.Otherinput = true;
    }
    else {
      this.Otherinput = false;
    }
  }

  RzploanName: any = sessionStorage.getItem('oppId');
  RzploanId: any;
  OtherAmount: any;
  Otherinput: boolean = false;

  proceedRzp() {
    const body =
    {
      phone: this.phone,
      name: this.userName,
      loanname: localStorage.getItem('single_loan_name'),
      amount: this.rzp_Amount
    }
    this.data.getPayNow(body).subscribe(res => {
      this.paynowdisable = false;
      this.payNowData = res;
      if (this.payNowData.data.orderid.error || this.payNowData.data.customerid.error) {
        console.log('error')
      }
      else {

        this.payNowcustomerId = this.payNowData.data.customerid.id;
        this.payNoworderId = this.payNowData.data.orderid.id;
        this.email = this.userBasicInfo.data[0].Email;
        const razorpayKey = environment.rzp_key;
        var payNowOptions = {

          "key": razorpayKey,
          "currency": "INR",
          "name": this.RzploanName,
          "description": "Pay your emi",
          "image": "https://los.dmifinance.in/assets/images/logo.svg",
          "order_id": this.payNoworderId,
          "handler": (res) => {
            this.display2 = 'block';
            const body = {
              phone: this.phone,
              loan_number: this.RzploanName,
              amount: this.rzp_Amount,
              source: 'DMI-Customer-Portal',
              method: 'Payment',
              customer_id: this.payNowcustomerId,
              order_id: this.payNoworderId,
              name: this.userName,
              email: this.email
            }


            this.data.getpaymentRecords(body).subscribe(res => {
            })
          },
          "prefill": {
            "email": this.email,
            "contact": this.phone
          },
          "notes": {
            "opportunityName": this.RzploanName,
            "senderId": "DMI",
            "opportunityID ": this.RzploanId,
            "billingAddress": this.billingAddress,
            "customerName": this.userName1
          },
          "theme": {
            "color": "#F37254"
          }
        };

        let eventName = 'Razorpay_Popup_Open_Loan_Details'

        const razorpayBody = {
          phone: this.phone,
          eventname: eventName,
          campign_number: this.RzploanName,
          source: 'DMI-Customer-Portal',
          partner: sessionStorage.getItem('company_Name'),
          remark_1: payNowOptions

        }
        if (this.RzploanName) {
          this.data.getEventName(razorpayBody).subscribe();
          var rzp1 = new Razorpay(payNowOptions);
          rzp1.open();
          if(rzp1.open()){
            document.getElementById('closeModel').click();
          }
        }
        else {

        }

      }
    })

  }



  payPreviousAmountNow() {

    let eventName = 'Pay Loan EMI Amount'
    this.phone = localStorage.getItem('dmi_phone')
    let campign_number = this.loanName;
    let source = 'DMI-Customer-Portal';
    let method = 'Payment';
    this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
    if (this.channelName != '') {
      const body1 = {
        phone: this.phone,
        eventname: eventName,
        campign_number: campign_number,
        source: source,
        partner: sessionStorage.getItem('company_Name')

      }
      this.data.getEventName(body1).subscribe(res => {
      })
    }
    // end

    //this.paidPayableAmount = this.payFormData.payableAmount.value;
    let a = this.previous_amount.replace(/\,/g, "");
    let amount = Math.trunc(a);
    const body =
    {
      phone: localStorage.getItem('dmi_phone'),
      name: this.userName,
      loanname: this.loanName,
      amount: amount
    }
    this.data.getPayNow(body).subscribe(res => {
      this.payNowData = res
      this.payNowcustomerId = this.payNowData.data.customerid.id;
      this.payNoworderId = this.payNowData.data.orderid.id;
      const razorpayKey = environment.rzp_key;
      var payNowOptions = {

        "key": razorpayKey,
        "currency": "INR",
        "name": this.loanName,
        "description": "Pay your emi",
        "image": "https://los.dmifinance.in/assets/images/logo.svg",
        "order_id": this.payNoworderId,//Order ID is generated as Orders API has been implemented. Refer the Checkout form table given below
        "handler": (response) => {
          this.display2 = 'block';
          // alert(response.razorpay_payment_id);
          const body = {
            phone: localStorage.getItem('dmi_phone'),
            loan_number: this.loanName,
            amount: amount,
            source: source,
            method: method,
            customer_id: this.payNowcustomerId,
            order_id: this.payNoworderId,
            name: this.userName,
            email: this.email
          }
          this.data.getpaymentRecords(body).subscribe(res => {
            // alert('Saved payment record successfully.')
          })
        },
        "prefill": {
          "email": this.email,
          "contact": this.phone
        },
        "notes": {
          "opportunityName": this.RzploanName,
          "senderId": "DMI",
          "opportunityID ": this.loanId,
          "billingAddress": this.billingAddress,
          "customerName": this.userName1
        },
        "theme": {
          "color": "#F37254"
        }
      };

      let eventName = 'Razorpay_Popup_Open_Loan_Details'

      const razorpayBody = {
        phone: this.phone,
        eventname: eventName,
        campign_number: localStorage.getItem('single_loan_name'),
        source: 'DMI-Customer-Portal',
        partner: sessionStorage.getItem('company_Name'),
        remark_1: payNowOptions

      }

      if (this.RzploanName) {
        this.data.getEventName(razorpayBody).subscribe();

        var rzp1 = new Razorpay(payNowOptions);
        rzp1.open();
        if (rzp1.open()) {
          this.payNowModel = 'none';
          this.payNowForm.reset();
          document.getElementById('closeModel').click();
        }
      }
      else {

      }

    })
  }

  nachRegistration(cancelEvent, successModal) {
    this.email = this.userBasicInfo.data[0].Email;
    //Start event tracking
    this.displayModal = 'none';
    let eventName = 'NACH Registration';
    let phone = localStorage.getItem('dmi_phone');
    let campign_number = this.loanName;
    let source = 'DMI-Customer-Portal';
    this.presendat = '2020-01-05';

    if (this.getPPName == 'null') { this.sendPPName = this.loanName }
    else if (this.getPPName != 'null') { this.sendPPName = this.getPPName; }

    if (this.getPPAmount == 'null') {
      this.specificLoanAmt2 = this.specificLoanAmt1;
    }
    else if (this.getPPAmount != 'null') {
      this.specificLoanAmt2 = this.getPPAmount;
    }

    this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
    if (this.channelName != '') {
      const body1 = {
        phone: localStorage.getItem('dmi_phone'),
        eventname: eventName,
        campign_number: campign_number,
        source: source,
        partner: sessionStorage.getItem('company_Name')

      }
      this.data.getEventName(body1).subscribe(res => {
      })
    }
    // end
    if (this.accountNo === null || this.accountNo == "") {
      this.requiredError = 'Account Number Required.'
      return;
    } else if (this.accountIFSC === null || this.accountIFSC == "") {
      this.requiredError1 = 'IFSC Code Required.'
      return;
    } else if (this.userName1 === null || this.userName1 == "") {
      this.requiredError2 = 'Account Holder Name Required.'
      return;
    }
    else {
      if (this.paymentMethod == 'Net Banking') {
        this.paymentMethod = "Net"
      }
      if (this.paymentMethod == 'Debit Card') {
        this.paymentMethod = "Debit"
      }
      if (this.companyName == 'Samsung') {
        this.loanAmount = 'EMI Amount'
        if (this.netPaymentC >= '100000') {
          this.maxAmount = "9999900";
          this.amtToString = this.maxAmount.toString();
        }
        else {
          this.maxAmount = this.netPaymentC * 100;
          this.amtToString = this.maxAmount.toString();
        }
      }
      else {
        if (this.specificLoanAmt2 >= '100000') {
          this.maxAmount = "9999900";
          this.amtToString = this.maxAmount.toString();
        }
        else {

          this.maxAmount = this.specificLoanAmt2 * 100;
          this.amtToString = this.maxAmount.toString();
        }
      }

      // this.fourDigitIFSC = this.accountIFSC.substring(0, 4)

      let account_type: any;
      for (let i = 0; i < this.UserAccountType.length; i++) {
        if (this.accountType == this.UserAccountType[i].name) {
          account_type = this.UserAccountType[i].value;
        }
      }

      if (this.accountIFSC.length < 11) {
        alert('The ifsc code must be 11 characters.')
        return;
      }
      const body =
      {
        phone: localStorage.getItem('dmi_phone'),
        email: this.email,
        name: this.userName1,
        loanamount: this.forNetBankingAmt,
        methodcheck: this.paymentMethod,
        loanname: this.sendPPName,
        account: this.accountNo,
        account_type: account_type,
        ifsc: this.accountIFSC
      }


      this.data.getCreateCo(body).subscribe(res => {
        this.createCO = res;
        this.customerId = this.createCO.data.customerid.id;
        localStorage.setItem('customerId', this.customerId)
        this.orderId = this.createCO.data.orderid.id;
        const razorpayKey = environment.rzp_key;
        if (this.paymentMethod == "Net") {
          var options = {

            "key": razorpayKey,
            "amount": "0",
            "name": this.userName,
            "order_id": this.orderId,
            "customer_id": this.customerId,
            "currency": "INR",
            "receipt": this.sendPPName,
            "description": "",
            "auth_type": "netbanking",
            "recurring": "1",
            "recurring_token.max_amount": this.amtToString,
            "recurring_token.expire_by": "31-12-2035",
            "prefill": {
              "name": this.userName1,
              "email": this.email,
              "contact": this.phoneNo,
              "bank": this.fourDigitIFSC,
              "method": "emandate",
              "bank_account[name]": this.userName1,
              "bank_account[account_number]": this.accountNo,
              "bank_account[ifsc]": this.accountIFSC,
            },
            "notes": {
              "address": "cp",
              "PartnerName": this.companyName,
              "BankName": this.selectedBankName,
              "OppName": this.RzploanName
            },
            "handler": (response) => {
              const body = {
                payment_id: response.razorpay_payment_id
              }
              this.data.getTokenData(
                body
              ).subscribe(res => {
                this.gettokendata = res
                this.tokennew = this.gettokendata.data;
                const body = {
                  token: localStorage.getItem('dmi_token'),
                  url: localStorage.getItem('dmi_instance_url'),
                  mobile: localStorage.getItem('dmi_phone'),
                  loanname: this.sendPPName,
                  email: this.email,
                  account: this.accountNo,
                  ifsc: this.accountIFSC,
                  bankformifsc: this.fourDigitIFSC,
                  selectoption: this.paymentMethod,
                  loanamount: this.specificLoanAmt2,
                  name: this.userName,
                  customer_id: this.customerId,
                  order_id: response.razorpay_order_id,
                  signature: response.razorpay_signature,
                  payment_id: response.razorpay_payment_id,
                  account_id: this.Accountid,
                  parntername: this.companyName,
                  presentationdate: this.presendat,
                  rztoken: this.tokennew,
                  account_type: account_type
                }

                this.data.getNachRegistration(body).subscribe(res => {
                  this.nachResponse = res;
                  this.closeModal();
                  let sourcename = 'DMI-Customer-Portal'

                  const body = {
                    phone: localStorage.getItem('dmi_phone'),
                    loan_number: this.loanName,
                    payment_id: response.razorpay_payment_id,
                    method: this.paymentMethod,
                    source: sourcename,
                    ppname: this.sendPPName
                  }

                  this.data.saveNACHrecords(body).subscribe(res => {
                  })
                  let eventName = "NACH Registration-Success"
                  this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
                  if (this.channelName != '') {
                    const body1 = {
                      phone: localStorage.getItem('dmi_phone'),
                      eventname: eventName,
                      campign_number: campign_number,
                      source: source,
                      partner: this.channelPartnerName

                    }
                    this.data.getEventName(body1).subscribe(res => {
                    })
                  }
                  // this.display1 = 'block';
                  this.modalService.dismissAll();
                  this.modalService.open(successModal);
                  //alert('NACH Registration Successfully.');
                })
              })
            },
            "modal": {
              "ondismiss": () => {
                this.modalService.dismissAll();
                this.modalService.open(cancelEvent);
                let eventName = "NACH Registration-Fail"
                this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
                if (this.channelName != '') {
                  const body1 = {
                    phone: localStorage.getItem('dmi_phone'),
                    eventname: eventName,
                    campign_number: campign_number,
                    source: source,
                    partner: this.channelPartnerName

                  }
                  this.data.getEventName(body1).subscribe(res => {
                  })
                }
                // window.location.replace("https://dmi.vistaconnect.com/eMandate/?key=" + localStorage.getItem('loginKey'));
              }
            },

            "theme": { "color": "#F37254" }
          };


        } else {
          options = {

            "key": razorpayKey,
            "amount": "1",
            "name": this.userName1,
            "order_id": this.orderId,
            "customer_id": this.customerId,
            "currency": "INR",
            "receipt": this.sendPPName,
            "description": "",
            "auth_type": "",
            "recurring": "1",
            "recurring_token.max_amount": " ",
            "recurring_token.expire_by": "31-12-2035",
            "prefill": {
              "name": this.userName,
              "email": this.email,
              "contact": this.phoneNo,
              "bank": " ",
              "method": "",
              "bank_account[name]": this.userName1,
              "bank_account[account_number]": this.accountNo,
              "bank_account[ifsc]": this.accountIFSC,
            },
            "notes": {
              "address": "cp",
              "PartnerName": this.companyName,
              "BankName": this.selectedBankName,
              "OppName": this.RzploanName

            },
            "handler": (response) => {
              const body = {
                payment_id: response.razorpay_payment_id
              }
              this.data.getTokenData(
                body
              ).subscribe(res => {
                this.gettokendata = res;
                this.tokennew = this.gettokendata.data;
                let accnumdebit = "null";
                let ifscdebit = "null";
                const body = {
                  token: localStorage.getItem('dmi_token'),
                  url: localStorage.getItem('dmi_instance_url'),
                  mobile: localStorage.getItem('dmi_phone'),
                  loanname: this.sendPPName,
                  email: this.email,
                  account: accnumdebit,
                  ifsc: ifscdebit,
                  bankformifsc: this.fourDigitIFSC,
                  selectoption: this.paymentMethod,
                  loanamount: this.specificLoanAmt2,
                  name: this.userName,
                  customer_id: this.customerId,
                  order_id: response.razorpay_order_id,
                  signature: response.razorpay_signature,
                  payment_id: response.razorpay_payment_id,
                  account_id: this.Accountid,
                  parntername: this.companyName,
                  presentationdate: this.presendat,
                  rztoken: this.tokennew,
                  account_type: account_type
                }
                this.data.getNachRegistration(body).subscribe(res => {
                  this.nachResponse = res
                  this.closeModal();
                  let sourcename = 'DMI-Customer-Portal'
                  const body = {
                    phone: localStorage.getItem('dmi_phone'),
                    loan_number: this.loanName,
                    payment_id: response.razorpay_payment_id,
                    method: this.paymentMethod,
                    source: sourcename,
                    ppname: this.sendPPName
                  }

                  this.data.saveNACHrecords(body).subscribe(res => {
                  })

                  let eventName = "NACH Registration-Success"
                  this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
                  if (this.channelName != '') {
                    const body1 = {
                      phone: localStorage.getItem('dmi_phone'),
                      eventname: eventName,
                      campign_number: campign_number,
                      source: source,
                      partner: this.channelPartnerName

                    }
                    this.data.getEventName(body1).subscribe(res => {
                    })
                  }
                  // this.display1 = 'block';
                  this.modalService.dismissAll();
                  this.modalService.open(successModal);
                  // alert('NACH Registration Successfully.');
                })
              })
            },
            "modal": {
              "ondismiss": () => {
                this.modalService.dismissAll();
                this.modalService.open(cancelEvent);
                let eventName = "NACH Registration-Fail";
                this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
                if (this.channelName != '') {
                  const body1 = {
                    phone: localStorage.getItem('dmi_phone'),
                    eventname: eventName,
                    campign_number: campign_number,
                    source: source,
                    partner: this.channelPartnerName

                  }
                  this.data.getEventName(body1).subscribe(res => {
                  })
                }
                // window.location.replace("https://dmi.vistaconnect.com/eMandate/?key=" + localStorage.getItem('loginKey'));
              }
            },

            "theme": { "color": "#F37254" }
          }
        }

        let eventName = 'Razorpay_Popup_Open_Loan_Details'

        const razorpayBody = {
          phone: this.phone,
          eventname: eventName,
          campign_number: localStorage.getItem('single_loan_name'),
          source: 'DMI-Customer-Portal',
          partner: sessionStorage.getItem('company_Name'),
          remark_1: options

        }

        if (this.RzploanName) {
          this.data.getEventName(razorpayBody).subscribe();

          var rzp1 = new Razorpay(options);
          rzp1.open();
          if (rzp1.open()) {
            this.display = 'none';
            document.getElementById('closeModel').click();
          }
        } else {

        }



      })
    }
    this.modalService.dismissAll();
  }

  closePayModal() {
    this.display2 = 'none';
  }

  closeNachModal() {
    alert(close)
    this.display1 = 'none';
  }

  open(content) {
    this.modalService.open(content);
  }

  loandetailnach(firstModal) {
    this.loandetailnachdisable = true
    // event Tracking
    this.modalService.open(firstModal);
    // this.displayModal='block';
    let loanname = localStorage.getItem('single_loan_name');
    let eventName = 'Loan Details Button - Register for Auto-Debit'
    let phone = localStorage.getItem('dmi_phone')
    let campign_number = loanname
    let source = 'DMI-Customer-Portal'
    this.loanDetail = JSON.parse(localStorage.getItem('loanDetail'));
    if (this.channelName != '') {
      const body1 = {
        phone: localStorage.getItem('dmi_phone'),
        eventname: eventName,
        campign_number: campign_number,
        source: source,
        partner: sessionStorage.getItem('company_Name')

      }
      this.data.getEventName(body1).subscribe(res => {
        this.loandetailnachdisable = false
      });
    }

  }


  // Emandate Function
  openEmandate() {

    const emandatebody =
    {
      user_id: this.userId,
      opp_name: this.loanName,
      account_id: this.Accountid,
      loan_amount: this.specificLoanAmt1,
      borrower_name: this.userName1,
      mobile_number: localStorage.getItem('dmi_phone')

    }
    this.data.emandateapply(emandatebody).subscribe(res => {

      this.eventTracking("E-Mandate Key Generation Success");
      window.open(res['data'], '_blank')

    }, (error) => {
      this.loandetailnachdisable = false
    })
  }

  closeModal() {
    this.displayModal = 'none';
  }

  welcomeLetter() {
    this.router.navigate(['/welcomeletter']);
  }

  ngOnDestroy() {
    clearTimeout(this.userActivity);
  }

  eventTracking(Event_Name) {
    const body = {
      phone: localStorage.getItem('dmi_phone'),
      eventname: Event_Name,
      campign_number: localStorage.getItem('single_loan_name'),
      source: 'DMI-Customer-Portal',
      partner: sessionStorage.getItem('company_Name'),

    }
    this.data.getEventName(body).subscribe();

  }
}
