import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-category-list-details',
  templateUrl: './category-list-details.component.html',
  styleUrls: ['./category-list-details.component.scss']
})
export class CategoryListDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
